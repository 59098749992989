module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="action">\n  ';
 if (item.has('file_id') && item.get("viewable_permission") && item.get("files").length > 0 ) { ;
__p += '\n    <a href="' +
((__t = ( Routes.checklist_answer_manage_uploaded_files_path(item.get('id')) )) == null ? '' : __t) +
'" class="manage-files-modal">Manage Files <i class="fa fa-upload"></i></a>\n  ';
 } else { ;
__p += '\n    ';
 if (item.get('can_upload') && item.get("viewable_permission")) { ;
__p += '\n      <div class="fileinput-button">\n          <a href="#">Upload <i class="fa fa-upload"></i></a>\n          <input type="file" name="uploaded_file[file]" data-url="' +
((__t = ( Routes.checklist_answer_uploaded_files_path(item.get('id')) )) == null ? '' : __t) +
'">\n      </div>\n      <div class="progress d-none">\n          <div class="progress-bar bg-success"></div>\n      </div>\n    ';
 } ;
__p += '\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}