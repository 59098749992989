module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (item.get("files").length > 0 && item.get("viewable_permission")){ ;
__p += '\n    <a href="' +
((__t = ( Routes.checklist_answer_manage_uploaded_files_path(item.get('id')) )) == null ? '' : __t) +
'" class="manage-files-modal">Manage Files <i class="fa fa-upload"></i></a>\n    ';
 } else if (item.get('can_upload') && item.get("viewable_permission")) { ;
__p += '\n    <div class="fileinput-button fileinput-with-form">\n        <a href="#">Upload <i class="fa fa-upload"></i></a>\n        <input type="file" name="uploaded_file[file]" data-url="' +
((__t = ( Routes.checklist_answer_uploaded_files_path(item.get('id')) )) == null ? '' : __t) +
'">\n    </div>\n    <div class="progress d-none">\n        <div class="progress-bar bg-success"></div>\n    </div>\n';
 } ;
__p += '\n';

}
return __p
}