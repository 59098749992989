var Utility = {
  htmlEscape: function (s) {
    return s.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/'/g, '&#039;')
        .replace(/"/g, '&quot;')
        .replace(/\n/g, '<br />');
  },

  changeAction: function(form_selector, action) {
    $(form_selector).attr('action', action);
    return true;
  },

  changeActionAndSubmit: function(form_selector, action) {
    $(form_selector).attr('action', action);
    if (window.Contact && typeof Contact.sorting_of_list !== 'undefined') {
      // inject sorting in form
      var inject = {
        sort: Contact.sorting_of_list.sort,
        sort_column: Contact.sorting_of_list.column
      };
      if ($('#reply_to_input').length > 0) {
        inject.reply_to = $('#reply_to_input').val();
      }
      $.each(inject, function(index, value) {
        $('input[name~=' + index + ']').remove();
        $('<input type="hidden", name="' + index + '", value="' + value + '"/>').appendTo(form_selector);
      });
    }
    $(form_selector).submit();
  },

  // Maps flash levels to bootstrap-notify types
  FLASH_TYPE: {
    success: 'success',
    alert: 'danger',
    error: 'danger',
    notice: 'info',
    warning: 'warning'
  },

  displayFlashMessages: function (flash_hash) {
    this.hideNotifications();
    for (var level in flash_hash) {
      var messages = _.flatten([flash_hash[level]]);
      for (var i = 0; i < messages.length; i++) {
        var note = $('<div class="alert in fade show alert-'+ (this.FLASH_TYPE[level] || 'info') +'"></div>');
        $(note).html(messages[i]);

        const _link = $('<a role="button" aria-label="Close" class="btn-close pull-right"></a>');
        $(_link).on('click', () => {$(note).remove()});
        note.prepend(_link);

        $('.notifications').append(note);
      }
    }
  },

  errorMessage: function (message) {
    Utility.displayFlashMessages({error: message});
  },

  successMessage: function (message) {
    Utility.displayFlashMessages({success: message});
  },

  warningMessage: function (message) {
    Utility.displayFlashMessages({warning: message});
  },

  hideNotifications: function () {
    $('.notifications .alert').hide();
  },

  /**
   * Displays flash messages for validation errors received from an API response
   *
   * @param {Object.<string, Array.<string>>} errors Object representing API errors by field name.
   */
  displayApiValidationErrors: function(errors) {
    const mappedErrors = Object.entries(errors).map(([field, fieldError]) => {
      if (field === 'base') {
        return fieldError;
      }
      else {
        return `${field} ${fieldError}`;
      }
    });

    Utility.displayFlashMessages({
      error: mappedErrors
    });
  },

  displayAjaxError: function () {
    $('.notifications').notify({
      closeable: true,
      fadeOut: { enabled: true, delay: 5000 },
      message: "We're sorry, an error has occurred.  If the problem persists, please <a href=''>contact support.</a> ",
      html: true,
      type: 'error'
    }).show();
  },

  initManualPopover: function (selector) {
    $(selector).popover({
      trigger: 'manual',
      html: true
    }).click(function () {
          $(this).popover('toggle');

          $(selector + '_cancel').click(function (e) {
            $(selector).popover('hide');
          });
        });

    $('body').click(function () {
      $(selector).popover('hide');
    });

    $(selector).click(function (e) {
      e.stopPropagation();
    });
  },

  /**
   * Disable chrome autofill for all text inputs and select boxes under the specified element.
   */
  disableChromeAutoComplete: function(el) {
    if (/chrome/.test(navigator.userAgent.toLowerCase())) {
      $(el).find('input[type="text"], select').attr("autocomplete", "off");
    }
  },


  /**
   * Removes autocomplete from all text inputs and select boxes under the specified form
   * when it's submitted. The autocomplete=off attribute on input elements prevents the
   * browser from re-populating the form when using the back button to return to it.
   *
   * @param form the form element to hook into
   */
  removeAutoCompleteOnSubmit: function(form) {
    $(form).submit(function(){
      $(this).find('input[type="text"], select').removeAttr("autocomplete");
    });
  },

  currentDate: function() {
    return I18n.ldate(new Date());
  },

  /**
  * Takes a group of radio buttons and, based on the value, hides/unhides the target object
  *
  * @param radio_group selector string for the group of radio buttons
  * @param target selector string for the object to be hidden/unhidden
  * @param showWhen radio value for which the target object will be shown
  */
  showHidden: function(radioGroup, target, showWhen) {
    $(radioGroup).find("input:radio").click(function() {
      var radioVal = $(radioGroup).find("input:radio:checked").val();

      if (radioVal === showWhen) {
        $(target).removeClass('hidden');
      }
      else {
        $(target).addClass('hidden');
      }
    });
  },

  /**
  * Enables an object once a radio selection has been made
  *
  * @param radio_group selector string for the group of radio buttons
  * @param target selector string for the object to be enabled/disabled
  */
  enableOnRadioSelection: function(radioGroup, target) {
    $(radioGroup).find("input:radio").click(function() {
      $(target).removeAttr('disabled');
    });
  },

  /**
   * Toggles two objects between enabled/disabled state based on the value of a radio button
   *
   * @param radio_group selector string for the group of radio buttons
   * @param object1 object to be enabled/disabled
   * @param object2 object to be enabled/disabled
   * @param target selector string for the object to be enabled/disabled
   */
  toggleDisabled: function(radioGroup, object1, object2, object1EnableVal) {
    $(radioGroup).find("input:radio").click(function() {
      var radioVal = $(radioGroup).find("input:radio:checked").val();

      if (radioVal == object1EnableVal) {
        $(object1).prop('disabled', false);
        $(object2).prop('disabled', true);
      }
      else
      {
        $(object2).prop('disabled', false);
        $(object1).prop('disabled', true);
      }
    });
  },

  initSessionTimeout(timeoutInSeconds, fromNow) {
    this.sessionTimeoutInSeconds = timeoutInSeconds;
    this.sessionTimeoutWarningInSeconds = Math.min(timeoutInSeconds, 60);

    this.resetSessionTimeoutTrackers();

    $('.session-timeout-warning').addClass('d-none');
    this.sessionTimeout = setTimeout(() => this.doubleCheckSessionTimeout(fromNow),
      this.sessionTimeoutInSeconds*1000 - this.sessionTimeoutWarningInSeconds*1000);
  },

  setSessionTimeoutRescue: function(rescue){
    this.sessionTimeoutRescue = rescue;
    if(this.resetSessionTimeoutTrackers()) {
      this.initSessionTimeout(this.sessionTimeoutInSeconds);
    }
  },

  resetSessionTimeoutTrackers: function(){
    if(!this.sessionTimeout && !this.sessionTimeoutCountdownInterval){
      return false;
    }

    if(this.sessionTimeout){
      clearTimeout(this.sessionTimeout);
    }

    if (this.sessionTimeoutCountdownInterval) {
      clearInterval(this.sessionTimeoutCountdownInterval);
    }

    return true;
  },

  // Another request in a separate tab might have bumped up the timeout
  doubleCheckSessionTimeout(fromNow) {
    $.get(`/api/session_timeout${fromNow ? `?from_now=${fromNow}` : ''}`)
      .done(function(response){
        // Got a successful response.  Double check time remaining.]
        if (response.timed_out) {
          // already timed out.  reload the page to show user a login message.
          location.reload();
        }
        else{
          if (response.seconds_left <= Utility.sessionTimeoutWarningInSeconds) {
            Utility.showSessionTimeoutWarning(response.seconds_left);
          }
          else{
            Utility.initSessionTimeout(response.seconds_left);
          }
        }
      })
      .fail(function(){
        location.reload();
      });
  },

  showSessionTimeoutWarning: function(secondsLeft){
    this.resetSessionTimeoutTrackers();

    this.sessionTimeoutCountdown = secondsLeft;

    if(this.sessionTimeoutRescue){
      this.sessionTimeoutCountdown -= 10; // perform rescue actions early so we don't accidentally time out while triggering them.

      this.sessionTimeout = setTimeout(function(){
        Utility.sessionTimeoutRescue();
      }, this.sessionTimeoutCountdown * 1000)
    }
    else {
      this.sessionTimeout = setTimeout(function () {
        Utility.evictSessionAfterTimeout();
      }, this.sessionTimeoutCountdown * 1000);
    }

    this.sessionTimeoutCountdownInterval = setInterval(function(){
      $('.session-timeout-warning .session-timeout-countdown').text(Math.max(Utility.sessionTimeoutCountdown--, 0));
    }, 1000);

    $('.session-timeout-warning').removeClass('d-none');
    window.scrollTo(0,0);
  },

  // Once the session has presumably finished timing out, reload the page.
  // Wait 3 seconds as a buffer against sessions that are just about to time out.
  evictSessionAfterTimeout: function(){
    $('.session-timeout-soon').addClass('d-none');
    $('.session-timeout-evicted').removeClass('d-none');

    setTimeout(function(){
      location.reload();
    }, 3000);
  },

  refreshSessionTimeout: function(){
    $.get("/api/reset_session_timeout")
      .done(function(response){
        Utility.initSessionTimeout(response.seconds_left);
      })
      .fail(function(){
        // If this failed, it's because the session already timed out.
        location.reload();
      });
  },

  isIframe: function() {
    return window != window.parent;
  }
};

export default Utility;

global.Utility = global.Utility || Utility;
