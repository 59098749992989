import Backbone from 'backbone';

import checklistHeaderTemplate from '../../../../templates/checklist/checklist_header.jst.ejs';

const ChecklistHeader = Backbone.View.extend({
  events: {
    "click .stages li a": "switchStages"
  },

  render: function() {
    this.$el.html(checklistHeaderTemplate({
      percentage: this.options.percentage,
      workflows: this.options.workflows,
      name: this.options.name
    }));
  },

  switchStages: function(event){
    var target = $(event.target).attr('class');

    $('.contact .checklist .lists [class^="tab-"]').addClass('hidden');
    $('.contact .checklist .lists .tab-' + target).removeClass('hidden');
  }
});

export default ChecklistHeader;