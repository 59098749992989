import Backbone from 'backbone';

import ChecklistItem from './checklist_item';

const DepositItem = ChecklistItem.extend({

  events: Object.assign({
    'click .change-deposit-link': 'updateDepositAmount',
    'click .change-split-deposit-link': 'updateSplitDepositAmount',
  }, ChecklistItem.prototype.events),

  initialize() {
    ChecklistItem.prototype.initialize.apply(this);

    this.backboneEventHandler = Object.assign({}, Backbone.Events);

    this.backboneEventHandler.on(
      'MODAL_SUBMIT',
      (result) => { this.updateModel(result); },
      this
    );
  },

  updateModel: function(attributes){
    var model = this.model;
    model.set(attributes);
  },

  // overrides the default toggleItem so the modal completes the checklist item
  toggleItem: function (e) {
    var model = this.model;
    const target = $(e.currentTarget);

    if (!this.isDisabled()) {
      if(model.get('has_split_deposits')) {
        const splitDepositId = this.splitDepositId(e);

        // Bail out if the user clicked the top-level checklist item
        if(!splitDepositId) {
          return false;
        }

        this.showSplitDepositModal(splitDepositId);
      }
      else {
        if (model.isComplete()) {
          $.when(this.confirmIncomplete())
            .then(function () {
              model.set('completion_date', null);
              model.save(null, {
                success: function () {
                  // Update the status in the contact record header
                  $('.contact-header .status')
                    .text(model.get('status'));
                }
              });
              model.trigger('incomplete');
            });
        } else {
          this.showModal();
        }
      }
    }
  },

  showModal: function () {
    ReactComponent.dispatch({
      type: 'schooladmin/modal/SHOW',
      modalType: 'ManualPaymentModal',
      modalProps: {
        checklistAnswerId: this.model.get('id'),
        type: this.model.get('back_bone_type'),
        contactId: this.model.get('contact_id'),
        paymentMethod: this.model.get('deposit_payment_method'),
        schoolYearId: this.model.get('school_year_id'),
        paymentAmount: this.model.get('deposit_amount_paid'),
        backboneEventHandler: this.backboneEventHandler
      }
    })
  },

  showSplitDepositModal: function(split_deposit_id) {
    ReactComponent.dispatch({
      type: 'schooladmin/modal/SHOW',
      modalType: 'SplitDepositManualPaymentModal',
      modalProps: {
        split_deposit_id,
        checklistAnswerId: this.model.get('id'),
        type: this.model.get('back_bone_type'),
        contactId: this.model.get('contact_id'),
        contractId: this.model.get('contract_id'),
        backboneEventHandler: this.backboneEventHandler
      }
    })
  },

  updateDepositAmount: function () {
    this.showModal()
  },

  updateSplitDepositAmount: function(e) {
    this.showSplitDepositModal(this.splitDepositId(e));
  },

  splitDepositId: function(e) {
    const target = $(e.currentTarget);
    return target.closest('.checklist-sub-item').data('split-deposit-id');
  },

  splitDepositCompleted: function(splitDeposit) {
    return splitDeposit.paid && !splitDeposit.offline_payment_pending;
  }
});

export default DepositItem;
