module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'detail\'>\n  ';
 if (!item.get('has_split_deposits') && item.get("viewable_permission")) { ;
__p += '\n      <a href=\'#\' class=\'change-deposit-link\'>\n        ';
 if (item.has('deposit_amount_paid')) { ;
__p += '\n          ' +
((__t = ( accounting.formatMoney(item.get('deposit_amount_paid'), item.get('deposit_currency_opts')) )) == null ? '' : __t) +
' paid\n          ';
 if (item.has('deposit_payment_method')) { ;
__p += '\n            by ' +
((__t = ( item.get('deposit_payment_method').toLowerCase() )) == null ? '' : __t) +
'\n          ';
 } ;
__p += '\n        ';
 } else { ;
__p += '\n        Edit\n        ';
 } ;
__p += '\n        <i class=\'fa fa-pencil\'></i>\n      </a>\n  ';
 } ;
__p += '\n\n  ';
 if (item.get('has_split_deposits')) { ;
__p += '\n      ';
 item.get('split_deposits').forEach((split_deposit) => { ;
__p += '\n          <div class="row checklist-sub-item"\n               data-split-deposit-id="' +
((__t = ( split_deposit.id )) == null ? '' : __t) +
'"\n               data-split-deposit-completed="' +
((__t = ( view.splitDepositCompleted(split_deposit) )) == null ? '' : __t) +
'"\n          >\n              <div class="box checklist-sub-item-box col-2 ' +
((__t = ( view.isDisabled() ? 'disabled' : '' )) == null ? '' : __t) +
'">\n                  ';
 if (view.splitDepositCompleted(split_deposit)) { ;
__p += '\n                      <i class="fa fa-check-square"></i>\n                  ';
 } else { ;
__p += '\n                      <i class="fa fa-square-o"></i>\n                  ';
 } ;
__p += '\n              </div>\n\n              <div class="item col-22">\n                  <div class="title col-18">' +
((__t = ( split_deposit.payer_name )) == null ? '' : __t) +
' Deposit</div>\n                  <div class="detail col-6">\n                      <a href=\'#\' class=\'change-split-deposit-link\'>\n                          ';
 if (view.splitDepositCompleted(split_deposit)) { ;
__p += '\n                              ' +
((__t = ( accounting.formatMoney(split_deposit.deposit_amount_paid, item.get('deposit_currency_opts')) )) == null ? '' : __t) +
' paid\n                              ';
 if (split_deposit.deposit_payment_method) { ;
__p += '\n                                  by ' +
((__t = ( split_deposit.deposit_payment_method.toLowerCase() )) == null ? '' : __t) +
'\n                              ';
 } ;
__p += '\n                          ';
 } else { ;
__p += '\n                              Edit\n                          ';
 } ;
__p += '\n                          <i class=\'fa fa-pencil\'></i>\n                      </a>\n                  </div>\n\n                  ';
 if (view.splitDepositCompleted(split_deposit) && split_deposit.paid_date) { ;
__p += '\n                      <div class="second-row row">\n                          <div class=\'col-7\'>\n                              <a class="completion-date editable">' +
((__t = ( item.formatDateTime(split_deposit.paid_date) )) == null ? '' : __t) +
'</a>&nbsp;\n                          </div>\n                      </div>\n                  ';
 } ;
__p += '\n              </div>\n          </div>\n      ';
 }); ;
__p += '\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}