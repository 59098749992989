import ChecklistFormItem from './_checklist_form_item';
import ChecklistItem from './checklist_item';

const RecommendationRequestItem = ChecklistFormItem.extend({

  events: Object.assign({'click .delete-file-link': 'deleteFile'
  }, ChecklistItem.prototype.events),

  confirmComplete: function () {
    return this.confirm('Confirm Changes', 'When manually completing this recommendation, any outstanding ' +
      'recommendation request for this applicant <em>will be cancelled</em>. Do you want to ' +
      'continue?');
  },
  confirmIncomplete () {
    return this.model.has('recommend_form_id') || this.model.attributes.files.length > 0
      ? this.confirm('Confirm Changes', 'Unchecking this checklist item will permanently delete the submitted or uploaded form. The form and any data entered cannot be recovered once deleted. Do you want to continue?')
      : null;
  }
});

export default RecommendationRequestItem;
