
import 'blueimp-file-upload/js/jquery.fileupload';

import ContactRecord from '../../../backbone/routers/contact_record';

var Checklists = {
  bindFileUpload: function (button_sel, max_file_size) {

    //disable browser support of drag and drop
    $(document).bind('drop dragover', function (e) {
      e.preventDefault();
    });

    // Contact portrait upload also uses these callbacks.
    $(button_sel).each(function() {
        $(this).fileupload({
        dataType: 'json',
        maxFileSize: max_file_size,
        autoUpload: true,
        dropZone: 'null',
        pasteZone: 'null',
        add: function (e, data) {
          var error = data.files[0].error;
          if (error) {
            alert(error);
          } else if(data.files[0].size > max_file_size) {
            alert(`An error occurred while uploading your file. Make sure it is smaller than ${max_file_size / 1024 / 1024} megabytes.`);
          } else {
            data.submit();
          }
        },
        start: function () {
          $(this).closest('.file-upload-widget').addClass('file-upload-widget-uploading');
        },
        progress: function (e, data) {
          var progress = parseInt(data.loaded / data.total * 100, 10);
          var progress_el = $(this).closest('.file-upload-widget').find('.file-upload-progress .file-upload-progress-bar');
          progress_el.css('width', progress + '%');
          if (progress >= 99) {
            setTimeout(function() {
              progress_el.addClass('progress-bar-striped progress-bar-animated');
            }, 1000);
          } else {
            progress_el.removeClass('progress-bar-striped progress-bar-animated');
          }
        },
        done: function () {
          var url_done = $(this).find('input').attr('data-url-done');
          if (url_done) {
            window.location = url_done;
          } else {
            window.location.reload();
          }
        },
        fail: function () {
          alert('An error occurred while uploading your file.');
          $(this).closest('.file-upload-widget').removeClass('file-upload-widget-uploading');
        },
        url: $(this).find('input').attr('data-url')
      });
    });
  },

  bindFileUploadAdmin: function (button_sel, max_file_size) {
    var action_el = $(button_sel).closest('.fileinput-button');
    var progress_el = action_el.siblings('.progress');
    progress_el.find('.progress-bar').css('width', '0');
    var uploaded = $.Deferred();

    //disable browser support of drag and drop
    $(document).bind('drop dragover', function (e) {
      e.preventDefault();
    });

    $(button_sel).fileupload({
      dataType: 'json',
      maxFileSize: max_file_size,
      autoUpload: true,
      dropZone: 'null',
      pasteZone: 'null',
      add: function (e, data) {
        var error = data.files[0].error;
        if (error) {
          alert(error);
        } else if(data.files[0].size > max_file_size) {
          alert(`An error occurred while uploading your file. Make sure it is smaller than ${max_file_size / 1024 / 1024} megabytes.`);
        } else {
          data.submit();
        }
      },
      start: function () {
        action_el.hide();
        progress_el.removeClass('d-none').show();
      },
      progress: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        progress_el.find('.progress-bar').css('width', progress + '%');
        if (progress >= 99) {
          setTimeout(function() {
            progress_el.addClass('progress-striped active');
          }, 1000);
        } else {
          progress_el.removeClass('progress-striped active');
        }
      },
      done: function (e, data) {
        action_el.show();
        progress_el.hide();

        if ('flash' in data.result) {
          Utility.displayFlashMessages(data.result.flash);
        }

        if (data.result && data.result.error) {
          uploaded.fail();
        } else {
          uploaded.resolve();
        }
      },
      fail: function () {
        action_el.show();
        progress_el.addClass('hide').hide();
        uploaded.fail();
      },
      url: button_sel.find('input').attr('data-url')
    });

    return uploaded;
  },

  // Bind the upload button on the portrait modal
  bindPortraitUploadButtonModal: function(max_file_size) {
    Checklists.bindFileUploadAdmin($(".portrait-crop-upload"), max_file_size).done(
      function() {
        $.ajax({
          // Always hide cancel button after uploading (at this point the previous portrait has been replaced, so there's no way to cancel)
          url: Routes.edit_contact_portrait_path(ContactRecord.id, {hide_cancel: true}),
          success: function(response) {
            Dialog._handle_update_modal_response($(".crop-modal"), response);
            Checklists.bindPortraitCrop(true);
            Checklists.bindPortraitUploadButtonModal(max_file_size);
          }
        });
      }
    );
  },

  // Bind the upload button on the actual contact record avatar
  bindPortraitUploadButtonAvatar: function(max_file_size, contact_id) {
    ContactRecord.id = ContactRecord.id || contact_id;
    var self = this;
    Checklists.bindFileUploadAdmin($('.contact-portrait .fileinput-button'), max_file_size).done(
      // On upload success
      function() {
        self.refreshContactPortrait();
        self.refreshAllChecklists();
        self.bindPortraitUploadButtonAvatar(max_file_size);
        self.openPortraitModal(true);
      }
    );
  },

  bindPortraitRotate: function(contact_id, portal, max_file_size) {
    var rotation = 0;
    var $dialog = portal ? $('.crop-dialog') : $('.crop-modal');
    var $buttons = $('button.rotate-portrait-right,button.rotate-portrait-left');
    $buttons.prop('disabled', false);

    $buttons.click(function() {
      $buttons.prop('disabled', true);
      var clockwise = $(this).hasClass('rotate-portrait-right');
      var rotation = clockwise ? 90 : -90;

      // These coordinates represent the crop window and are continously updated as it is adjusted
      // according to the jcrop onChange handler below. They're persisted on the portrait model so they need
      // to be sent and saved along with the rotation parameter
      var form = $dialog.find('form');
      var x = form.find('input[name=portrait\\[image_crop_x\\]]').val();
      var y = form.find('input[name=portrait\\[image_crop_y\\]]').val();
      var w = form.find('input[name=portrait\\[image_crop_w\\]]').val();
      var h = form.find('input[name=portrait\\[image_crop_h\\]]').val();

      $.ajax({
        url: Routes.contact_portrait_rotate_path(contact_id),
        data: {portal: portal, rotation: rotation, portrait: {'image_crop_x': x, 'image_crop_y': y, 'image_crop_w': w, 'image_crop_h': h}},
        type: "POST",
        success: function(response){
          if(portal){
            //replace the contents of the crop dialog manually since it's not a full modal on the parent portal
            $dialog.contents().remove();
            $dialog.html($(response).html());
            //Re-bind the submit and upload buttons to the "new" crop dialog data
            Checklists.bindCropButton();
            Checklists.bindFileUpload('.portrait-crop-upload', max_file_size);
          }
          else {
            Dialog._handle_update_modal_response($('.crop-modal'), response);
          }
        }
      }).then(function(){
        Checklists.bindPortraitCrop(true);
        Checklists.bindPortraitRotate(contact_id, portal, max_file_size);
      });
    });
  },

  portraitCropPreview: function(jcrop_element, coords) {
    var rx = 100 / coords.w;
    var ry = 100 / coords.h;
    var jcrop_w = $(jcrop_element).width();
    var jcrop_h = $(jcrop_element).height();

    $(jcrop_element).closest('.crop-dialog').find('.preview').find('img').css({
      width: Math.round(rx * jcrop_w) + 'px',
      height: Math.round(ry * jcrop_h) + 'px',
      marginLeft: '-' + Math.round(rx * coords.x) + 'px',
      marginTop: '-' + Math.round(ry * coords.y) + 'px'
    });
  },

  // Returns a new coordinates object that has been transformed for a new grid
  //
  // This is used to transform Jcrop geometry coordinates in one picture size
  // to another.  This is needed because some parent portal screen sizes are
  // too small to display the original 400x300 image.
  //
  // coords - original coordinates object
  // jcrop_geometry - JS obj that looks like {original: {x: 123, y: 123}, display: {x: 234, y: 234}}
  // flip - if true, transform the geometry in the other direction
  transformGeometry: function(coords, jcrop_geometry, flip) {
    var rx = flip ? (jcrop_geometry.display.x / jcrop_geometry.original.x) : (jcrop_geometry.original.x / jcrop_geometry.display.x);
    var ry = flip ? (jcrop_geometry.display.y / jcrop_geometry.original.y) : (jcrop_geometry.original.y / jcrop_geometry.display.y);
    return {
        x: Math.round(rx * coords.x),
        y: Math.round(ry * coords.y),
        w: Math.round(rx * coords.w),
        h: Math.round(ry * coords.h)
    };
  },

  // Get a JSON representation of the native and display dimensions of the jcrop pic
  getJcropGeometry: function(jcrop_element) {
    var img = jcrop_element.get(0);

    return {
      original: { x: img.naturalWidth, y: img.naturalHeight },
      display: { x: jcrop_element.width(), y: jcrop_element.height() }
    };
  },

  // Returns a boolean indicating whether both geometries from getJcropGeometry() are the same.
  geometryEquivalent: function(jcrop_geometry) {
    return (jcrop_geometry.original.x == jcrop_geometry.display.x) && (jcrop_geometry.original.y == jcrop_geometry.display.y);
  },

  portraitCropSetHiddenElements: function(jcrop_element, coords, alternate_geometry, form) {
    if (alternate_geometry) {
      coords = this.transformGeometry(coords, alternate_geometry);
    }

    form.find('input[name=portrait\\[image_crop_x\\]]').val(coords.x);
    form.find('input[name=portrait\\[image_crop_y\\]]').val(coords.y);
    form.find('input[name=portrait\\[image_crop_w\\]]').val(coords.w);
    form.find('input[name=portrait\\[image_crop_h\\]]').val(coords.h);
  },

  // Reloads the portrait image on the contact record via AJAX
  refreshContactPortrait: function(contact_id) {
    var portrait = $('.contact-portrait');

    $.ajax({
      url: Routes.contact_portrait_info_path(ContactRecord.id),
      success: function(data) {
        portrait.css("background-image", "url(" + data.url + ")");
        if (data.placeholder) {
          portrait.addClass("placeholder");
        } else {
          portrait.removeClass("placeholder");
          portrait.find(".portrait-control-bottom").show();
        }
      }
    });
  },

  // Triggers an event to refresh all Backbone checklists from the server
  refreshAllChecklists: function() {
    $('.contact .checklist .lists').trigger('refresh');
  },

  bindDeferredRefresh: function() {
    var self = this;
    var deferred = $.Deferred();
    deferred.done(function() {
      self.refreshContactPortrait();
    });
    $('.crop-modal').data("deferred_result", deferred);
  },

  // Bind controls for contact portrait on contact record
  bindContactPortraitControls: function(contact_id) {
    ContactRecord.id = ContactRecord.id || contact_id;
    var self = this;
    var portrait = $('.contact-portrait');

    $('.contact-portrait .delete').on('click', function() {
      if (window.confirm('Do you really want to delete this image?')) {
        // Send AJAX request to delete
        $.ajax({
          method: "DELETE",
          url: Routes.contact_portrait_path(ContactRecord.id || contact_id),
          complete: function(data) {
            // Refresh regardless of success
            self.refreshContactPortrait();
            self.refreshAllChecklists();

            if (data && data.responseJSON && data.responseJSON.flash) {
              Utility.displayFlashMessages(data.responseJSON.flash);
            }
          }
        });
      }
    });

    $('.contact-portrait .edit').on('click', function() {
      self.openPortraitModal();
    });
  },

  calculatePortraitCrop: function(jcrop_element){
    var self = this;
    var initial_selection = JSON.parse(jcrop_element.attr('data-initial-selection'));

    // Used to find hidden elements
    var form = $(jcrop_element).closest('.crop-dialog').find('form').first();

    var jcrop_geometry = self.getJcropGeometry(jcrop_element);
    var alternate_geometry;

    if (!self.geometryEquivalent(jcrop_geometry)) {
      initial_selection = self.transformGeometry(initial_selection, jcrop_geometry, true);
      alternate_geometry = jcrop_geometry;
    } else {
      alternate_geometry = false;
    }

    // Clone the array & convert coords from x,y,w,h -> x,y,x2,y2 - Jcrop requires initial selection to be in this format.
    var converted_selection = [
      initial_selection.x,
      initial_selection.y,
      (initial_selection.x + initial_selection.w),
      (initial_selection.y + initial_selection.h)
    ];

    // Define onChange function here so that it has access to which
    // particular Jcrop element is being manipulated.
    var onChange = function (coords) {
      self.portraitCropPreview(jcrop_element, coords);
      self.portraitCropSetHiddenElements(jcrop_element, coords, alternate_geometry, form);
    };

    jcrop_element.Jcrop({
      // This keySupport attribute is a workaround for a scroll/jumping bug in Jcrop.
      keySupport: false,
      aspectRatio: 1,
      onChange: onChange,
      onSelect: onChange,
      setSelect: converted_selection
    }, function () {
      self.jcropAPI = this;
    })
  },

  bindPortraitCrop: function(reload) {
    var self = this;

    $('.crop-dialog .original').each(function() {
      var jcrop_element = $(this);
      var img = $(jcrop_element.get(0));

      // jQuery tries to select the dimensions of the actual crop window before the image
      // has (re)loaded, resulting in height and width of 0, so we call the calculation logic
      // after the img has loaded if we're reloading the image (i.e. the user has pressed
      // the 'Pick a different photo' button)
      if (reload) {
        img.on('load', function () {
          self.calculatePortraitCrop(jcrop_element);
          }
        )
      } else {
        self.calculatePortraitCrop(jcrop_element);
      }
    });
  },


  bindPortraitDrawer: function() {
    var self = this;
    $('.portrait-drawer-toggle').on('click', function(e) {
      $(this).closest('.checklist-item-details').find('.crop-dialog').slideToggle({
        complete: function() {
          // Upon slide animation completion...
          if ($(this).is(":hidden")) { // when closed
            // Disable Jcrop
            self.jcropAPI.destroy();
          } else { // when open
            // Enable Jcrop
            self.bindPortraitCrop();
          }
        }
      });
      return false;
    });
  },

  openPortraitDrawers: function() {
    $('.portrait-drawer-toggle').trigger('click');
  },

  // Activates the Portrait cropping modal
  // hide_cancel - boolean indicating whether to hide the cancel button on the modal
  openPortraitModal: function(hide_cancel) {
    var extra_params = {};
    if (hide_cancel) {
      extra_params.hide_cancel = true;
    }

    Dialog.open(Routes.edit_contact_portrait_path(ContactRecord.id, extra_params));
  },

  // Bind the crop button on the crop drawer on the parent portal
  bindCropButton: function() {
    // Disable the file upload input because IE & Firefox will send an empty string otherwise
    $('.crop-dialog .button-row button.crop').on('click', function() {
      $(this).closest('.crop-dialog').find('input[type=file]').attr('disabled', true);
    });
  },

  setupFileManagementModal: function(max_file_size, checklist_item_id){
    var theModal = $('.manage-uploaded-files');
    var checklist_item = $('.checklist-item[checklist_item_id='+checklist_item_id+']');
    var dirty = false;

    var refreshCounts = function(){
      // Update counts.
      var count = theModal.find('.file-details').length;
      checklist_item.find('.checklist-item-hint').text(count + " Files");
      if(count <= 0) {
        dirty = true;
      }
    };
    refreshCounts();

    // bind edit links
    // Edit button clicked
    $(".manage-uploaded-files .edit-file-name-link").click(function(e){
      const clicked = $(this);
      const clickedRow = clicked.closest('.file-details');

      clickedRow.find('.delete-download-edit').hide()
      clickedRow.find('.file-name').hide()
      clickedRow.find('.edit-confirmation').removeClass('d-none').show()
      clickedRow.find('.edit-file-name-input').removeClass('d-none').show()

      e.preventDefault()
      return false
    })
    // Edit cancel button clicked
    $(".manage-uploaded-files .edit-confirmation .cancel").click(function (e) {
      const clicked = $(this);
      const clickedRow = clicked.closest('.file-details');

      clickedRow.find('.edit-confirmation').hide();
      clickedRow.find('.edit-file-name-input').hide();
      clickedRow.find('.delete-download-edit').removeClass('d-none').show();
      clickedRow.find('.file-name').removeClass('d-none').show();

      e.preventDefault();
      return false;
    });
    // Edit confirm name change button clicked
    $(".manage-uploaded-files .edit-confirmation .ok").click(function (e) {
      const clicked = $(this);
      var clickedRow = clicked.closest('.file-details');
      const updatedInputValue = clickedRow.find(`#${clicked[0].id}`)[0].value

      clickedRow.find('.edit-confirmation').hide();
      clickedRow.find('.edit-file-name-input').hide();
      clickedRow.find('.file-name').removeClass('d-none').show();
      clickedRow.find('.file-name').text('Updating...');

      $.ajax({
        method: 'put',
        data: {
          "_method": "update",
          "from_modal": "true",
          id: clicked[0].id,
          value: updatedInputValue,
        },
        url: clicked.attr('href'),
        success: function (res) {
          clickedRow.find('.delete-download-edit').removeClass('d-none').show();
          clickedRow.find('.file-name').text(res.name)
          refreshCounts();
        }
      });

      e.preventDefault();
      return false;
    });

    // bind delete links
    $(".manage-uploaded-files .delete-file-link").click(function(e){
      var clicked = $(this);
      var clickedRow = clicked.closest('.file-details');

      clickedRow.find('.delete-download-edit').hide();
      clickedRow.find('.delete-confirmation').removeClass('d-none').show();

      e.preventDefault();
      return false;
    });

    $(".manage-uploaded-files .delete-confirmation .ok").click(function(e){
      var clicked = $(this);
      var clickedRow = clicked.closest('.file-details');

      clickedRow.find('.delete-confirmation').hide();
      clickedRow.find('.file-name').text('Deleting...');
      clickedRow.find('.spinner').show().removeClass('d-none');

      $.ajax({
        method: 'post',
        data: {
          "_method": "delete",
          "from_modal": "true"
        },
        url: clicked.attr('href'),
        success: function(){
          clickedRow.remove();
          refreshCounts();
        }
      })
      .then(() => {
        var count = theModal.find('.file-details').length;
        if (count == 0) {
          $('.no-file-details').removeClass('d-none').show()
        }
      });

      e.preventDefault();
      return false;
    });

    $(".manage-uploaded-files .delete-confirmation .cancel").click(function(e){
      var clicked = $(this);
      var clickedRow = clicked.closest('.file-details');

      clickedRow.find('.delete-confirmation').hide();
      clickedRow.find('.delete-download-edit').removeClass('d-none').show();

      e.preventDefault();
      return false;
    });

    // bind file upload button
    Checklists.bindFileUploadAdmin($(".manage-uploaded-files .fileinput-button input[type=file]"),max_file_size).then(
      function(){
        // submitting the form triggers dialog to reload in place...
        Dialog.submit(theModal, $("form", theModal), null);
      },
      function(){
        alert("error");
      }
    );

    // Reload page when dialog closes
    theModal.on('hide.bs.modal', function () {
      $('.modal-body', theModal).remove();

      if(dirty && checklist_item.length) {
        $('body').addClass('loading');
        window.location.reload();
      }
    });
  }
};

global.Checklists = global.Checklists || Checklists;

export default Checklists;
