import Backbone from 'backbone';

import ChecklistHeader from './checklist_header';

import AdHocFormItemView from './ad_hoc_form_item';
import ApplicationItemView from './application_item';
import ApplicationFeeItemView from './application_fee_item';
import ChecklistItemView from './checklist_item';
import ChecklistRequestItemView from './checklist_request_item';
import ContractItemView from './contract_item';
import InquiryItemView from './inquiry_item';

import CustomFeeItemView from './custom_fee_item';
import DepositItemView from './deposit_item';
import FactsItemView from './facts_item';
import FileUploadItemView from './file_upload_item';
import MultiFileUploadItemView from './multi_file_upload_item';
import PortraitItemView from './portrait_item';
import RecommendationRequestItemView from './recommendation_request_item';

import ChecklistTemplate from '../../../../templates/checklist/checklist.jst.ejs';

const itemViews = {
  AdHocFormItemView,
  ApplicationItemView,
  ApplicationFeeItemView,
  ChecklistItemView,
  ChecklistRequestItemView,
  ContractItemView,
  CustomFeeItemView,
  DepositItemView,
  FactsItemView,
  FileUploadItemView,
  MultiFileUploadItemView,
  PortraitItemView,
  RecommendationRequestItemView,
  InquiryItemView
};

const Checklist = Backbone.View.extend({

  initialize: function () {
    this.listenTo(this.model.get('required_items'), "change reset add remove", this.renderHeader);
  },

  render: function () {
    var self = this;

    this.$el.html(ChecklistTemplate({
      optional_items: this.model.get('optional_items').length,
      required_items: this.model.get('required_items').length,
      name: this.model.get('name')
    }));

    var element = $(this.el).find('.required-items ul');

    this.model.get('required_items').each(function (item) {
      var view = self._viewForItem(item);
      element.append(view.render().el);
    });

    element = $(this.el).find('.optional-items ul');
    this.model.get('optional_items').each(function (item) {
      var view = self._viewForItem(item);
      element.append(view.render().el);
    });

    this.renderHeader();
  },

  renderHeader: function () {
    var completed = 0;
    this.model.get('required_items').each(function (item) {
      if (item.get('completion_date')) {
        completed++;
      }
    });
    var percentage = this.model.get('required_items').length > 0 ?
        parseInt(completed / this.model.get('required_items').length * 100, 10) :
        0;

    new ChecklistHeader({
      el: '.contact .checklist .lists .tab-' + this.model.get('name') + ' .header',
      percentage: percentage,
      workflows: this.options.workflows,
      name: this.model.get('name')
    }).render();
  },

  _viewForItem: function (item) {
    var viewClass = item.get('back_bone_type') + 'ItemView';

    return (viewClass in itemViews)
      ? new itemViews[viewClass]({ model: item })
      : new ChecklistItemView({ model: item });
  }
});

export default Checklist;
