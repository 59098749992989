module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'header\'>\n  <h2>Checklist</h2>\n</div>\n\n\n<div class="helper-text">\n  Items required to complete the current workflow\n</div>\n\n\n';
 checklists.each(function(model) { ;
__p += '\n  <div class="tab-' +
((__t = ( model.get('name'))) == null ? '' : __t) +
' ' +
((__t = ( model.get('name') != current_workflow ? 'hidden' : '')) == null ? '' : __t) +
'"></div>\n';
 }); ;
__p += '\n';

}
return __p
}