/*
File generated by js-routes 1.4.14
Based on Rails 6.1.4.7 routes of SchoolAdmin::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// account => /accounts/:id(.:format)
  // function(id, options)
  account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_update_owners => /accounts/:account_id/update_owners(.:format)
  // function(account_id, options)
  account_update_owners_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"update_owners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accounts => /accounts(.:format)
  // function(options)
  accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// accounts_admin_billing => /admin/billing/accounts(.:format)
  // function(options)
  accounts_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// activate_contract_fee => /setup/contract_fees/:id/activate(.:format)
  // function(id, options)
  activate_contract_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// activities => /activities(.:format)
  // function(options)
  activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// activities_api_contact => /api/contacts/:id/activities(.:format)
  // function(id, options)
  activities_api_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// activity => /activities/:id(.:format)
  // function(id, options)
  activity_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// activity_items => /setup/activity_items(.:format)
  // function(options)
  activity_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"activity_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ad_hoc_form => /forms/:id(.:format)
  // function(id, options)
  ad_hoc_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ad_hoc_form_update_payment => /forms/:id/update_payment(.:format)
  // function(id, options)
  ad_hoc_form_update_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// ad_hoc_template => /setup/ad_hoc_templates/:id(.:format)
  // function(id, options)
  ad_hoc_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"ad_hoc_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// ad_hoc_templates => /setup/ad_hoc_templates/show(.:format)
  // function(options)
  ad_hoc_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"ad_hoc_templates",false],[2,[7,"/",false],[2,[6,"show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// add_page_form_template => /setup/form_templates/:id/add_page(.:format)
  // function(id, options)
  add_page_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"add_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// add_to_workflow => /contacts/add_to_workflow/:id(.:format)
  // function(id, options)
  add_to_workflow_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"add_to_workflow",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin => /setup/admins/:id(.:format)
  // function(id, options)
  admin_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_application => /admin/applications/:id(.:format)
  // function(id, options)
  admin_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_appointment => /admin/appointments/:id(.:format)
  // function(id, options)
  admin_appointment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_appointments => /admin/appointments(.:format)
  // function(options)
  admin_appointments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"appointments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_available_time => /admin/available_times/:id(.:format)
  // function(id, options)
  admin_available_time_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_available_time_appointment => /admin/available_times/:available_time_id/appointments/:id(.:format)
  // function(available_time_id, id, options)
  admin_available_time_appointment_path: Utils.route([["available_time_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_available_time_appointments => /admin/available_times/:available_time_id/appointments(.:format)
  // function(available_time_id, options)
  admin_available_time_appointments_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_available_time_appointments_new_form => /admin/available_times/:available_time_id/appointments/new/form(.:format)
  // function(available_time_id, options)
  admin_available_time_appointments_new_form_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// admin_available_time_appointments_new_form_validate => /admin/available_times/:available_time_id/appointments/new/form/validate(.:format)
  // function(available_time_id, options)
  admin_available_time_appointments_new_form_validate_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// admin_available_time_confirm_delete => /admin/available_times/:available_time_id/confirm_delete(.:format)
  // function(available_time_id, options)
  admin_available_time_confirm_delete_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"confirm_delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_available_times => /admin/available_times(.:format)
  // function(options)
  admin_available_times_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_billing => /admin/billing(.:format)
  // function(options)
  admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_calendar => /admin/calendars/:id(.:format)
  // function(id, options)
  admin_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_calendar_available_time => /admin/calendars/:calendar_id/available_times/:id(.:format)
  // function(calendar_id, id, options)
  admin_calendar_available_time_path: Utils.route([["calendar_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_calendar_available_times => /admin/calendars/:calendar_id/available_times(.:format)
  // function(calendar_id, options)
  admin_calendar_available_times_path: Utils.route([["calendar_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"available_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_calendar_schedules => /admin/calendars/:calendar_id/schedules(.:format)
  // function(calendar_id, options)
  admin_calendar_schedules_path: Utils.route([["calendar_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_calendars => /admin/calendars(.:format)
  // function(options)
  admin_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_contact => /admin/contacts/:id(.:format)
  // function(id, options)
  admin_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard => /admin(.:format)
  // function(options)
  admin_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_edit_inquiry => /admin/inquiries/:id/edit(.:format)
  // function(id, options)
  admin_edit_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_inquiry => /admin/inquiries/:id(.:format)
  // function(id, options)
  admin_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_new_inquiry => /admin/new_inquiry(.:format)
  // function(options)
  admin_new_inquiry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"new_inquiry",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_password => /admins/password(.:format)
  // function(options)
  admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_pdf_inquiry => /admin/inquiries/:id/pdf(.:format)
  // function(id, options)
  admin_pdf_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_reset_password => /setup/admins/:admin_id/reset_password(.:format)
  // function(admin_id, options)
  admin_reset_password_path: Utils.route([["admin_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"admin_id",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_saved_searches => /admin/saved_searches(.:format)
  // function(options)
  admin_saved_searches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"saved_searches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_schedule => /admin/schedules/:id(.:format)
  // function(id, options)
  admin_schedule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_session => /admins/sign_in(.:format)
  // function(options)
  admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_thank_you_inquiry => /admin/inquiries/:id/thank_you(.:format)
  // function(id, options)
  admin_thank_you_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"thank_you",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_unlock => /admins/unlock(.:format)
  // function(options)
  admin_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_update_field => /admin/update_field(.:format)
  // function(options)
  admin_update_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"update_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_update_inquiry => /admin/inquiries/:id/edit(.:format)
  // function(id, options)
  admin_update_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_zendesk_session => /admin/zendesk_session(.:format)
  // function(options)
  admin_zendesk_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"zendesk_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admins => /setup/admins(.:format)
  // function(options)
  admins_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admins_to_admin_redirect => /admins(.:format)
  // function(options)
  admins_to_admin_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admission_refresh_tracker => /setup/admission_refresh_tracker(.:format)
  // function(options)
  admission_refresh_tracker_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admission_refresh_tracker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_ad_hoc_templates => /setup/admissions_ad_hoc_templates(.:format)
  // function(options)
  admissions_ad_hoc_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_ad_hoc_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_applicants => /admissions/applicants(.:format)
  // function(options)
  admissions_applicants_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admissions",false],[2,[7,"/",false],[2,[6,"applicants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_checklist_item => /setup/admissions_checklist_items/:id(.:format)
  // function(id, options)
  admissions_checklist_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_checklist_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admissions_checklist_items => /setup/admissions_checklist_items(.:format)
  // function(options)
  admissions_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_checklist_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_in_enrollment => /admissions/in_enrollment(.:format)
  // function(options)
  admissions_in_enrollment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admissions",false],[2,[7,"/",false],[2,[6,"in_enrollment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_inquiries => /admissions/inquiries(.:format)
  // function(options)
  admissions_inquiries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admissions",false],[2,[7,"/",false],[2,[6,"inquiries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_prospects => /admissions/prospects(.:format)
  // function(options)
  admissions_prospects_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admissions",false],[2,[7,"/",false],[2,[6,"prospects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_reader_reviews => /admissions/reader_reviews(.:format)
  // function(options)
  admissions_reader_reviews_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admissions",false],[2,[7,"/",false],[2,[6,"reader_reviews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_school_years => /setup/admissions_school_years(.:format)
  // function(options)
  admissions_school_years_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_school_years",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admissions_school_years_make_active => /setup/admissions_school_years/make_active/:id(.:format)
  // function(id, options)
  admissions_school_years_make_active_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_school_years",false],[2,[7,"/",false],[2,[6,"make_active",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admissions_school_years_make_draft => /setup/admissions_school_years/make_draft/:id(.:format)
  // function(id, options)
  admissions_school_years_make_draft_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_school_years",false],[2,[7,"/",false],[2,[6,"make_draft",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// aging_admin_billing => /admin/billing/aging(.:format)
  // function(options)
  aging_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"aging",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_activity_item => /api/activity_items/:id(.:format)
  // function(id, options)
  api_activity_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"activity_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_activity_items => /api/activity_items(.:format)
  // function(options)
  api_activity_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"activity_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_api_billing_accounts_summary => /api/api/billing/accounts/summary(.:format)
  // function(options)
  api_api_billing_accounts_summary_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_api_billing_summary => /api/api/billing/summary(.:format)
  // function(options)
  api_api_billing_summary_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_api_credential => /api/api_credentials/:id(.:format)
  // function(id, options)
  api_api_credential_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_credentials",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_api_credentials => /api/api_credentials(.:format)
  // function(options)
  api_api_credentials_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_credentials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_api_user => /api/api_users/:id(.:format)
  // function(id, options)
  api_api_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_api_users => /api/api_users(.:format)
  // function(options)
  api_api_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_appointment => /api/appointments/:id(.:format)
  // function(id, options)
  api_appointment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_appointments => /api/appointments(.:format)
  // function(options)
  api_appointments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"appointments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_auth_payment_account => /api/auth_payment_accounts/:id(.:format)
  // function(id, options)
  api_auth_payment_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth_payment_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_auth_payment_accounts => /api/auth_payment_accounts(.:format)
  // function(options)
  api_auth_payment_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth_payment_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_auth_payment_methods => /api/auth_payment_methods(.:format)
  // function(options)
  api_auth_payment_methods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth_payment_methods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_account => /api/audits/billing_accounts/:id(.:format)
  // function(id, options)
  api_billing_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"billing_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_billing_account_access => /api/audits/billing_account_accesses/:id(.:format)
  // function(id, options)
  api_billing_account_access_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"billing_account_accesses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_billing_account_accesses => /api/audits/billing_account_accesses(.:format)
  // function(options)
  api_billing_account_accesses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"billing_account_accesses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_account_contacts => /api/billing_account_contacts(.:format)
  // function(options)
  api_billing_account_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_account_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_account_potential_payers => /api/billing_account_potential_payers(.:format)
  // function(options)
  api_billing_account_potential_payers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_account_potential_payers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_accounts => /api/audits/billing_accounts(.:format)
  // function(options)
  api_billing_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"billing_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_aging_report_index => /api/billing_aging_report(.:format)
  // function(options)
  api_billing_aging_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_aging_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_ar_report => /api/billing_ar_report/:id(.:format)
  // function(id, options)
  api_billing_ar_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_ar_report",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_ar_report_index => /api/billing_ar_report(.:format)
  // function(options)
  api_billing_ar_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_ar_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_categories => /api/audits/billing_categories(.:format)
  // function(options)
  api_billing_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"billing_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_category => /api/audits/billing_categories/:id(.:format)
  // function(id, options)
  api_billing_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"billing_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_billing_category_group => /api/billing_category_groups/:id(.:format)
  // function(id, options)
  api_billing_category_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_category_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_category_groups => /api/billing_category_groups(.:format)
  // function(options)
  api_billing_category_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_category_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_category_report_index => /api/billing_category_report(.:format)
  // function(options)
  api_billing_category_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_category_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_config => /api/audits/billing_configs/:id(.:format)
  // function(id, options)
  api_billing_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"billing_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_billing_configs => /api/audits/billing_configs(.:format)
  // function(options)
  api_billing_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"billing_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_contact => /api/billing_contacts/:id(.:format)
  // function(id, options)
  api_billing_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_contacts => /api/billing_contacts(.:format)
  // function(options)
  api_billing_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_import_jobs => /api/billing_import_jobs(.:format)
  // function(options)
  api_billing_import_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_import_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_item => /api/billing_items/:id(.:format)
  // function(id, options)
  api_billing_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_items => /api/billing_items(.:format)
  // function(options)
  api_billing_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_ledger_items => /api/billing_ledger_items(.:format)
  // function(options)
  api_billing_ledger_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_ledger_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_payers => /api/billing_payers(.:format)
  // function(options)
  api_billing_payers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_payers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_payment => /api/billing_payments/:id(.:format)
  // function(id, options)
  api_billing_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_billing_payments => /api/billing_payments(.:format)
  // function(options)
  api_billing_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_billing_students => /api/billing_students(.:format)
  // function(options)
  api_billing_students_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_blackbaud_config => /api/blackbaud_configs/:id(.:format)
  // function(id, options)
  api_blackbaud_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_blackbaud_configs => /api/blackbaud_configs(.:format)
  // function(options)
  api_blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_bulk_action_event => /api/bulk_action_events/:id(.:format)
  // function(id, options)
  api_bulk_action_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_action_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_bulk_action_events => /api/bulk_action_events(.:format)
  // function(options)
  api_bulk_action_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_action_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_bulk_countersign => /api/bulk_countersign(.:format)
  // function(options)
  api_bulk_countersign_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_countersign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_bulk_file_page => /api/bulk_file_pages/:id(.:format)
  // function(id, options)
  api_bulk_file_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_bulk_file_pages => /api/bulk_file_pages(.:format)
  // function(options)
  api_bulk_file_pages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_bulk_file_workflow => /api/bulk_file_workflows/:id(.:format)
  // function(id, options)
  api_bulk_file_workflow_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_workflows",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_bulk_file_workflows => /api/bulk_file_workflows(.:format)
  // function(options)
  api_bulk_file_workflows_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_workflows",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_calendar => /api/calendars/:id(.:format)
  // function(id, options)
  api_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_calendar_cancellation_email_template => /api/calendars/:calendar_id/cancellation_email_template(.:format)
  // function(calendar_id, options)
  api_calendar_cancellation_email_template_path: Utils.route([["calendar_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"cancellation_email_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_calendar_confirmation_email_template => /api/calendars/:calendar_id/confirmation_email_template(.:format)
  // function(calendar_id, options)
  api_calendar_confirmation_email_template_path: Utils.route([["calendar_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"confirmation_email_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_calendar_reminder_email_template => /api/calendars/:calendar_id/reminder_email_template(.:format)
  // function(calendar_id, options)
  api_calendar_reminder_email_template_path: Utils.route([["calendar_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"reminder_email_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_calendars => /api/calendars(.:format)
  // function(options)
  api_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"calendars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_checklist_answer => /api/checklist_answers/:id(.:format)
  // function(id, options)
  api_checklist_answer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_checklist_answer_link => /api/checklist_answer_links/:id(.:format)
  // function(id, options)
  api_checklist_answer_link_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"checklist_answer_links",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_checklist_answer_links => /api/checklist_answer_links(.:format)
  // function(options)
  api_checklist_answer_links_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"checklist_answer_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_checklist_answers => /api/checklist_answers(.:format)
  // function(options)
  api_checklist_answers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_checklist_items => /api/checklist_items(.:format)
  // function(options)
  api_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"checklist_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_common_checklist_group => /api/common_checklist_groups/:id(.:format)
  // function(id, options)
  api_common_checklist_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"common_checklist_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_common_checklist_groups => /api/common_checklist_groups(.:format)
  // function(options)
  api_common_checklist_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"common_checklist_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_common_form => /api/common_forms/:id(.:format)
  // function(id, options)
  api_common_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"common_forms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_common_forms => /api/common_forms(.:format)
  // function(options)
  api_common_forms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"common_forms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_communication_plan => /api/communication_plans/:id(.:format)
  // function(id, options)
  api_communication_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"communication_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_communication_plans => /api/communication_plans(.:format)
  // function(options)
  api_communication_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"communication_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_contact => /api/contacts/:id(.:format)
  // function(id, options)
  api_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_contact_installment_schedule => /api/contact_installment_schedules/:id(.:format)
  // function(id, options)
  api_contact_installment_schedule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contact_installment_schedules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_contacts => /api/contacts(.:format)
  // function(options)
  api_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_contract => /api/contracts/:id(.:format)
  // function(id, options)
  api_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_contract_fee => /api/contract_fees/:id(.:format)
  // function(id, options)
  api_contract_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_contract_fees => /api/contract_fees(.:format)
  // function(options)
  api_contract_fees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_contracts => /api/contracts(.:format)
  // function(options)
  api_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_countersign_contracts => /api/countersign_contracts(.:format)
  // function(options)
  api_countersign_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"countersign_contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_csp => /api/csp(.:format)
  // function(options)
  api_csp_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"csp",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_customer => /api/audits/customers/:id(.:format)
  // function(id, options)
  api_customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_customer_infos => /api/customer_infos(.:format)
  // function(options)
  api_customer_infos_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_infos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_customer_ledger_items => /api/customer_ledger_items(.:format)
  // function(options)
  api_customer_ledger_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_ledger_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_customer_note => /api/customer_notes/:id(.:format)
  // function(id, options)
  api_customer_note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_customer_notes => /api/customer_notes(.:format)
  // function(options)
  api_customer_notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_customer_oauth_config => /api/customer_oauth_config(.:format)
  // function(options)
  api_customer_oauth_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_oauth_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_customers => /api/audits/customers(.:format)
  // function(options)
  api_customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_data_retention => /api/data_retention(.:format)
  // function(options)
  api_data_retention_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"data_retention",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_delayed_job => /api/delayed_jobs/:id(.:format)
  // function(id, options)
  api_delayed_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"delayed_jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_delayed_jobs => /api/delayed_jobs(.:format)
  // function(options)
  api_delayed_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"delayed_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_dispute => /api/disputes/:id(.:format)
  // function(id, options)
  api_dispute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"disputes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_disputes => /api/disputes(.:format)
  // function(options)
  api_disputes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"disputes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_download_all_files_and_forms => /api/download_all_files_and_forms/:id(.:format)
  // function(id, options)
  api_download_all_files_and_forms_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"download_all_files_and_forms",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_email_histories => /api/email_histories(.:format)
  // function(options)
  api_email_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_histories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_email_history => /api/email_histories/:id(.:format)
  // function(id, options)
  api_email_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_histories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_email_snippet => /api/email_snippets/:id(.:format)
  // function(id, options)
  api_email_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_email_snippets => /api/email_snippets(.:format)
  // function(options)
  api_email_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_emails => /api/emails(.:format)
  // function(options)
  api_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_external_contact => /api/external/contacts/:id(.:format)
  // function(id, options)
  api_external_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_external_contact_status => /api/external/contact_statuses/:name(.:format)
  // function(name, options)
  api_external_contact_status_path: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_external_contact_statuses => /api/external/contact_statuses(.:format)
  // function(options)
  api_external_contact_statuses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_external_contacts => /api/external/contacts(.:format)
  // function(options)
  api_external_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_external_customer => /api/external/customer(.:format)
  // function(options)
  api_external_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_external_field => /api/external/fields/:id(.:format)
  // function(id, options)
  api_external_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_external_fields => /api/external/fields(.:format)
  // function(options)
  api_external_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_external_grade => /api/external/grades/:id(.:format)
  // function(id, options)
  api_external_grade_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"grades",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_external_grades => /api/external/grades(.:format)
  // function(options)
  api_external_grades_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"grades",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_external_ping => /api/external/ping(.:format)
  // function(options)
  api_external_ping_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"ping",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_external_school_year => /api/external/school_years/:id(.:format)
  // function(id, options)
  api_external_school_year_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_external_school_years => /api/external/school_years(.:format)
  // function(options)
  api_external_school_years_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"school_years",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_external_user => /api/external/users/:id(.:format)
  // function(id, options)
  api_external_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_external_users => /api/external/users(.:format)
  // function(options)
  api_external_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_fee => /api/fees/:id(.:format)
  // function(id, options)
  api_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_fee_group => /api/fee_groups/:id(.:format)
  // function(id, options)
  api_fee_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_fee_groups => /api/fee_groups(.:format)
  // function(options)
  api_fee_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_fee_rule => /api/fee_rules/:id(.:format)
  // function(id, options)
  api_fee_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_fee_rules => /api/fee_rules(.:format)
  // function(options)
  api_fee_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_fees => /api/fees(.:format)
  // function(options)
  api_fees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_field => /api/fields/:id(.:format)
  // function(id, options)
  api_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_fields => /api/fields(.:format)
  // function(options)
  api_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_flash_index => /api/flash(.:format)
  // function(options)
  api_flash_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"flash",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_form_inbox_items => /api/form_inbox_items(.:format)
  // function(options)
  api_form_inbox_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"form_inbox_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_form_template => /api/form_templates/:id(.:format)
  // function(id, options)
  api_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_global_widget => /api/global_widgets/:canonical_name(.:format)
  // function(canonical_name, options)
  api_global_widget_path: Utils.route([["canonical_name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"global_widgets",false],[2,[7,"/",false],[2,[3,"canonical_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_global_widgets => /api/global_widgets(.:format)
  // function(options)
  api_global_widgets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"global_widgets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_grades => /api/grades(.:format)
  // function(options)
  api_grades_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"grades",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_health => /api/health(.:format)
  // function(options)
  api_health_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"health",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_image_resource => /api/image_resources/:id(.:format)
  // function(id, options)
  api_image_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"image_resources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_image_resources => /api/image_resources(.:format)
  // function(options)
  api_image_resources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"image_resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_letter_snippet => /api/letter_snippets/:id(.:format)
  // function(id, options)
  api_letter_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"letter_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_letter_snippets => /api/letter_snippets(.:format)
  // function(options)
  api_letter_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"letter_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_line_item => /api/line_items/:id(.:format)
  // function(id, options)
  api_line_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"line_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_line_items => /api/line_items(.:format)
  // function(options)
  api_line_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"line_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_list_page_configs => /api/list_page_configs(.:format)
  // function(options)
  api_list_page_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"list_page_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_list_page_configs_fields => /api/list_page_configs_fields(.:format)
  // function(options)
  api_list_page_configs_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"list_page_configs_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_mailing_label_snippet => /api/mailing_label_snippets/:id(.:format)
  // function(id, options)
  api_mailing_label_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mailing_label_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_mailing_label_snippets => /api/mailing_label_snippets(.:format)
  // function(options)
  api_mailing_label_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mailing_label_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_mms => /api/mms(.:format)
  // function(options)
  api_mms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_mms_histories => /api/mms_histories(.:format)
  // function(options)
  api_mms_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms_histories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_mms_snippet => /api/mms_snippets/:id(.:format)
  // function(id, options)
  api_mms_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_mms_snippets => /api/mms_snippets(.:format)
  // function(options)
  api_mms_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_orders => /api/orders(.:format)
  // function(options)
  api_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_overview => /setup/api_overview(.:format)
  // function(options)
  api_overview_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"api_overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_payment_plan => /api/payment_plans/:id(.:format)
  // function(id, options)
  api_payment_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_payment_plan_schedule => /api/payment_plan_schedules/:id(.:format)
  // function(id, options)
  api_payment_plan_schedule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plan_schedules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_payment_plan_schedules => /api/payment_plan_schedules(.:format)
  // function(options)
  api_payment_plan_schedules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plan_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_payment_plans => /api/payment_plans(.:format)
  // function(options)
  api_payment_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_payment_profile => /api/audits/payment_profiles/:id(.:format)
  // function(id, options)
  api_payment_profile_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"payment_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_payment_profiles => /api/audits/payment_profiles(.:format)
  // function(options)
  api_payment_profiles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"audits",false],[2,[7,"/",false],[2,[6,"payment_profiles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_payment_verification => /api/payment_verifications/:id(.:format)
  // function(id, options)
  api_payment_verification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_verifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_payment_verifications => /api/payment_verifications(.:format)
  // function(options)
  api_payment_verifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_verifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_power_school_contact => /api/power_school_contacts/:id(.:format)
  // function(id, options)
  api_power_school_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"power_school_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_power_school_contacts => /api/power_school_contacts(.:format)
  // function(options)
  api_power_school_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"power_school_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_preview_emails => /api/preview_emails(.:format)
  // function(options)
  api_preview_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"preview_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_preview_mms => /api/preview_mms(.:format)
  // function(options)
  api_preview_mms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"preview_mms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_rate_cards => /api/rate_cards(.:format)
  // function(options)
  api_rate_cards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"rate_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_refunds => /api/refunds(.:format)
  // function(options)
  api_refunds_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"refunds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_reindex => /api/reindex(.:format)
  // function(options)
  api_reindex_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reindex",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_related_contacts => /api/related_contacts(.:format)
  // function(options)
  api_related_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"related_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_relationship => /api/relationships/:id(.:format)
  // function(id, options)
  api_relationship_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"relationships",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_relationships => /api/relationships(.:format)
  // function(options)
  api_relationships_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"relationships",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_reporting_combined_enrollment_total_report => /api/reporting/combined_enrollment_total_report(.:format)
  // function(options)
  api_reporting_combined_enrollment_total_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"combined_enrollment_total_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_reporting_combined_funnel_report => /api/reporting/combined_funnel_report(.:format)
  // function(options)
  api_reporting_combined_funnel_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"combined_funnel_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_reporting_key_feature_report => /api/reporting/key_feature_report(.:format)
  // function(options)
  api_reporting_key_feature_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"key_feature_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_reset_session_timeout => /api/reset_session_timeout(.:format)
  // function(options)
  api_reset_session_timeout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reset_session_timeout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_revenue_recognition_report_index => /api/revenue_recognition_report(.:format)
  // function(options)
  api_revenue_recognition_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"revenue_recognition_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_review => /api/reviews/:id(.:format)
  // function(id, options)
  api_review_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_review_activity_items => /api/review_activity_items(.:format)
  // function(options)
  api_review_activity_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_activity_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_review_checklist_items => /api/review_checklist_items(.:format)
  // function(options)
  api_review_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_checklist_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_review_comment => /api/reviews/:review_id/comment/:checklist_item_id(.:format)
  // function(review_id, checklist_item_id, options)
  api_review_comment_path: Utils.route([["review_id",true],["checklist_item_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"review_id",false],[2,[7,"/",false],[2,[6,"comment",false],[2,[7,"/",false],[2,[3,"checklist_item_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_review_configs => /api/review_configs(.:format)
  // function(options)
  api_review_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_review_contact => /api/review_contacts/:id(.:format)
  // function(id, options)
  api_review_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_review_contacts => /api/review_contacts(.:format)
  // function(options)
  api_review_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_review_criteria => /api/review_criteria(.:format)
  // function(options)
  api_review_criteria_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_criteria",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_review_criterium => /api/review_criteria/:id(.:format)
  // function(id, options)
  api_review_criterium_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_criteria",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_review_document => /api/review_documents/:checklist_answer_id(.:format)
  // function(checklist_answer_id, options)
  api_review_document_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_documents",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_review_field => /api/review_fields/:id(.:format)
  // function(id, options)
  api_review_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_review_fields => /api/review_fields(.:format)
  // function(options)
  api_review_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_review_rating => /api/reviews/:review_id/rating/:review_criteria_id(.:format)
  // function(review_id, review_criteria_id, options)
  api_review_rating_path: Utils.route([["review_id",true],["review_criteria_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"review_id",false],[2,[7,"/",false],[2,[6,"rating",false],[2,[7,"/",false],[2,[3,"review_criteria_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_review_readers => /api/review_readers(.:format)
  // function(options)
  api_review_readers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_readers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_review_school_year => /api/review_school_years/:id(.:format)
  // function(id, options)
  api_review_school_year_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_school_years",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_review_school_years => /api/review_school_years(.:format)
  // function(options)
  api_review_school_years_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_school_years",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_saved_searches => /api/saved_searches(.:format)
  // function(options)
  api_saved_searches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"saved_searches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_schedules => /api/schedules(.:format)
  // function(options)
  api_schedules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_school_year => /api/school_years/:id(.:format)
  // function(id, options)
  api_school_year_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_school_years => /api/school_years(.:format)
  // function(options)
  api_school_years_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"school_years",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_search_fields => /api/search_fields(.:format)
  // function(options)
  api_search_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"search_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_search_index => /api/search(.:format)
  // function(options)
  api_search_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_session_timeout => /api/session_timeout(.:format)
  // function(options)
  api_session_timeout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"session_timeout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_show_pdf => /api/review_documents/:checklist_answer_id/show_pdf(.:format)
  // function(checklist_answer_id, options)
  api_show_pdf_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_documents",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"show_pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_split_deposit => /api/split_deposits/:id(.:format)
  // function(id, options)
  api_split_deposit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"split_deposits",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_split_deposits => /api/split_deposits(.:format)
  // function(options)
  api_split_deposits_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"split_deposits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_ssatb_components => /api/ssatb_components(.:format)
  // function(options)
  api_ssatb_components_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ssatb_components",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_ssatb_fields => /api/ssatb_fields(.:format)
  // function(options)
  api_ssatb_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ssatb_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_statuses => /api/statuses(.:format)
  // function(options)
  api_statuses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_stripe => /api/stripe(.:format)
  // function(options)
  api_stripe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_stripe_balance_transactions => /api/stripe_balance_transactions(.:format)
  // function(options)
  api_stripe_balance_transactions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_balance_transactions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_stripe_payout => /api/stripe_payouts/:id(.:format)
  // function(id, options)
  api_stripe_payout_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_payouts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_stripe_payouts => /api/stripe_payouts(.:format)
  // function(options)
  api_stripe_payouts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_payouts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_tag => /api/tags/:id(.:format)
  // function(id, options)
  api_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_tags => /api/tags(.:format)
  // function(options)
  api_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_task => /api/tasks/:id(.:format)
  // function(id, options)
  api_task_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_tasks => /api/tasks(.:format)
  // function(options)
  api_tasks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_test_scores => /api/test_scores(.:format)
  // function(options)
  api_test_scores_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"test_scores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_tokens => /api/tokens(.:format)
  // function(options)
  api_tokens_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tokens",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_tuition_and_fees_summary => /api/tuition_and_fees/summary(.:format)
  // function(options)
  api_tuition_and_fees_summary_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tuition_and_fees",false],[2,[7,"/",false],[2,[6,"summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_upload_field => /api/upload_fields/:id(.:format)
  // function(id, options)
  api_upload_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"upload_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_upload_fields => /api/upload_fields(.:format)
  // function(options)
  api_upload_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"upload_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_user => /api/users/:id(.:format)
  // function(id, options)
  api_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_jobs => /api/user_jobs(.:format)
  // function(options)
  api_user_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_user_jobs_bulk_download_all_jobs => /api/user_jobs/bulk_download_all_jobs(.:format)
  // function(options)
  api_user_jobs_bulk_download_all_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[6,"bulk_download_all_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_jobs_bulk_pdf_job => /api/user_jobs/bulk_pdf_jobs/:id(.:format)
  // function(id, options)
  api_user_jobs_bulk_pdf_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[6,"bulk_pdf_jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_jobs_bulk_pdf_jobs => /api/user_jobs/bulk_pdf_jobs(.:format)
  // function(options)
  api_user_jobs_bulk_pdf_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[6,"bulk_pdf_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_jobs_bulk_pdf_jobs_meta => /api/user_jobs/bulk_pdf_jobs/meta(.:format)
  // function(options)
  api_user_jobs_bulk_pdf_jobs_meta_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[6,"bulk_pdf_jobs",false],[2,[7,"/",false],[2,[6,"meta",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_jobs_job => /api/user_jobs/:type/:id(.:format)
  // function(type, id, options)
  api_user_jobs_job_path: Utils.route([["type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_jobs_job_attachment => /api/user_jobs/:type/:id/attachment(.:format)
  // function(type, id, options)
  api_user_jobs_job_attachment_path: Utils.route([["type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[3,"type",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"attachment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_user_jobs_jobs => /api/user_jobs/:type(.:format)
  // function(type, options)
  api_user_jobs_jobs_path: Utils.route([["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_jobs_submit_contracts_jobs => /api/user_jobs/submit_contracts_jobs(.:format)
  // function(options)
  api_user_jobs_submit_contracts_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[6,"submit_contracts_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_users => /api/users(.:format)
  // function(options)
  api_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_veracross_config => /api/veracross_config(.:format)
  // function(options)
  api_veracross_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"veracross_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_web_snippet => /api/web_snippets/:id(.:format)
  // function(id, options)
  api_web_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_web_snippets => /api/web_snippets(.:format)
  // function(options)
  api_web_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_widget_snippet => /api/widget_snippets/:id(.:format)
  // function(id, options)
  api_widget_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"widget_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_widget_snippets => /api/widget_snippets(.:format)
  // function(options)
  api_widget_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"widget_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// applicable_contacts_api_bulk_file_workflow => /api/bulk_file_workflows/:id/applicable_contacts(.:format)
  // function(id, options)
  applicable_contacts_api_bulk_file_workflow_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_workflows",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"applicable_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// applicant => /portal/student/:contact_id(.:format)
  // function(contact_id, options)
  applicant_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"student",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// application => /applications/:id(.:format)
  // function(id, options)
  application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// application_edit => /applications/edit/:id/:page(.:format)
  // function(id, page, options)
  application_edit_path: Utils.route([["id",true],["page",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// application_fee_rule => /setup/application_fee_rules/:id(.:format)
  // function(id, options)
  application_fee_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"application_fee_rules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// application_fee_rules => /setup/application_fee_rules(.:format)
  // function(options)
  application_fee_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"application_fee_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// application_new_with_contact => /applications/new_with_contact/:contact_id/:page(.:format)
  // function(contact_id, page, options)
  application_new_with_contact_path: Utils.route([["contact_id",true],["page",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new_with_contact",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[3,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// application_parent => /applications/show_parent/:id(.:format)
  // function(id, options)
  application_parent_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"show_parent",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// application_review => /applications/:id/review(.:format)
  // function(id, options)
  application_review_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// application_save_exit => /applications/save_exit/:id(.:format)
  // function(id, options)
  application_save_exit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"save_exit",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// application_show_pdf => /applications/:id/pdf(.:format)
  // function(id, options)
  application_show_pdf_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// application_templates => /setup/application_templates(.:format)
  // function(options)
  application_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"application_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// application_thanks => /applications/thank_you/:id(.:format)
  // function(id, options)
  application_thanks_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"thank_you",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// application_timeout_rescue => /applications/timeout_rescue/:id(.:format)
  // function(id, options)
  application_timeout_rescue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"timeout_rescue",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// application_update => /applications/edit/:id/:page(.:format)
  // function(id, page, options)
  application_update_path: Utils.route([["id",true],["page",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// application_update_payment => /applications/:id/update_payment(.:format)
  // function(id, options)
  application_update_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// applications => /applications(.:format)
  // function(options)
  applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// appointment_ad_hoc_templates => /setup/appointment_ad_hoc_templates(.:format)
  // function(options)
  appointment_ad_hoc_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"appointment_ad_hoc_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ar_admin_billing => /admin/billing/ar(.:format)
  // function(options)
  ar_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"ar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// audit_history_charge_rule => /setup/charge_rules/:id/audit_history(.:format)
  // function(id, options)
  audit_history_charge_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"charge_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"audit_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// audit_history_contract_fee => /setup/contract_fees/:id/audit_history(.:format)
  // function(id, options)
  audit_history_contract_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"audit_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// audit_history_export_config => /setup/export_configs/:id/audit_history(.:format)
  // function(id, options)
  audit_history_export_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"export_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"audit_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// audit_history_payment_plan => /setup/payment_plans/:id/audit_history(.:format)
  // function(id, options)
  audit_history_payment_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"payment_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"audit_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// audits_api_billing_account => /api/billing_accounts/:id/audits(.:format)
  // function(id, options)
  audits_api_billing_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"audits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// auth_snippets => /setup/auth_snippets(.:format)
  // function(options)
  auth_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"auth_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// authenticate => /portal/authenticate(/:code)(.:format)
  // function(options)
  authenticate_path: Utils.route([["code",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"authenticate",false],[2,[1,[2,[7,"/",false],[3,"code",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// authenticate_for_contract => /portal/authenticate_for_contract/:code/:contact_id(.:format)
  // function(code, contact_id, options)
  authenticate_for_contract_path: Utils.route([["code",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"authenticate_for_contract",false],[2,[7,"/",false],[2,[3,"code",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// backend_application_delete => /application/delete(.:format)
  // function(options)
  backend_application_delete_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"application",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// balances_api_billing_account => /api/billing_accounts/:id/balances(.:format)
  // function(id, options)
  balances_api_billing_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"balances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// billing_categories => /setup/billing_categories(.:format)
  // function(options)
  billing_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"billing_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// billing_category_report_admin_billing => /admin/billing/billing_category_report(.:format)
  // function(options)
  billing_category_report_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"billing_category_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// billing_config => /setup/billing_config(.:format)
  // function(options)
  billing_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"billing_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// billing_payment_api_billing_account => /api/billing_accounts/:id/billing_payment(.:format)
  // function(id, options)
  billing_payment_api_billing_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"billing_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// billing_snippets => /setup/billing_snippets(.:format)
  // function(options)
  billing_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"billing_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blackbaud_configs => /setup/blackbaud_configs(.:format)
  // function(options)
  blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blank_household => /internal/blank_households/:id(.:format)
  // function(id, options)
  blank_household_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"blank_households",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blank_households => /internal/blank_households(.:format)
  // function(options)
  blank_households_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"blank_households",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bogus_nmi_redirect => /payments/bogus_nmi(.:format)
  // function(options)
  bogus_nmi_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"bogus_nmi",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// boom => /boom(.:format)
  // function(options)
  boom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"boom",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// browser => /browser(.:format)
  // function(options)
  browser_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"browser",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bulk_add_to_workflow_contacts => /contacts/bulk_add_to_workflow(.:format)
  // function(options)
  bulk_add_to_workflow_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"bulk_add_to_workflow",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_api_list_page_configs => /api/list_page_configs/bulk(.:format)
  // function(options)
  bulk_api_list_page_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"list_page_configs",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_change_schedule_admin_available_times => /admin/available_times/bulk_change_schedule(.:format)
  // function(options)
  bulk_change_schedule_admin_available_times_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[6,"bulk_change_schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_change_track_contacts => /contacts/bulk_change_track(.:format)
  // function(options)
  bulk_change_track_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"bulk_change_track",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_change_track_modal_contacts => /contacts/bulk_change_track_modal(.:format)
  // function(options)
  bulk_change_track_modal_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"bulk_change_track_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_create_activities => /activities/bulk_create(.:format)
  // function(options)
  bulk_create_activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[6,"bulk_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_destroy_api_email_histories => /api/email_histories/bulk(.:format)
  // function(options)
  bulk_destroy_api_email_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_histories",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_file_workflows => /setup/bulk_file_workflows(.:format)
  // function(options)
  bulk_file_workflows_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"bulk_file_workflows",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_ignore_duplicate_contacts => /setup/duplicate_contacts/bulk_ignore(.:format)
  // function(options)
  bulk_ignore_duplicate_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_contacts",false],[2,[7,"/",false],[2,[6,"bulk_ignore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_ignore_duplicate_households => /setup/duplicate_households/bulk_ignore(.:format)
  // function(options)
  bulk_ignore_duplicate_households_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_households",false],[2,[7,"/",false],[2,[6,"bulk_ignore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_merge_duplicate_contacts => /setup/duplicate_contacts/bulk_merge(.:format)
  // function(options)
  bulk_merge_duplicate_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_contacts",false],[2,[7,"/",false],[2,[6,"bulk_merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_merge_duplicate_households => /setup/duplicate_households/bulk_merge(.:format)
  // function(options)
  bulk_merge_duplicate_households_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_households",false],[2,[7,"/",false],[2,[6,"bulk_merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_print => /contracts/bulk_print(.:format)
  // function(options)
  bulk_print_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"bulk_print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_remove_from_workflow => /contacts/bulk_remove_from_workflow(.:format)
  // function(options)
  bulk_remove_from_workflow_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"bulk_remove_from_workflow",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_rollover => /contacts/bulk_rollover(.:format)
  // function(options)
  bulk_rollover_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"bulk_rollover",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_update_checklist_answers => /checklist_answers/bulk_update(.:format)
  // function(options)
  bulk_update_checklist_answers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[6,"bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// caches => /internal/caches(.:format)
  // function(options)
  caches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"caches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_all_admin_available_time_appointments => /admin/available_times/:available_time_id/appointments/cancel_all(.:format)
  // function(available_time_id, options)
  cancel_all_admin_available_time_appointments_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[6,"cancel_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_admin_password => /admins/password/change(.:format)
  // function(options)
  change_admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"change",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// change_enrollment_type => /contacts/change_enrollment_type/:id/:year_id/:enrollment_type(.:format)
  // function(id, year_id, enrollment_type, options)
  change_enrollment_type_path: Utils.route([["id",true],["year_id",true],["enrollment_type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"change_enrollment_type",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"year_id",false],[2,[7,"/",false],[2,[3,"enrollment_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// change_grade => /contacts/change_grade/:id/:year_id/:grade_id(.:format)
  // function(id, year_id, grade_id, options)
  change_grade_path: Utils.route([["id",true],["year_id",true],["grade_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"change_grade",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"year_id",false],[2,[7,"/",false],[2,[3,"grade_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// change_log => /setup/change_logs/:id(.:format)
  // function(id, options)
  change_log_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"change_logs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// change_logs => /setup/change_logs(.:format)
  // function(options)
  change_logs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"change_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_role => /contacts/change_role(.:format)
  // function(options)
  change_role_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"change_role",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_status => /contacts/change_status/:id/:year_id/:status(.:format)
  // function(id, year_id, status, options)
  change_status_path: Utils.route([["id",true],["year_id",true],["status",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"change_status",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"year_id",false],[2,[7,"/",false],[2,[3,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// change_track => /contacts/change_track/:id(.:format)
  // function(id, options)
  change_track_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"change_track",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// charge_rule => /setup/charge_rules/:id(.:format)
  // function(id, options)
  charge_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"charge_rules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// charge_rules => /setup/charge_rules(.:format)
  // function(options)
  charge_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"charge_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// check_ratings_api_review_criteria => /api/review_criteria/check_ratings(.:format)
  // function(options)
  check_ratings_api_review_criteria_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_criteria",false],[2,[7,"/",false],[2,[6,"check_ratings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// checklist_answer => /checklist_answers/:id(.:format)
  // function(id, options)
  checklist_answer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// checklist_answer_checklist_request => /checklist_answers/:checklist_answer_id/checklist_request(.:format)
  // function(checklist_answer_id, options)
  checklist_answer_checklist_request_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"checklist_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// checklist_answer_manage_uploaded_files => /checklist_answers/:checklist_answer_id/manage_uploaded_files(.:format)
  // function(checklist_answer_id, options)
  checklist_answer_manage_uploaded_files_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"manage_uploaded_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// checklist_answer_recommendation_request => /checklist_answers/:checklist_answer_id/recommendation_request(.:format)
  // function(checklist_answer_id, options)
  checklist_answer_recommendation_request_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"recommendation_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// checklist_answer_uploaded_files => /checklist_answers/:checklist_answer_id/uploaded_files(.:format)
  // function(checklist_answer_id, options)
  checklist_answer_uploaded_files_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"uploaded_files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// checklist_request_ad_hoc_templates => /setup/checklist_request_ad_hoc_templates(.:format)
  // function(options)
  checklist_request_ad_hoc_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"checklist_request_ad_hoc_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// checklist_request_portal => /checklist_requests/:token(/:action)(.:format)
  // function(token, options)
  checklist_request_portal_path: Utils.route([["token",true],["action",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_requests",false],[2,[7,"/",false],[2,[3,"token",false],[2,[1,[2,[7,"/",false],[3,"action",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// checklist_school_groups => /internal/checklist_school_groups(.:format)
  // function(options)
  checklist_school_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"checklist_school_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// clear_clear_fields => /internal/clear_fields/clear(.:format)
  // function(options)
  clear_clear_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"clear_fields",false],[2,[7,"/",false],[2,[6,"clear",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// clear_fields => /internal/clear_fields(.:format)
  // function(options)
  clear_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"clear_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// clear_form => /applications/clear_form/:id(.:format)
  // function(id, options)
  clear_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"clear_form",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// clone_form_template => /setup/form_templates/:id/clone(.:format)
  // function(id, options)
  clone_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"clone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// columns_and_filters => /setup/review/columns_and_filters(.:format)
  // function(options)
  columns_and_filters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"columns_and_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// commit_api_bulk_file_workflow => /api/bulk_file_workflows/:id/commit(.:format)
  // function(id, options)
  commit_api_bulk_file_workflow_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_workflows",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"commit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// communication => /setup/tags/communication(.:format)
  // function(options)
  communication_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"communication",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// communication_plan => /setup/communication_plans/:id(.:format)
  // function(id, options)
  communication_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"communication_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// communication_plans => /setup/communication_plans(.:format)
  // function(options)
  communication_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"communication_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// communication_preference => /users/communication_preferences/:name(.:format)
  // function(name, options)
  communication_preference_path: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"communication_preferences",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// complete_api_order => /api/orders/:id/complete(.:format)
  // function(id, options)
  complete_api_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// complete_basic_api_checklist_answer => /api/checklist_answers/:id/complete_basic(.:format)
  // function(id, options)
  complete_basic_api_checklist_answer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"complete_basic",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// complete_manual_payment_api_split_deposit => /api/split_deposits/:id/complete_manual_payment(.:format)
  // function(id, options)
  complete_manual_payment_api_split_deposit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"split_deposits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"complete_manual_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// complete_nmi_redirect => /payments/complete_nmi(.:format)
  // function(options)
  complete_nmi_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"complete_nmi",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// completed_redir_order => /orders/:id/completed_redir(.:format)
  // function(id, options)
  completed_redir_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"completed_redir",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// confirm_bulk_cancel_admin_available_time_appointments => /admin/available_times/:available_time_id/appointments/confirm_bulk_cancel(.:format)
  // function(available_time_id, options)
  confirm_bulk_cancel_admin_available_time_appointments_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[6,"confirm_bulk_cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// confirm_cancel_admin_appointment => /admin/appointments/:id/confirm_cancel(.:format)
  // function(id, options)
  confirm_cancel_admin_appointment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// confirm_cancel_portal_appointment => /portal/appointments/:id/confirm_cancel(.:format)
  // function(id, options)
  confirm_cancel_portal_appointment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// confirm_recurrence_admin_available_times => /admin/available_times/confirm_recurrence(.:format)
  // function(options)
  confirm_recurrence_admin_available_times_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[6,"confirm_recurrence",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// confirm_reschedule_portal_appointment => /portal/appointments/:id/confirm_reschedule(.:format)
  // function(id, options)
  confirm_reschedule_portal_appointment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_reschedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact => /contacts/:id(.:format)
  // function(id, options)
  contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_activities => /contacts/:contact_id/activities(.:format)
  // function(contact_id, options)
  contact_activities_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_activity => /contacts/:contact_id/activities/:id(.:format)
  // function(contact_id, id, options)
  contact_activity_path: Utils.route([["contact_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact_admissions => /contacts/:id/admissions(.:format)
  // function(id, options)
  contact_admissions_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"admissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_billing => /contacts/:id/billing(/*subpage)(.:format)
  // function(id, options)
  contact_billing_path: Utils.route([["id",true],["subpage",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[1,[2,[7,"/",false],[5,[3,"subpage",false],false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// contact_checklist_answer => /contacts/:contact_id/checklist_answers/:id(.:format)
  // function(contact_id, id, options)
  contact_checklist_answer_path: Utils.route([["contact_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact_checklist_answers => /contacts/:contact_id/checklist_answers(.:format)
  // function(contact_id, options)
  contact_checklist_answers_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_checklists => /contacts/:id/checklists(.:format)
  // function(id, options)
  contact_checklists_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"checklists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_contract_preview => /contacts/:contact_id/contract_preview(.:format)
  // function(contact_id, options)
  contact_contract_preview_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"contract_preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_contract_preview_new => /contacts/:contact_id/contract_preview/new(.:format)
  // function(contact_id, options)
  contact_contract_preview_new_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"contract_preview",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact_details => /internal/contact_details(.:format)
  // function(options)
  contact_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"contact_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_details_show => /internal/contact_details/:contact_id(.:format)
  // function(contact_id, options)
  contact_details_show_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"contact_details",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_enrollment => /contacts/:id/enrollment(.:format)
  // function(id, options)
  contact_enrollment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"enrollment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_field => /setup/contact_fields/:id(.:format)
  // function(id, options)
  contact_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_fields => /setup/contact_fields(.:format)
  // function(options)
  contact_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_history => /contacts/:id/history(.:format)
  // function(id, options)
  contact_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_info => /setup/contact_info(.:format)
  // function(options)
  contact_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_paper_application => /contacts/:contact_id/paper_application(.:format)
  // function(contact_id, options)
  contact_paper_application_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"paper_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_paper_contract => /contacts/:contact_id/paper_contract(.:format)
  // function(contact_id, options)
  contact_paper_contract_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"paper_contract",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_personal => /contacts/:id/personal(.:format)
  // function(id, options)
  contact_personal_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"personal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_portrait => /contacts/:contact_id/portrait(.:format)
  // function(contact_id, options)
  contact_portrait_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"portrait",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_portrait_info => /contacts/:contact_id/portrait/info(.:format)
  // function(contact_id, options)
  contact_portrait_info_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"portrait",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact_portrait_rotate => /contacts/:contact_id/portrait/rotate(.:format)
  // function(contact_id, options)
  contact_portrait_rotate_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"portrait",false],[2,[7,"/",false],[2,[6,"rotate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact_preview_tuition_and_fees => /contacts/:contact_id/preview_tuition_and_fees(.:format)
  // function(contact_id, options)
  contact_preview_tuition_and_fees_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"preview_tuition_and_fees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_review => /contacts/:id/reviews(.:format)
  // function(id, options)
  contact_review_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reviews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_review_activities => /contacts/:id/reviews/:review_id/activities(.:format)
  // function(id, review_id, options)
  contact_review_activities_path: Utils.route([["id",true],["review_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"review_id",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// contact_review_document => /contacts/:id/reviews/:review_id/documents/:document_key(.:format)
  // function(id, review_id, document_key, options)
  contact_review_document_path: Utils.route([["id",true],["review_id",true],["document_key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"review_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"document_key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// contact_review_summary => /contacts/:id/reviews/:review_id/summary(.:format)
  // function(id, review_id, options)
  contact_review_summary_path: Utils.route([["id",true],["review_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"review_id",false],[2,[7,"/",false],[2,[6,"summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// contact_review_test_scores => /contacts/:id/reviews/:review_id/test_scores(.:format)
  // function(id, review_id, options)
  contact_review_test_scores_path: Utils.route([["id",true],["review_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reviews",false],[2,[7,"/",false],[2,[3,"review_id",false],[2,[7,"/",false],[2,[6,"test_scores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// contact_status => /setup/contact_statuses/:id(.:format)
  // function(id, options)
  contact_status_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_status_select_replacement => /setup/contact_statuses/:contact_status_id/select_replacement(.:format)
  // function(contact_status_id, options)
  contact_status_select_replacement_path: Utils.route([["contact_status_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[2,[7,"/",false],[2,[3,"contact_status_id",false],[2,[7,"/",false],[2,[6,"select_replacement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact_statuses => /setup/contact_statuses(.:format)
  // function(options)
  contact_statuses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact_task => /contacts/:contact_id/tasks/:id(.:format)
  // function(contact_id, id, options)
  contact_task_path: Utils.route([["contact_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contact_tasks => /contacts/:contact_id/tasks(.:format)
  // function(contact_id, options)
  contact_tasks_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contacts => /contacts(.:format)
  // function(options)
  contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// contract_fee => /setup/contract_fees/:id(.:format)
  // function(id, options)
  contract_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contract_fees => /setup/contract_fees(.:format)
  // function(options)
  contract_fees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contract_next => /contracts/next/:id/:contact_id(.:format)
  // function(id, contact_id, options)
  contract_next_path: Utils.route([["id",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"next",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contract_prev => /contracts/prev/:id/:contact_id(.:format)
  // function(id, contact_id, options)
  contract_prev_path: Utils.route([["id",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"prev",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contract_resume => /contracts/resume/:contact_id(.:format)
  // function(contact_id, options)
  contract_resume_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"resume",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contract_thank_you => /contracts/thank_you(.:format)
  // function(options)
  contract_thank_you_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"thank_you",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contracts_update_payment => /contracts/:id/update_payment(.:format)
  // function(id, options)
  contracts_update_payment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// convenience_fee_api_billing_payments => /api/billing_payments/convenience_fee(.:format)
  // function(options)
  convenience_fee_api_billing_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_payments",false],[2,[7,"/",false],[2,[6,"convenience_fee",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// count_before_aasm_changes_contacts => /contacts/count_before_aasm_changes(.:format)
  // function(options)
  count_before_aasm_changes_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"count_before_aasm_changes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// count_recipients => /contacts/count/:contact_filter(.:format)
  // function(contact_filter, options)
  count_recipients_path: Utils.route([["contact_filter",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"count",false],[2,[7,"/",false],[2,[3,"contact_filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// countersign_api_countersign_contracts => /api/countersign_contracts/countersign(.:format)
  // function(options)
  countersign_api_countersign_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"countersign_contracts",false],[2,[7,"/",false],[2,[6,"countersign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// countersign_image => /setup/countersign_image(.:format)
  // function(options)
  countersign_image_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"countersign_image",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cover_sheet => /setup/review/cover_sheet(.:format)
  // function(options)
  cover_sheet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"cover_sheet",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_account_api_stripe_custom => /api/stripe_custom/account(.:format)
  // function(options)
  create_account_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_admin_application => /admin/new_application(.:format)
  // function(options)
  create_admin_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"new_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_external_account_api_stripe_custom => /api/stripe_custom/external_accounts(.:format)
  // function(options)
  create_external_account_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"external_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_family_member_contacts => /contacts/create_family_member(.:format)
  // function(options)
  create_family_member_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"create_family_member",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_link_token_api_plaid => /api/plaid/link_token(.:format)
  // function(options)
  create_link_token_api_plaid_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"plaid",false],[2,[7,"/",false],[2,[6,"link_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_payment_account_api_stripe_custom => /api/stripe_custom/payment_accounts(.:format)
  // function(options)
  create_payment_account_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"payment_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_payment_method_api_stripe_custom => /api/stripe_custom/payment_methods(.:format)
  // function(options)
  create_payment_method_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"payment_methods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_setup_intent_api_stripe_custom => /api/stripe_custom/setup_intent(.:format)
  // function(options)
  create_setup_intent_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"setup_intent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_stripe_token_api_plaid => /api/plaid/stripe_token(.:format)
  // function(options)
  create_stripe_token_api_plaid_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"plaid",false],[2,[7,"/",false],[2,[6,"stripe_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// current_api_billing_configs => /api/billing_configs/current(.:format)
  // function(options)
  current_api_billing_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_configs",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// current_api_customers => /api/customers/current(.:format)
  // function(options)
  current_api_customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// custom_field_form_form_templates => /setup/form_templates/custom_field_form(.:format)
  // function(options)
  custom_field_form_form_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[6,"custom_field_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// custom_stylesheet => /custom_stylesheets/:css(.:format)
  // function(css, options)
  custom_stylesheet_path: Utils.route([["css",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_stylesheets",false],[2,[7,"/",false],[2,[3,"css",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer => /setup/customer(.:format)
  // function(options)
  customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_deposit => /setup/deposit(.:format)
  // function(options)
  customer_deposit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"deposit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_ledger => /setup/customer_ledger(.:format)
  // function(options)
  customer_ledger_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"customer_ledger",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// data_import => /setup/data_imports/:id(.:format)
  // function(id, options)
  data_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"data_imports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// data_import_download => /setup/data_imports/:data_import_id/download(.:format)
  // function(data_import_id, options)
  data_import_download_path: Utils.route([["data_import_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"data_imports",false],[2,[7,"/",false],[2,[3,"data_import_id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// data_import_hide => /setup/data_imports/:data_import_id/hide(.:format)
  // function(data_import_id, options)
  data_import_hide_path: Utils.route([["data_import_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"data_imports",false],[2,[7,"/",false],[2,[3,"data_import_id",false],[2,[7,"/",false],[2,[6,"hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// data_import_unhide => /setup/data_imports/:data_import_id/unhide(.:format)
  // function(data_import_id, options)
  data_import_unhide_path: Utils.route([["data_import_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"data_imports",false],[2,[7,"/",false],[2,[3,"data_import_id",false],[2,[7,"/",false],[2,[6,"unhide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// data_imports => /setup/data_imports(.:format)
  // function(options)
  data_imports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"data_imports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// deactivate_contract_fee => /setup/contract_fees/:id/deactivate(.:format)
  // function(id, options)
  deactivate_contract_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// decision_types => /setup/review/decision_types(.:format)
  // function(options)
  decision_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"decision_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delayed_jobs => /internal/delayed_jobs(.:format)
  // function(options)
  delayed_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"delayed_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delete_contract => /contracts/delete/:contact_id(.:format)
  // function(contact_id, options)
  delete_contract_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delete_mms_phone_number_api_customer => /api/customer/delete_mms_phone_number(.:format)
  // function(options)
  delete_mms_phone_number_api_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"delete_mms_phone_number",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delete_page_form_template => /setup/form_templates/:id/delete_page(.:format)
  // function(id, options)
  delete_page_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delete_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// delete_payment_method_api_stripe_custom => /api/stripe_custom/payment_methods/:payment_method_id(.:format)
  // function(payment_method_id, options)
  delete_payment_method_api_stripe_custom_path: Utils.route([["payment_method_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"payment_methods",false],[2,[7,"/",false],[2,[3,"payment_method_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// demo_for_salespeoples => /demo(.:format)
  // function(options)
  demo_for_salespeoples_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"demo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_admin_session => /admins/sign_out(.:format)
  // function(options)
  destroy_admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_all_blank_households => /internal/blank_households/destroy_all(.:format)
  // function(options)
  destroy_all_blank_households_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"blank_households",false],[2,[7,"/",false],[2,[6,"destroy_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_everybody => /contacts/delete(.:format)
  // function(options)
  destroy_everybody_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_household => /contacts/destroy_household/:id/:household_id(.:format)
  // function(id, household_id, options)
  destroy_household_path: Utils.route([["id",true],["household_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"destroy_household",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"household_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// details_api_tag => /api/tags/:id/details(.:format)
  // function(id, options)
  details_api_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// disputes => /setup/disputes(.:format)
  // function(options)
  disputes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"disputes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// documents => /setup/review/documents(.:format)
  // function(options)
  documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// download_api_billing_import_job => /api/billing_import_jobs/:id/download(.:format)
  // function(id, options)
  download_api_billing_import_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_import_jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_api_bulk_file_page => /api/bulk_file_pages/:id/download(.:format)
  // function(id, options)
  download_api_bulk_file_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_api_bulk_file_workflow => /api/bulk_file_workflows/:id/download(.:format)
  // function(id, options)
  download_api_bulk_file_workflow_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_workflows",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_export => /setup/exports/:id/download(.:format)
  // function(id, options)
  download_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_powerschool_custom_access_plugin => /setup/power_school_custom_access_plugin(.:format)
  // function(options)
  download_powerschool_custom_access_plugin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"power_school_custom_access_plugin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// download_powerschool_plugin => /setup/power_school_plugin(.:format)
  // function(options)
  download_powerschool_plugin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"power_school_plugin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// draft_forms_admin => /forms_inbox/drafts/admin(.:format)
  // function(options)
  draft_forms_admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms_inbox",false],[2,[7,"/",false],[2,[6,"drafts",false],[2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// draft_forms_parent => /forms_inbox/drafts/parent(.:format)
  // function(options)
  draft_forms_parent_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms_inbox",false],[2,[7,"/",false],[2,[6,"drafts",false],[2,[7,"/",false],[2,[6,"parent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dupe_checks => /setup/dupe_checks(.:format)
  // function(options)
  dupe_checks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"dupe_checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// duplicate_contact => /setup/duplicate_contacts/:id(.:format)
  // function(id, options)
  duplicate_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// duplicate_contacts => /setup/duplicate_contacts(.:format)
  // function(options)
  duplicate_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// duplicate_household => /setup/duplicate_households/:id(.:format)
  // function(id, options)
  duplicate_household_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_households",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// duplicate_households => /setup/duplicate_households(.:format)
  // function(options)
  duplicate_households_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_households",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_account => /accounts/:id/edit(.:format)
  // function(id, options)
  edit_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_activity => /activities/:id/edit(.:format)
  // function(id, options)
  edit_activity_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_ad_hoc_template => /setup/ad_hoc_templates/:id/edit(.:format)
  // function(id, options)
  edit_ad_hoc_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"ad_hoc_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin => /setup/admins/:id/edit(.:format)
  // function(id, options)
  edit_admin_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_appointment => /admin/appointments/:id/edit(.:format)
  // function(id, options)
  edit_admin_appointment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_available_time => /admin/available_times/:id/edit(.:format)
  // function(id, options)
  edit_admin_available_time_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_available_time_appointment => /admin/available_times/:available_time_id/appointments/:id/edit(.:format)
  // function(available_time_id, id, options)
  edit_admin_available_time_appointment_path: Utils.route([["available_time_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_calendar => /admin/calendars/:id/edit(.:format)
  // function(id, options)
  edit_admin_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_calendar_available_time => /admin/calendars/:calendar_id/available_times/:id/edit(.:format)
  // function(calendar_id, id, options)
  edit_admin_calendar_available_time_path: Utils.route([["calendar_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_admin_password => /admins/password/edit(.:format)
  // function(options)
  edit_admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_admin_schedule => /admin/schedules/:id/edit(.:format)
  // function(id, options)
  edit_admin_schedule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_update_field => /admin/update_field/edit(.:format)
  // function(options)
  edit_admin_update_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"update_field",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_admissions_checklist_item => /setup/admissions_checklist_items/:id/edit(.:format)
  // function(id, options)
  edit_admissions_checklist_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_checklist_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_activity_item => /api/activity_items/:id/edit(.:format)
  // function(id, options)
  edit_api_activity_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"activity_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_api_credential => /api/api_credentials/:id/edit(.:format)
  // function(id, options)
  edit_api_api_credential_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_credentials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_api_user => /api/api_users/:id/edit(.:format)
  // function(id, options)
  edit_api_api_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_auth_payment_account => /api/auth_payment_accounts/:id/edit(.:format)
  // function(id, options)
  edit_api_auth_payment_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth_payment_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_billing_category_group => /api/billing_category_groups/:id/edit(.:format)
  // function(id, options)
  edit_api_billing_category_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_category_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_bulk_file_page => /api/bulk_file_pages/:id/edit(.:format)
  // function(id, options)
  edit_api_bulk_file_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_bulk_file_workflow => /api/bulk_file_workflows/:id/edit(.:format)
  // function(id, options)
  edit_api_bulk_file_workflow_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_workflows",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_calendar => /api/calendars/:id/edit(.:format)
  // function(id, options)
  edit_api_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_checklist_answer => /api/checklist_answers/:id/edit(.:format)
  // function(id, options)
  edit_api_checklist_answer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_common_checklist_group => /api/common_checklist_groups/:id/edit(.:format)
  // function(id, options)
  edit_api_common_checklist_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"common_checklist_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_communication_plan => /api/communication_plans/:id/edit(.:format)
  // function(id, options)
  edit_api_communication_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"communication_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_contract => /api/contracts/:id/edit(.:format)
  // function(id, options)
  edit_api_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_contract_fee => /api/contract_fees/:id/edit(.:format)
  // function(id, options)
  edit_api_contract_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_customer_oauth_config => /api/customer_oauth_config/edit(.:format)
  // function(options)
  edit_api_customer_oauth_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_oauth_config",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_api_dispute => /api/disputes/:id/edit(.:format)
  // function(id, options)
  edit_api_dispute_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"disputes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_email_snippet => /api/email_snippets/:id/edit(.:format)
  // function(id, options)
  edit_api_email_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_external_contact => /api/external/contacts/:id/edit(.:format)
  // function(id, options)
  edit_api_external_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_external_contact_status => /api/external/contact_statuses/:name/edit(.:format)
  // function(name, options)
  edit_api_external_contact_status_path: Utils.route([["name",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[2,[7,"/",false],[2,[3,"name",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_external_customer => /api/external/customer/edit(.:format)
  // function(options)
  edit_api_external_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_external_field => /api/external/fields/:id/edit(.:format)
  // function(id, options)
  edit_api_external_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_external_grade => /api/external/grades/:id/edit(.:format)
  // function(id, options)
  edit_api_external_grade_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"grades",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_external_ping => /api/external/ping/edit(.:format)
  // function(options)
  edit_api_external_ping_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"ping",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_external_school_year => /api/external/school_years/:id/edit(.:format)
  // function(id, options)
  edit_api_external_school_year_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_api_fee => /api/fees/:id/edit(.:format)
  // function(id, options)
  edit_api_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_fee_group => /api/fee_groups/:id/edit(.:format)
  // function(id, options)
  edit_api_fee_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_fee_rule => /api/fee_rules/:id/edit(.:format)
  // function(id, options)
  edit_api_fee_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_letter_snippet => /api/letter_snippets/:id/edit(.:format)
  // function(id, options)
  edit_api_letter_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"letter_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_mailing_label_snippet => /api/mailing_label_snippets/:id/edit(.:format)
  // function(id, options)
  edit_api_mailing_label_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mailing_label_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_mms_snippet => /api/mms_snippets/:id/edit(.:format)
  // function(id, options)
  edit_api_mms_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_payment_plan => /api/payment_plans/:id/edit(.:format)
  // function(id, options)
  edit_api_payment_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_payment_plan_schedule => /api/payment_plan_schedules/:id/edit(.:format)
  // function(id, options)
  edit_api_payment_plan_schedule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plan_schedules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_payment_profile => /api/payment_profiles/:id/edit(.:format)
  // function(id, options)
  edit_api_payment_profile_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_profiles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_payment_verification => /api/payment_verifications/:id/edit(.:format)
  // function(id, options)
  edit_api_payment_verification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_verifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_power_school_contact => /api/power_school_contacts/:id/edit(.:format)
  // function(id, options)
  edit_api_power_school_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"power_school_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_school_year => /api/school_years/:id/edit(.:format)
  // function(id, options)
  edit_api_school_year_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_stripe => /api/stripe/edit(.:format)
  // function(options)
  edit_api_stripe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_api_task => /api/tasks/:id/edit(.:format)
  // function(id, options)
  edit_api_task_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_upload_field => /api/upload_fields/:id/edit(.:format)
  // function(id, options)
  edit_api_upload_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"upload_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_web_snippet => /api/web_snippets/:id/edit(.:format)
  // function(id, options)
  edit_api_web_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_widget_snippet => /api/widget_snippets/:id/edit(.:format)
  // function(id, options)
  edit_api_widget_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"widget_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_application => /applications/:id/edit(.:format)
  // function(id, options)
  edit_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_application_fee_rule => /setup/application_fee_rules/:id/edit(.:format)
  // function(id, options)
  edit_application_fee_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"application_fee_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_blackbaud_configs => /setup/blackbaud_configs/edit(.:format)
  // function(options)
  edit_blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_blank_household => /internal/blank_households/:id/edit(.:format)
  // function(id, options)
  edit_blank_household_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"blank_households",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_change_log => /setup/change_logs/:id/edit(.:format)
  // function(id, options)
  edit_change_log_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"change_logs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_charge_rule => /setup/charge_rules/:id/edit(.:format)
  // function(id, options)
  edit_charge_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"charge_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_checklist_answer_checklist_request => /checklist_answers/:checklist_answer_id/checklist_request/edit(.:format)
  // function(checklist_answer_id, options)
  edit_checklist_answer_checklist_request_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"checklist_request",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_checklist_answer_recommendation_request => /checklist_answers/:checklist_answer_id/recommendation_request/edit(.:format)
  // function(checklist_answer_id, options)
  edit_checklist_answer_recommendation_request_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"recommendation_request",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_contact => /contacts/:id/edit(.:format)
  // function(id, options)
  edit_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_contact_activity => /contacts/:contact_id/activities/:id/edit(.:format)
  // function(contact_id, id, options)
  edit_contact_activity_path: Utils.route([["contact_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_contact_checklist_answer => /contacts/:contact_id/checklist_answers/:id/edit(.:format)
  // function(contact_id, id, options)
  edit_contact_checklist_answer_path: Utils.route([["contact_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_contact_details => /internal/contact_details/edit(.:format)
  // function(options)
  edit_contact_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"contact_details",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_contact_field => /setup/contact_fields/:id/edit(.:format)
  // function(id, options)
  edit_contact_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_contact_page => /contacts/:id/edit/:page(.:format)
  // function(id, page, options)
  edit_contact_page_path: Utils.route([["id",true],["page",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_contact_portrait => /contacts/:contact_id/portrait/edit(.:format)
  // function(contact_id, options)
  edit_contact_portrait_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"portrait",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_contact_status => /setup/contact_statuses/:id/edit(.:format)
  // function(id, options)
  edit_contact_status_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_contact_task => /contacts/:contact_id/tasks/:id/edit(.:format)
  // function(contact_id, id, options)
  edit_contact_task_path: Utils.route([["contact_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_contract_fee => /setup/contract_fees/:id/edit(.:format)
  // function(id, options)
  edit_contract_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_customer => /setup/customer/edit(.:format)
  // function(options)
  edit_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_data_import => /setup/data_imports/:id/edit(.:format)
  // function(id, options)
  edit_data_import_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"data_imports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_duplicate_contact => /setup/duplicate_contacts/:id/edit(.:format)
  // function(id, options)
  edit_duplicate_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_duplicate_household => /setup/duplicate_households/:id/edit(.:format)
  // function(id, options)
  edit_duplicate_household_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_households",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_enrollment_checklist_item => /setup/enrollment_checklist_items/:id/edit(.:format)
  // function(id, options)
  edit_enrollment_checklist_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_checklist_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_export => /setup/exports/:id/edit(.:format)
  // function(id, options)
  edit_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_export_config => /setup/export_configs/:id/edit(.:format)
  // function(id, options)
  edit_export_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"export_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_external_link => /internal/external_links/:id/edit(.:format)
  // function(id, options)
  edit_external_link_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"external_links",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_failed_email => /setup/failed_emails/:id/edit(.:format)
  // function(id, options)
  edit_failed_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"failed_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_feature => /setup/features/:id/edit(.:format)
  // function(id, options)
  edit_feature_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"features",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_fee => /setup/fees/:id/edit(.:format)
  // function(id, options)
  edit_fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_fee_group => /setup/fee_groups/:id/edit(.:format)
  // function(id, options)
  edit_fee_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_fee_rule => /setup/fee_rules/:id/edit(.:format)
  // function(id, options)
  edit_fee_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_field => /setup/fields/:id/edit(.:format)
  // function(id, options)
  edit_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_file_resource => /setup/file_resources/:id/edit(.:format)
  // function(id, options)
  edit_file_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"file_resources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_form_preview => /setup/form_preview/:template_id/edit(.:format)
  // function(template_id, options)
  edit_form_preview_path: Utils.route([["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_preview",false],[2,[7,"/",false],[2,[3,"template_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_form_template => /setup/form_templates/:id/edit(.:format)
  // function(id, options)
  edit_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_global_config => /internal/global_configs/:id/edit(.:format)
  // function(id, options)
  edit_global_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"global_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_grade => /setup/grades/:id/edit(.:format)
  // function(id, options)
  edit_grade_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"grades",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_id_field => /setup/id_fields/:id/edit(.:format)
  // function(id, options)
  edit_id_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"id_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_import_config => /setup/import_configs/:id/edit(.:format)
  // function(id, options)
  edit_import_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"import_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_inquiry_checklist_item => /setup/inquiry_checklist_items/:id/edit(.:format)
  // function(id, options)
  edit_inquiry_checklist_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"inquiry_checklist_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_line_item => /setup/line_items/:id/edit(.:format)
  // function(id, options)
  edit_line_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"line_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_lottery => /setup/lotteries/:id/edit(.:format)
  // function(id, options)
  edit_lottery_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"lotteries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_match => /setup/matches/:id/edit(.:format)
  // function(id, options)
  edit_match_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"matches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_multiple_external_links => /internal/external_links/edit_multiple(.:format)
  // function(options)
  edit_multiple_external_links_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"external_links",false],[2,[7,"/",false],[2,[6,"edit_multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_nmi_custom_field => /setup/nmi_custom_fields/:id/edit(.:format)
  // function(id, options)
  edit_nmi_custom_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"nmi_custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_non_fee_billing_category => /setup/non_fee_billing_categories/:type(.:format)
  // function(type, options)
  edit_non_fee_billing_category_path: Utils.route([["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"non_fee_billing_categories",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_order => /orders/:id/edit(.:format)
  // function(id, options)
  edit_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_portal_appointment => /portal/appointments/:id/edit(.:format)
  // function(id, options)
  edit_portal_appointment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_portal_available_time => /portal/available_times/:id/edit(.:format)
  // function(id, options)
  edit_portal_available_time_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_portal_calendar => /portal/calendars/:id/edit(.:format)
  // function(id, options)
  edit_portal_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_power_school_debug => /internal/power_school_debug/edit(.:format)
  // function(options)
  edit_power_school_debug_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"power_school_debug",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_review_activity_items_api_review_activity_items => /api/review_activity_items/bulk(.:format)
  // function(options)
  edit_review_activity_items_api_review_activity_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_activity_items",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_review_checklist_items_api_review_checklist_items => /api/review_checklist_items/bulk(.:format)
  // function(options)
  edit_review_checklist_items_api_review_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_checklist_items",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_review_configs_api_review_configs => /api/review_configs/bulk(.:format)
  // function(options)
  edit_review_configs_api_review_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_configs",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_review_criteria_api_review_criteria => /api/review_criteria/bulk(.:format)
  // function(options)
  edit_review_criteria_api_review_criteria_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_criteria",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_review_fields_api_review_fields => /api/review_fields/bulk(.:format)
  // function(options)
  edit_review_fields_api_review_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_fields",false],[2,[7,"/",false],[2,[6,"bulk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_scheduled_export => /setup/scheduled_exports/:id/edit(.:format)
  // function(id, options)
  edit_scheduled_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"scheduled_exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_school_year => /setup/school_years/:id/edit(.:format)
  // function(id, options)
  edit_school_year_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_search => /searches/:id/edit(.:format)
  // function(id, options)
  edit_search_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"searches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_settings => /internal/settings/edit(.:format)
  // function(options)
  edit_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sftp_config => /setup/sftp_configs/:id/edit(.:format)
  // function(id, options)
  edit_sftp_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"sftp_configs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_site => /internal/sites/:id/edit(.:format)
  // function(id, options)
  edit_site_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_snippet => /setup/snippets/:id/edit(.:format)
  // function(id, options)
  edit_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"snippets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_solrs => /internal/solrs/edit(.:format)
  // function(options)
  edit_solrs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"solrs",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_stat => /internal/stats/:id/edit(.:format)
  // function(id, options)
  edit_stat_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"stats",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_task => /tasks/:id/edit(.:format)
  // function(id, options)
  edit_task_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_uploaded_file => /uploaded_files/:id/edit(.:format)
  // function(id, options)
  edit_uploaded_file_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"uploaded_files",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user => /setup/users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_info => /internal/user_info/edit(.:format)
  // function(options)
  edit_user_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"user_info",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// email_histories => /setup/email_histories(.:format)
  // function(options)
  email_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"email_histories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// email_history => /setup/email_histories/:id(.:format)
  // function(id, options)
  email_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"email_histories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// email_setup => /setup/email(.:format)
  // function(options)
  email_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// email_snippets => /setup/email_snippets(.:format)
  // function(options)
  email_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"email_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enrollment => /enrollment/enrollment(.:format)
  // function(options)
  enrollment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enrollment",false],[2,[7,"/",false],[2,[6,"enrollment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enrollment_ad_hoc_templates => /setup/enrollment_ad_hoc_templates(.:format)
  // function(options)
  enrollment_ad_hoc_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_ad_hoc_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enrollment_checklist_item => /setup/enrollment_checklist_items/:id(.:format)
  // function(id, options)
  enrollment_checklist_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_checklist_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// enrollment_checklist_items => /setup/enrollment_checklist_items(.:format)
  // function(options)
  enrollment_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_checklist_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enrollment_info_templates => /setup/enrollment_info_templates(.:format)
  // function(options)
  enrollment_info_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_info_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enrollment_refresh_tracker => /setup/enrollment_refresh_tracker(.:format)
  // function(options)
  enrollment_refresh_tracker_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_refresh_tracker",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enrollment_school_years => /setup/enrollment_school_years(.:format)
  // function(options)
  enrollment_school_years_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_school_years",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enrollment_school_years_make_active => /setup/enrollment_school_years/make_active/:id(.:format)
  // function(id, options)
  enrollment_school_years_make_active_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_school_years",false],[2,[7,"/",false],[2,[6,"make_active",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// enrollment_school_years_make_draft => /setup/enrollment_school_years/make_draft/:id(.:format)
  // function(id, options)
  enrollment_school_years_make_draft_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_school_years",false],[2,[7,"/",false],[2,[6,"make_draft",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// enrollment_school_years_rollback => /setup/enrollment_school_years/rollback/:id(.:format)
  // function(id, options)
  enrollment_school_years_rollback_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_school_years",false],[2,[7,"/",false],[2,[6,"rollback",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// enrollment_tuition_and_fees => /enrollment/tuition_and_fees(.:format)
  // function(options)
  enrollment_tuition_and_fees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enrollment",false],[2,[7,"/",false],[2,[6,"tuition_and_fees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export => /setup/exports/:id(.:format)
  // function(id, options)
  export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_admin_calendar => /admin/calendars/:id/export(.:format)
  // function(id, options)
  export_admin_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_api_billing_aging_report_index => /api/billing_aging_report/export(.:format)
  // function(options)
  export_api_billing_aging_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_aging_report",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_api_billing_ar_report_index => /api/billing_ar_report/export(.:format)
  // function(options)
  export_api_billing_ar_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_ar_report",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_api_billing_category_report_index => /api/billing_category_report/export(.:format)
  // function(options)
  export_api_billing_category_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_category_report",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_api_billing_items => /api/billing_items/export(.:format)
  // function(options)
  export_api_billing_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_items",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_api_billing_ledger_items => /api/billing_ledger_items/export(.:format)
  // function(options)
  export_api_billing_ledger_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_ledger_items",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_api_email_histories => /api/email_histories/export(.:format)
  // function(options)
  export_api_email_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_histories",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_api_reporting_combined_enrollment_total_report => /api/reporting/combined_enrollment_total_report/export(.:format)
  // function(options)
  export_api_reporting_combined_enrollment_total_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"combined_enrollment_total_report",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_api_reporting_combined_funnel_report => /api/reporting/combined_funnel_report/export(.:format)
  // function(options)
  export_api_reporting_combined_funnel_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"combined_funnel_report",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_api_reporting_key_feature_report => /api/reporting/key_feature_report/export(.:format)
  // function(options)
  export_api_reporting_key_feature_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"key_feature_report",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_api_revenue_recognition_report_index => /api/revenue_recognition_report/export(.:format)
  // function(options)
  export_api_revenue_recognition_report_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"revenue_recognition_report",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_api_stripe_balance_transactions => /api/stripe_balance_transactions/export(.:format)
  // function(options)
  export_api_stripe_balance_transactions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_balance_transactions",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_api_stripe_payouts => /api/stripe_payouts/export(.:format)
  // function(options)
  export_api_stripe_payouts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_payouts",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_config => /setup/export_configs/:id(.:format)
  // function(id, options)
  export_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"export_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_configs => /setup/export_configs(.:format)
  // function(options)
  export_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"export_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export_form_template => /setup/form_templates/:id/export(.:format)
  // function(id, options)
  export_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_line_items => /setup/line_items/export(.:format)
  // function(options)
  export_line_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"line_items",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_manage_orders => /setup/manage_orders/export(.:format)
  // function(options)
  export_manage_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"manage_orders",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// exports => /setup/exports(.:format)
  // function(options)
  exports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// external_api_credentials => /setup/external_api_credentials(.:format)
  // function(options)
  external_api_credentials_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"external_api_credentials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// external_link => /internal/external_links/:id(.:format)
  // function(id, options)
  external_link_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"external_links",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// external_links => /internal/external_links(.:format)
  // function(options)
  external_links_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"external_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// facts_redirect => /payments/facts/redirect(/:contact_id)(.:format)
  // function(options)
  facts_redirect_path: Utils.route([["contact_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"facts",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[1,[2,[7,"/",false],[3,"contact_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// facts_rtan => /payments/facts/rtan(.:format)
  // function(options)
  facts_rtan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"facts",false],[2,[7,"/",false],[2,[6,"rtan",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// failed_email => /setup/failed_emails/:id(.:format)
  // function(id, options)
  failed_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"failed_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// failed_email_ignore => /setup/failed_emails/:failed_email_id/ignore(.:format)
  // function(failed_email_id, options)
  failed_email_ignore_path: Utils.route([["failed_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"failed_emails",false],[2,[7,"/",false],[2,[3,"failed_email_id",false],[2,[7,"/",false],[2,[6,"ignore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// failed_email_resend => /setup/failed_emails/:failed_email_id/resend(.:format)
  // function(failed_email_id, options)
  failed_email_resend_path: Utils.route([["failed_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"failed_emails",false],[2,[7,"/",false],[2,[3,"failed_email_id",false],[2,[7,"/",false],[2,[6,"resend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// failed_emails => /setup/failed_emails(.:format)
  // function(options)
  failed_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"failed_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fe_export_api_stripe_payouts => /api/stripe_payouts/fe_export(.:format)
  // function(options)
  fe_export_api_stripe_payouts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_payouts",false],[2,[7,"/",false],[2,[6,"fe_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// feature => /setup/features/:id(.:format)
  // function(id, options)
  feature_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"features",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// features => /setup/features(.:format)
  // function(options)
  features_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"features",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fee => /setup/fees/:id(.:format)
  // function(id, options)
  fee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// fee_group => /setup/fee_groups/:id(.:format)
  // function(id, options)
  fee_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// fee_groups => /setup/fee_groups(.:format)
  // function(options)
  fee_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fee_management_index => /setup/fee_management(.:format)
  // function(options)
  fee_management_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_management",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fee_rule => /setup/fee_rules/:id(.:format)
  // function(id, options)
  fee_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// fee_rules => /setup/fee_rules(.:format)
  // function(options)
  fee_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fees => /setup/fees(.:format)
  // function(options)
  fees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fetch_field_api_form_template => /api/form_templates/:id/fetch_field(.:format)
  // function(id, options)
  fetch_field_api_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"fetch_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fetch_file_api_disputes => /api/disputes/fetch_file(.:format)
  // function(options)
  fetch_file_api_disputes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"disputes",false],[2,[7,"/",false],[2,[6,"fetch_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// field => /setup/fields/:id(.:format)
  // function(id, options)
  field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// field_customization => /setup/fields_customization(.:format)
  // function(options)
  field_customization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fields_customization",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// field_examine_fields => /setup/fields/field_examine(.:format)
  // function(options)
  field_examine_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[6,"field_examine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// field_filter_options_form_templates => /setup/form_templates/show_filter_options/:field/:filter_field(.:format)
  // function(field, filter_field, options)
  field_filter_options_form_templates_path: Utils.route([["field",true],["filter_field",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[6,"show_filter_options",false],[2,[7,"/",false],[2,[3,"field",false],[2,[7,"/",false],[2,[3,"filter_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// fields => /setup/fields(.:format)
  // function(options)
  fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// file_resource => /setup/file_resources/:id(.:format)
  // function(id, options)
  file_resource_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"file_resources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// file_resource_permalink => /file_resource/:id(.:format)
  // function(id, options)
  file_resource_permalink_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"file_resource",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// file_resources => /setup/file_resources(.:format)
  // function(options)
  file_resources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"file_resources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// force_submit_application => /applications/:id/force_submit_application(.:format)
  // function(id, options)
  force_submit_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"force_submit_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_create => /forms/:ad_hoc_type/:form_name/:contact_id(.:format)
  // function(ad_hoc_type, form_name, contact_id, options)
  form_create_path: Utils.route([["ad_hoc_type",true],["form_name",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"ad_hoc_type",false],[2,[7,"/",false],[2,[3,"form_name",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// form_edit => /forms/edit/:id/:page(.:format)
  // function(id, page, options)
  form_edit_path: Utils.route([["id",true],["page",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// form_pay_success => /forms/:ad_hoc_type/:form_name/:contact_id/pay_success(.:format)
  // function(ad_hoc_type, form_name, contact_id, options)
  form_pay_success_path: Utils.route([["ad_hoc_type",true],["form_name",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"ad_hoc_type",false],[2,[7,"/",false],[2,[3,"form_name",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"pay_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// form_pdf => /forms/:id/pdf(.:format)
  // function(id, options)
  form_pdf_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_preview => /setup/form_preview/:template_id(.:format)
  // function(template_id, options)
  form_preview_path: Utils.route([["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_preview",false],[2,[7,"/",false],[2,[3,"template_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_show => /forms/show/:id/:page(.:format)
  // function(id, page, options)
  form_show_path: Utils.route([["id",true],["page",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[6,"show",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// form_template => /setup/form_templates/:id(.:format)
  // function(id, options)
  form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// form_templates => /setup/form_templates(.:format)
  // function(options)
  form_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// form_update => /forms/:ad_hoc_type/:form_name/:contact_id(.:format)
  // function(ad_hoc_type, form_name, contact_id, options)
  form_update_path: Utils.route([["ad_hoc_type",true],["form_name",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"forms",false],[2,[7,"/",false],[2,[3,"ad_hoc_type",false],[2,[7,"/",false],[2,[3,"form_name",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// forms_inbox_delete => /forms_inbox/delete(.:format)
  // function(options)
  forms_inbox_delete_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms_inbox",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// forms_inbox_export => /forms_inbox/export(.:format)
  // function(options)
  forms_inbox_export_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms_inbox",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// forms_with_gender => /setup/forms_with_gender(.:format)
  // function(options)
  forms_with_gender_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"forms_with_gender",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// generate_contracts => /contacts/generate_contracts(.:format)
  // function(options)
  generate_contracts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"generate_contracts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// generate_id_field => /setup/id_fields/:id/generate(.:format)
  // function(id, options)
  generate_id_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"id_fields",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// generate_jwt_api_api_credential => /api/api_credentials/:id/generate_jwt(.:format)
  // function(id, options)
  generate_jwt_api_api_credential_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_credentials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"generate_jwt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// generate_powerschool_tables_and_fields_list => /setup/power_school_tables_and_fields_list(.:format)
  // function(options)
  generate_powerschool_tables_and_fields_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"power_school_tables_and_fields_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// generate_print_labels_contacts => /contacts/generate_print_labels(.:format)
  // function(options)
  generate_print_labels_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"generate_print_labels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// generate_report_snippets => /setup/snippets/generate_report(.:format)
  // function(options)
  generate_report_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"snippets",false],[2,[7,"/",false],[2,[6,"generate_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// get_customer_account_api_stripe_custom => /api/stripe_custom/account(.:format)
  // function(options)
  get_customer_account_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// get_external_account_api_stripe_custom => /api/stripe_custom/external_accounts(.:format)
  // function(options)
  get_external_account_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"external_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// get_form_matches_form_matching => /form_matching/:id/get_form_matches(.:format)
  // function(id, options)
  get_form_matches_form_matching_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"form_matching",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"get_form_matches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// get_matches_contacts => /contacts/get_matches(.:format)
  // function(options)
  get_matches_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"get_matches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// global_config => /internal/global_configs/:id(.:format)
  // function(id, options)
  global_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"global_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// global_config_download => /internal/global_configs/:global_config_id/download(.:format)
  // function(global_config_id, options)
  global_config_download_path: Utils.route([["global_config_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"global_configs",false],[2,[7,"/",false],[2,[3,"global_config_id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// global_configs => /internal/global_configs(.:format)
  // function(options)
  global_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"global_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// google_oauth_redirect => /oauth/google(.:format)
  // function(options)
  google_oauth_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"google",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// grade => /setup/grades/:id(.:format)
  // function(id, options)
  grade_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"grades",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// grade_in_use => /setup/grades/:grade_id/in_use(.:format)
  // function(grade_id, options)
  grade_in_use_path: Utils.route([["grade_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"grades",false],[2,[7,"/",false],[2,[3,"grade_id",false],[2,[7,"/",false],[2,[6,"in_use",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// grades => /setup/grades(.:format)
  // function(options)
  grades_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"grades",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hide_export => /setup/exports/:id/hide(.:format)
  // function(id, options)
  hide_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// hooks_api_stripe => /api/stripe/hooks(.:format)
  // function(options)
  hooks_api_stripe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"hooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// household_stats => /internal/household_stats(.:format)
  // function(options)
  household_stats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"household_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// households_account_contact => /contacts/:id/households/account(.:format)
  // function(id, options)
  households_account_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"households",false],[2,[7,"/",false],[2,[6,"account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// households_contact => /contacts/:id/households(.:format)
  // function(id, options)
  households_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"households",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// households_overview_contact => /contacts/:id/households/overview(.:format)
  // function(id, options)
  households_overview_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"households",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ical_admin_calendar => /admin/calendars/:id/ical(.:format)
  // function(id, options)
  ical_admin_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ical",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// id_field => /setup/id_fields/:id(.:format)
  // function(id, options)
  id_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"id_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// id_fields => /setup/id_fields(.:format)
  // function(options)
  id_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"id_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ignore_all_failed_emails => /setup/failed_emails/ignore_all(.:format)
  // function(options)
  ignore_all_failed_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"failed_emails",false],[2,[7,"/",false],[2,[6,"ignore_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// ignore_duplicate_contact => /setup/duplicate_contacts/:id/ignore(.:format)
  // function(id, options)
  ignore_duplicate_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ignore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ignore_duplicate_household => /setup/duplicate_households/:id/ignore(.:format)
  // function(id, options)
  ignore_duplicate_household_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_households",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ignore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// import_admin_billing => /admin/billing/import(.:format)
  // function(options)
  import_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// import_config => /setup/import_configs/:id(.:format)
  // function(id, options)
  import_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"import_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// import_configs => /setup/import_configs(.:format)
  // function(options)
  import_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"import_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// import_details => /setup/imports/details/:id(.:format)
  // function(id, options)
  import_details_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"imports",false],[2,[7,"/",false],[2,[6,"details",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// import_download => /setup/imports/download/:id(.:format)
  // function(id, options)
  import_download_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"imports",false],[2,[7,"/",false],[2,[6,"download",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// import_form_matching_index => /form_matching/import(.:format)
  // function(options)
  import_form_matching_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"form_matching",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// import_history => /setup/import_history(.:format)
  // function(options)
  import_history_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"import_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// imported => /forms_inbox/imported(.:format)
  // function(options)
  imported_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms_inbox",false],[2,[7,"/",false],[2,[6,"imported",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inbox => /forms_inbox(.:format)
  // function(options)
  inbox_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forms_inbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// index_payment_methods_api_stripe_custom => /api/stripe_custom/payment_methods(.:format)
  // function(options)
  index_payment_methods_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"payment_methods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// info_show => /contracts/info_show/:id(.:format)
  // function(id, options)
  info_show_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"info_show",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// info_update => /contracts/info_update/:id/:contact_id(.:format)
  // function(id, contact_id, options)
  info_update_path: Utils.route([["id",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"info_update",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// inquiry_checklist_item => /setup/inquiry_checklist_items/:id(.:format)
  // function(id, options)
  inquiry_checklist_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"inquiry_checklist_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// inquiry_checklist_items => /setup/inquiry_checklist_items(.:format)
  // function(options)
  inquiry_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"inquiry_checklist_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inquiry_templates => /setup/inquiry_templates(.:format)
  // function(options)
  inquiry_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"inquiry_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inspect_user => /internal/sherlock/:id(.:format)
  // function(id, options)
  inspect_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"sherlock",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invite_signer => /contracts/invite/:id/:signer_id(.:format)
  // function(id, signer_id, options)
  invite_signer_path: Utils.route([["id",true],["signer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"invite",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"signer_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// isearches => /internal/isearches(.:format)
  // function(options)
  isearches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"isearches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ledger_admin_billing => /admin/billing/ledger(.:format)
  // function(options)
  ledger_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"ledger",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// letter_snippets => /setup/letter_snippets(.:format)
  // function(options)
  letter_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"letter_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// line_item => /setup/line_items/:id(.:format)
  // function(id, options)
  line_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"line_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// line_items => /setup/line_items(.:format)
  // function(options)
  line_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"line_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// line_items_admin_billing => /admin/billing/line_items(.:format)
  // function(options)
  line_items_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"line_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// line_items_refreshes => /internal/line_items_refreshes(.:format)
  // function(options)
  line_items_refreshes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"line_items_refreshes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// link_forms => /internal/link_forms(.:format)
  // function(options)
  link_forms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"link_forms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// link_link_forms => /internal/link_forms/link(.:format)
  // function(options)
  link_link_forms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"link_forms",false],[2,[7,"/",false],[2,[6,"link",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// link_user_to_contact => /contacts/link_user_to_contact(.:format)
  // function(options)
  link_user_to_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"link_user_to_contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// list_page_config => /setup/list_page_config(.:format)
  // function(options)
  list_page_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"list_page_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lotteries => /setup/lotteries(.:format)
  // function(options)
  lotteries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"lotteries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lottery => /setup/lotteries/:id(.:format)
  // function(id, options)
  lottery_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"lotteries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// magical_fields => /internal/magical_fields(.:format)
  // function(options)
  magical_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"magical_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mailing_label_snippets => /setup/mailing_label_snippets(.:format)
  // function(options)
  mailing_label_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"mailing_label_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_facts_config => /setup/facts_config(.:format)
  // function(options)
  manage_facts_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"facts_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_intercom_config => /setup/intercom_config(.:format)
  // function(options)
  manage_intercom_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"intercom_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_lotteries => /setup/manage_lotteries(.:format)
  // function(options)
  manage_lotteries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"manage_lotteries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_non_fee_billing_categories => /setup/non_fee_billing_categories(.:format)
  // function(options)
  manage_non_fee_billing_categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"non_fee_billing_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_order => /setup/manage_orders/:id(.:format)
  // function(id, options)
  manage_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"manage_orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// manage_orders => /setup/manage_orders(.:format)
  // function(options)
  manage_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"manage_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_power_school_config => /setup/power_school_config(.:format)
  // function(options)
  manage_power_school_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"power_school_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_setup_home => /internal/manage_setup_home(.:format)
  // function(options)
  manage_setup_home_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"manage_setup_home",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// manage_ssatb_config => /setup/ssatb_config(.:format)
  // function(options)
  manage_ssatb_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"ssatb_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mark_exported_export => /setup/exports/:id/mark_exported(.:format)
  // function(id, options)
  mark_exported_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_exported",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// match => /setup/matches/:id(.:format)
  // function(id, options)
  match_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"matches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// matches => /setup/matches(.:format)
  // function(options)
  matches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"matches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// merge_dupe_checks => /setup/dupe_checks/merge(.:format)
  // function(options)
  merge_dupe_checks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"dupe_checks",false],[2,[7,"/",false],[2,[6,"merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// merge_duplicate_contact => /setup/duplicate_contacts/:id/merge(.:format)
  // function(id, options)
  merge_duplicate_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// merge_duplicate_household => /setup/duplicate_households/:id/merge(.:format)
  // function(id, options)
  merge_duplicate_household_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_households",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// meta_api_review_contacts => /api/review_contacts/meta(.:format)
  // function(options)
  meta_api_review_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"review_contacts",false],[2,[7,"/",false],[2,[6,"meta",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// metrics => /internal/metrics(.:format)
  // function(options)
  metrics_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"metrics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// microsoft_oauth_redirect => /oauth/microsoft(.:format)
  // function(options)
  microsoft_oauth_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"microsoft",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// migrate_core => /internal/migrate_core(.:format)
  // function(options)
  migrate_core_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"migrate_core",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mms_histories => /setup/mms_histories(.:format)
  // function(options)
  mms_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"mms_histories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mms_setup => /setup/mms_setup(.:format)
  // function(options)
  mms_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"mms_setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mms_snippets => /setup/mms_snippets(.:format)
  // function(options)
  mms_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"mms_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mongo_examine => /internal/mongo_examine(.:format)
  // function(options)
  mongo_examine_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"mongo_examine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// move_api_billing_category_group => /api/billing_category_groups/:id/move(.:format)
  // function(id, options)
  move_api_billing_category_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_category_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// move_appointments_admin_schedule => /admin/schedules/:id/move_appointments(.:format)
  // function(id, options)
  move_appointments_admin_schedule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_appointments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// needs_signature => /contracts/needs_signature/:id(.:format)
  // function(id, options)
  needs_signature_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"needs_signature",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_account => /accounts/new(.:format)
  // function(options)
  new_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_activity => /activities/new(.:format)
  // function(options)
  new_activity_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_ad_hoc_template => /setup/ad_hoc_templates/new(.:format)
  // function(options)
  new_ad_hoc_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"ad_hoc_templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin => /setup/admins/new(.:format)
  // function(options)
  new_admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_application => /admin/new_application(.:format)
  // function(options)
  new_admin_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"new_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_available_time => /admin/available_times/new(.:format)
  // function(options)
  new_admin_available_time_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_available_time_appointment => /admin/available_times/:available_time_id/appointments/new(.:format)
  // function(available_time_id, options)
  new_admin_available_time_appointment_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_calendar => /admin/calendars/new(.:format)
  // function(options)
  new_admin_calendar_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_calendar_available_time => /admin/calendars/:calendar_id/available_times/new(.:format)
  // function(calendar_id, options)
  new_admin_calendar_available_time_path: Utils.route([["calendar_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_calendar_schedule => /admin/calendars/:calendar_id/schedules/new(.:format)
  // function(calendar_id, options)
  new_admin_calendar_schedule_path: Utils.route([["calendar_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"calendar_id",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_admin_inquiry => /admin/new_inquiry(.:format)
  // function(options)
  new_admin_inquiry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"new_inquiry",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_password => /admins/password/new(.:format)
  // function(options)
  new_admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_session => /admins/sign_in(.:format)
  // function(options)
  new_admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_unlock => /admins/unlock/new(.:format)
  // function(options)
  new_admin_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admissions_checklist_item => /setup/admissions_checklist_items/new(.:format)
  // function(options)
  new_admissions_checklist_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_checklist_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_activity_item => /api/activity_items/new(.:format)
  // function(options)
  new_api_activity_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"activity_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_api_credential => /api/api_credentials/new(.:format)
  // function(options)
  new_api_api_credential_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_credentials",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_api_user => /api/api_users/new(.:format)
  // function(options)
  new_api_api_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_auth_payment_account => /api/auth_payment_accounts/new(.:format)
  // function(options)
  new_api_auth_payment_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"auth_payment_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_billing_category_group => /api/billing_category_groups/new(.:format)
  // function(options)
  new_api_billing_category_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_category_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_bulk_file_page => /api/bulk_file_pages/new(.:format)
  // function(options)
  new_api_bulk_file_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_bulk_file_workflow => /api/bulk_file_workflows/new(.:format)
  // function(options)
  new_api_bulk_file_workflow_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_workflows",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_calendar => /api/calendars/new(.:format)
  // function(options)
  new_api_calendar_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_checklist_answer => /api/checklist_answers/new(.:format)
  // function(options)
  new_api_checklist_answer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_common_checklist_group => /api/common_checklist_groups/new(.:format)
  // function(options)
  new_api_common_checklist_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"common_checklist_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_communication_plan => /api/communication_plans/new(.:format)
  // function(options)
  new_api_communication_plan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"communication_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_contract => /api/contracts/new(.:format)
  // function(options)
  new_api_contract_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_contract_fee => /api/contract_fees/new(.:format)
  // function(options)
  new_api_contract_fee_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_customer_oauth_config => /api/customer_oauth_config/new(.:format)
  // function(options)
  new_api_customer_oauth_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_oauth_config",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_dispute => /api/disputes/new(.:format)
  // function(options)
  new_api_dispute_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"disputes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_email_snippet => /api/email_snippets/new(.:format)
  // function(options)
  new_api_email_snippet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_snippets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_external_contact => /api/external/contacts/new(.:format)
  // function(options)
  new_api_external_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_external_contact_status => /api/external/contact_statuses/new(.:format)
  // function(options)
  new_api_external_contact_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_external_customer => /api/external/customer/new(.:format)
  // function(options)
  new_api_external_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_external_field => /api/external/fields/new(.:format)
  // function(options)
  new_api_external_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_external_grade => /api/external/grades/new(.:format)
  // function(options)
  new_api_external_grade_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"grades",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_external_ping => /api/external/ping/new(.:format)
  // function(options)
  new_api_external_ping_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"ping",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_external_school_year => /api/external/school_years/new(.:format)
  // function(options)
  new_api_external_school_year_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_fee => /api/fees/new(.:format)
  // function(options)
  new_api_fee_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_fee_group => /api/fee_groups/new(.:format)
  // function(options)
  new_api_fee_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_fee_rule => /api/fee_rules/new(.:format)
  // function(options)
  new_api_fee_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_letter_snippet => /api/letter_snippets/new(.:format)
  // function(options)
  new_api_letter_snippet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"letter_snippets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_mailing_label_snippet => /api/mailing_label_snippets/new(.:format)
  // function(options)
  new_api_mailing_label_snippet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mailing_label_snippets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_mms_snippet => /api/mms_snippets/new(.:format)
  // function(options)
  new_api_mms_snippet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms_snippets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_payment_plan => /api/payment_plans/new(.:format)
  // function(options)
  new_api_payment_plan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_payment_plan_schedule => /api/payment_plan_schedules/new(.:format)
  // function(options)
  new_api_payment_plan_schedule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plan_schedules",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_payment_profile => /api/payment_profiles/new(.:format)
  // function(options)
  new_api_payment_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_profiles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_payment_verification => /api/payment_verifications/new(.:format)
  // function(options)
  new_api_payment_verification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_verifications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_power_school_contact => /api/power_school_contacts/new(.:format)
  // function(options)
  new_api_power_school_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"power_school_contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_refund => /api/refunds/new(.:format)
  // function(options)
  new_api_refund_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"refunds",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_school_year => /api/school_years/new(.:format)
  // function(options)
  new_api_school_year_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_stripe => /api/stripe/new(.:format)
  // function(options)
  new_api_stripe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_task => /api/tasks/new(.:format)
  // function(options)
  new_api_task_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_upload_field => /api/upload_fields/new(.:format)
  // function(options)
  new_api_upload_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"upload_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_web_snippet => /api/web_snippets/new(.:format)
  // function(options)
  new_api_web_snippet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web_snippets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_api_widget_snippet => /api/widget_snippets/new(.:format)
  // function(options)
  new_api_widget_snippet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"widget_snippets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_application => /applications/new(.:format)
  // function(options)
  new_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_application_fee_rule => /setup/application_fee_rules/new(.:format)
  // function(options)
  new_application_fee_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"application_fee_rules",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_blackbaud_configs => /setup/blackbaud_configs/new(.:format)
  // function(options)
  new_blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_blank_household => /internal/blank_households/new(.:format)
  // function(options)
  new_blank_household_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"blank_households",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_change_log => /setup/change_logs/new(.:format)
  // function(options)
  new_change_log_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"change_logs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_charge_rule => /setup/charge_rules/new(.:format)
  // function(options)
  new_charge_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"charge_rules",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_checklist_answer_checklist_request => /checklist_answers/:checklist_answer_id/checklist_request/new(.:format)
  // function(checklist_answer_id, options)
  new_checklist_answer_checklist_request_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"checklist_request",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_checklist_answer_recommendation_request => /checklist_answers/:checklist_answer_id/recommendation_request/new(.:format)
  // function(checklist_answer_id, options)
  new_checklist_answer_recommendation_request_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"recommendation_request",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_checklist_answer_uploaded_file => /checklist_answers/:checklist_answer_id/uploaded_files/new(.:format)
  // function(checklist_answer_id, options)
  new_checklist_answer_uploaded_file_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"uploaded_files",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_contact => /contacts/new(.:format)
  // function(options)
  new_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_contact_activity => /contacts/:contact_id/activities/new(.:format)
  // function(contact_id, options)
  new_contact_activity_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"activities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_contact_checklist_answer => /contacts/:contact_id/checklist_answers/new(.:format)
  // function(contact_id, options)
  new_contact_checklist_answer_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_contact_details => /internal/contact_details/new(.:format)
  // function(options)
  new_contact_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"contact_details",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_contact_field => /setup/contact_fields/new(.:format)
  // function(options)
  new_contact_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_contact_paper_contract => /contacts/:contact_id/paper_contract/new(.:format)
  // function(contact_id, options)
  new_contact_paper_contract_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"paper_contract",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_contact_portrait => /contacts/:contact_id/portrait/new(.:format)
  // function(contact_id, options)
  new_contact_portrait_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"portrait",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_contact_status => /setup/contact_statuses/new(.:format)
  // function(options)
  new_contact_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_statuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_contact_task => /contacts/:contact_id/tasks/new(.:format)
  // function(contact_id, options)
  new_contact_task_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_contract => /contracts/:contact_id(.:format)
  // function(contact_id, options)
  new_contract_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_contract_fee => /setup/contract_fees/new(.:format)
  // function(options)
  new_contract_fee_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contract_fees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_contract_portal => /portal/view_contract/:contact_id(.:format)
  // function(contact_id, options)
  new_contract_portal_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"view_contract",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_customer => /setup/customer/new(.:format)
  // function(options)
  new_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"customer",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_data_import => /setup/data_imports/new(.:format)
  // function(options)
  new_data_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"data_imports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_duplicate_contact => /setup/duplicate_contacts/new(.:format)
  // function(options)
  new_duplicate_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_duplicate_household => /setup/duplicate_households/new(.:format)
  // function(options)
  new_duplicate_household_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"duplicate_households",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_enrollment_checklist_item => /setup/enrollment_checklist_items/new(.:format)
  // function(options)
  new_enrollment_checklist_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_checklist_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_export => /setup/exports/new(.:format)
  // function(options)
  new_export_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_export_config => /setup/export_configs/new(.:format)
  // function(options)
  new_export_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"export_configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_external_link => /internal/external_links/new(.:format)
  // function(options)
  new_external_link_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"external_links",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_failed_email => /setup/failed_emails/new(.:format)
  // function(options)
  new_failed_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"failed_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_feature => /setup/features/new(.:format)
  // function(options)
  new_feature_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"features",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_fee => /setup/fees/new(.:format)
  // function(options)
  new_fee_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_fee_group => /setup/fee_groups/new(.:format)
  // function(options)
  new_fee_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_fee_rule => /setup/fee_rules/new(.:format)
  // function(options)
  new_fee_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_field => /setup/fields/new(.:format)
  // function(options)
  new_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_file_resource => /setup/file_resources/new(.:format)
  // function(options)
  new_file_resource_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"file_resources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_form_preview => /setup/form_preview/new(.:format)
  // function(options)
  new_form_preview_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_preview",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_form_template => /setup/form_templates/new(.:format)
  // function(options)
  new_form_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_global_config => /internal/global_configs/new(.:format)
  // function(options)
  new_global_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"global_configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_grade => /setup/grades/new(.:format)
  // function(options)
  new_grade_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"grades",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_house => /contacts/create_household/:contact_id(.:format)
  // function(contact_id, options)
  new_house_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"create_household",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_id_field => /setup/id_fields/new(.:format)
  // function(options)
  new_id_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"id_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_import_config => /setup/import_configs/new(.:format)
  // function(options)
  new_import_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"import_configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_inquiry_checklist_item => /setup/inquiry_checklist_items/new(.:format)
  // function(options)
  new_inquiry_checklist_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"inquiry_checklist_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_line_item => /setup/line_items/new(.:format)
  // function(options)
  new_line_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"line_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_lottery => /setup/lotteries/new(.:format)
  // function(options)
  new_lottery_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"lotteries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_match => /setup/matches/new(.:format)
  // function(options)
  new_match_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"matches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_nmi_custom_field => /setup/nmi_custom_fields/new(.:format)
  // function(options)
  new_nmi_custom_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"nmi_custom_fields",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_order => /orders/new(.:format)
  // function(options)
  new_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_portal_available_time => /portal/available_times/new(.:format)
  // function(options)
  new_portal_available_time_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_portal_available_time_appointment => /portal/available_times/:available_time_id/appointments/new(.:format)
  // function(available_time_id, options)
  new_portal_available_time_appointment_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_portal_calendar => /portal/calendars/new(.:format)
  // function(options)
  new_portal_calendar_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_portal_inquiry => /portal/new_inquiry(.:format)
  // function(options)
  new_portal_inquiry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"new_inquiry",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_power_school_debug => /internal/power_school_debug/new(.:format)
  // function(options)
  new_power_school_debug_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"power_school_debug",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_scheduled_export => /setup/scheduled_exports/new(.:format)
  // function(options)
  new_scheduled_export_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"scheduled_exports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_school_year => /setup/school_years/new(.:format)
  // function(options)
  new_school_year_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_search => /searches/new(.:format)
  // function(options)
  new_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"searches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_settings => /internal/settings/new(.:format)
  // function(options)
  new_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sftp_config => /setup/sftp_configs/new(.:format)
  // function(options)
  new_sftp_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"sftp_configs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_site => /internal/sites/new(.:format)
  // function(options)
  new_site_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_snippet => /setup/snippets/new(.:format)
  // function(options)
  new_snippet_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"snippets",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_solrs => /internal/solrs/new(.:format)
  // function(options)
  new_solrs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"solrs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_stat => /internal/stats/new(.:format)
  // function(options)
  new_stat_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"stats",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_task => /tasks/new(.:format)
  // function(options)
  new_task_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user => /setup/users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_info => /internal/user_info/new(.:format)
  // function(options)
  new_user_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"user_info",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_unlock => /users/unlock/new(.:format)
  // function(options)
  new_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// nmi_custom_field => /setup/nmi_custom_fields/:id(.:format)
  // function(id, options)
  nmi_custom_field_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"nmi_custom_fields",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// nmi_custom_fields => /setup/nmi_custom_fields(.:format)
  // function(options)
  nmi_custom_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"nmi_custom_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth => /setup/oauth(.:format)
  // function(options)
  oauth_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"oauth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// onboard_api_stripe_custom => /api/stripe_custom/onboard(.:format)
  // function(options)
  onboard_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"onboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// open_auth_oauth2_omniauth_authorize => /open_auth/auth/oauth2(.:format)
  // function(options)
  open_auth_oauth2_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"open_auth",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"oauth2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// open_auth_oauth2_omniauth_callback => /open_auth/auth/oauth2/callback(.:format)
  // function(options)
  open_auth_oauth2_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"open_auth",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// order => /orders/:id(.:format)
  // function(id, options)
  order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// orders => /orders(.:format)
  // function(options)
  orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// parent_application_delete => /application/delete/:id(.:format)
  // function(id, options)
  parent_application_delete_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"application",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// parent_dashboard => /portal(.:format)
  // function(options)
  parent_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// parent_portal_content => /setup/parent_portal_content(.:format)
  // function(options)
  parent_portal_content_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"parent_portal_content",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// partial_admin_update_field => /admin/update_field/partial(.:format)
  // function(options)
  partial_admin_update_field_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"update_field",false],[2,[7,"/",false],[2,[6,"partial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pay_contract_deposit => /contracts/pay_deposit/:id(.:format)
  // function(id, options)
  pay_contract_deposit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"pay_deposit",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pay_for_application => /applications/:id/pay(.:format)
  // function(id, options)
  pay_for_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pay",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pay_split_deposit => /contracts/pay_split_deposit/:id/:split_deposit_id(.:format)
  // function(id, split_deposit_id, options)
  pay_split_deposit_path: Utils.route([["id",true],["split_deposit_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"pay_split_deposit",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"split_deposit_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pay_success_application => /applications/:id/pay_success(.:format)
  // function(id, options)
  pay_success_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pay_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payment_gateways => /setup/payment_gateways(.:format)
  // function(options)
  payment_gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"payment_gateways",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payment_plan_preview_api_contract => /api/contracts/:id/payment_plan_preview(.:format)
  // function(id, options)
  payment_plan_preview_api_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"payment_plan_preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payment_plans => /setup/payment_plans(.:format)
  // function(options)
  payment_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"payment_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payment_setup => /setup/payment_setup(.:format)
  // function(options)
  payment_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"payment_setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payment_success_contract => /contracts/:id/payment_success(.:format)
  // function(id, options)
  payment_success_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"payment_success",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payments => /setup/payments(.:format)
  // function(options)
  payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payout => /setup/payouts/:id(.:format)
  // function(id, options)
  payout_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"payouts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payouts => /setup/payouts(.:format)
  // function(options)
  payouts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"payouts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payouts_admin_billing => /admin/billing/payouts(.:format)
  // function(options)
  payouts_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"payouts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// poll_ssatb => /setup/ssatb_poll(.:format)
  // function(options)
  poll_ssatb_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"ssatb_poll",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// portal_appointment => /portal/appointments/:id(.:format)
  // function(id, options)
  portal_appointment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_available_time => /portal/available_times/:id(.:format)
  // function(id, options)
  portal_available_time_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_available_time_appointments => /portal/available_times/:available_time_id/appointments(.:format)
  // function(available_time_id, options)
  portal_available_time_appointments_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// portal_available_time_appointments_new_form => /portal/available_times/:available_time_id/appointments/new/form(.:format)
  // function(available_time_id, options)
  portal_available_time_appointments_new_form_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// portal_available_time_appointments_new_form_validate => /portal/available_times/:available_time_id/appointments/new/form/validate(.:format)
  // function(available_time_id, options)
  portal_available_time_appointments_new_form_validate_path: Utils.route([["available_time_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"available_times",false],[2,[7,"/",false],[2,[3,"available_time_id",false],[2,[7,"/",false],[2,[6,"appointments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// portal_available_times => /portal/available_times(.:format)
  // function(options)
  portal_available_times_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"available_times",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// portal_billing => /portal/billing(/*all)(.:format)
  // function(options)
  portal_billing_path: Utils.route([["all",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[1,[2,[7,"/",false],[5,[3,"all",false],false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// portal_calendar => /portal/calendars/:id(.:format)
  // function(id, options)
  portal_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_calendars => /portal/calendars(.:format)
  // function(options)
  portal_calendars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"calendars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// portal_edit_inquiry => /portal/inquiries/:id/edit(.:format)
  // function(id, options)
  portal_edit_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// portal_inquiry => /portal/inquiries/:id(.:format)
  // function(id, options)
  portal_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_new_application => /portal/new_application(.:format)
  // function(options)
  portal_new_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"new_application",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// portal_new_inquiry => /portal/new_inquiry(.:format)
  // function(options)
  portal_new_inquiry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"new_inquiry",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// portal_pay_now => /portal/pay_now/:entity_type/:entity_id(.:format)
  // function(entity_type, entity_id, options)
  portal_pay_now_path: Utils.route([["entity_type",true],["entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"pay_now",false],[2,[7,"/",false],[2,[3,"entity_type",false],[2,[7,"/",false],[2,[3,"entity_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// portal_pay_now_success => /portal/pay_now_success/:entity_type/:entity_id(.:format)
  // function(entity_type, entity_id, options)
  portal_pay_now_success_path: Utils.route([["entity_type",true],["entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"pay_now_success",false],[2,[7,"/",false],[2,[3,"entity_type",false],[2,[7,"/",false],[2,[3,"entity_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// portal_pay_now_update_payment => /portal/pay_now/:entity_type/:entity_id/update_payment(.:format)
  // function(entity_type, entity_id, options)
  portal_pay_now_update_payment_path: Utils.route([["entity_type",true],["entity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"pay_now",false],[2,[7,"/",false],[2,[3,"entity_type",false],[2,[7,"/",false],[2,[3,"entity_id",false],[2,[7,"/",false],[2,[6,"update_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// portal_pdf_inquiry => /portal/inquiries/:id/pdf(.:format)
  // function(id, options)
  portal_pdf_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// portal_public_calendar => /portal/public_calendars/:id(.:format)
  // function(id, options)
  portal_public_calendar_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"public_calendars",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_student => /portal/student(.:format)
  // function(options)
  portal_student_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"student",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// portal_thank_you_inquiry => /portal/inquiries/:id/thank_you(.:format)
  // function(id, options)
  portal_thank_you_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"thank_you",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// portal_update_inquiry => /portal/inquiries/:id/edit(.:format)
  // function(id, options)
  portal_update_inquiry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"inquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// power_school_contact => /internal/power_school_contacts/:id(.:format)
  // function(id, options)
  power_school_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"power_school_contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// power_school_contacts => /internal/power_school_contacts(.:format)
  // function(options)
  power_school_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"power_school_contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// power_school_debug => /internal/power_school_debug(.:format)
  // function(options)
  power_school_debug_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"power_school_debug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// preview_api_billing_items => /api/billing_items/preview(.:format)
  // function(options)
  preview_api_billing_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_items",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// preview_snippets => /setup/snippets/preview(.:format)
  // function(options)
  preview_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"snippets",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// print_index => /print(.:format)
  // function(options)
  print_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// privacy => /privacy(.:format)
  // function(options)
  privacy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"privacy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// proceed_bulk_aasm_changing_contacts => /contacts/proceed_bulk_aasm_changing(.:format)
  // function(options)
  proceed_bulk_aasm_changing_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"proceed_bulk_aasm_changing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// process_stripe_events_now => /internal/process_stripe_events_now(.:format)
  // function(options)
  process_stripe_events_now_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"process_stripe_events_now",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// public_applications => /applications/public(.:format)
  // function(options)
  public_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"public",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// publish_form_template => /setup/form_templates/:id/publish(.:format)
  // function(id, options)
  publish_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"publish",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pull_audits_blackbaud_configs => /setup/blackbaud_configs/pull_audits(.:format)
  // function(options)
  pull_audits_blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[2,[7,"/",false],[2,[6,"pull_audits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pull_blackbaud_data_blackbaud_configs => /setup/blackbaud_configs/pull_blackbaud_data(.:format)
  // function(options)
  pull_blackbaud_data_blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[2,[7,"/",false],[2,[6,"pull_blackbaud_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pull_student_data_blackbaud_configs => /setup/blackbaud_configs/pull_student_data(.:format)
  // function(options)
  pull_student_data_blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[2,[7,"/",false],[2,[6,"pull_student_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pull_year_data_blackbaud_configs => /setup/blackbaud_configs/pull_year_data(.:format)
  // function(options)
  pull_year_data_blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[2,[7,"/",false],[2,[6,"pull_year_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// push_to_blackbaud_export => /setup/exports/:id/push_to_blackbaud(.:format)
  // function(id, options)
  push_to_blackbaud_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"push_to_blackbaud",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// push_to_power_school_export => /setup/exports/:id/push_to_power_school(.:format)
  // function(id, options)
  push_to_power_school_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"push_to_power_school",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// push_to_veracross_export => /setup/exports/:id/push_to_veracross(.:format)
  // function(id, options)
  push_to_veracross_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"push_to_veracross",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// query_api_contacts => /api/contacts/query(.:format)
  // function(options)
  query_api_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"query",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// quick_add_contacts => /contacts/quick_add(.:format)
  // function(options)
  quick_add_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"quick_add",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// quickbooks_api_stripe_payouts => /api/stripe_payouts/quickbooks(.:format)
  // function(options)
  quickbooks_api_stripe_payouts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_payouts",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rating_criteria => /setup/review/rating_criteria(.:format)
  // function(options)
  rating_criteria_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"rating_criteria",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// recommendation_ad_hoc_templates => /setup/recommendation_ad_hoc_templates(.:format)
  // function(options)
  recommendation_ad_hoc_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"recommendation_ad_hoc_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recommendation_portal => /recommendations/:token(/:action)(.:format)
  // function(token, options)
  recommendation_portal_path: Utils.route([["token",true],["action",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"recommendations",false],[2,[7,"/",false],[2,[3,"token",false],[2,[1,[2,[7,"/",false],[3,"action",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// recommendation_snippets => /setup/recommendation_snippets(.:format)
  // function(options)
  recommendation_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"recommendation_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recommendations_setup => /setup/recommendations(.:format)
  // function(options)
  recommendations_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"recommendations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// regenerate_contract => /contracts/regenerate/:contact_id(.:format)
  // function(contact_id, options)
  regenerate_contract_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"regenerate",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// regenerate_secret_api_api_credential => /api/api_credentials/:id/regenerate_secret(.:format)
  // function(id, options)
  regenerate_secret_api_api_credential_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"api_credentials",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"regenerate_secret",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reindex => /internal/reindex(.:format)
  // function(options)
  reindex_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"reindex",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// remove_from_workflow => /contacts/remove_from_workflow/:id/:year_id(.:format)
  // function(id, year_id, options)
  remove_from_workflow_path: Utils.route([["id",true],["year_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"remove_from_workflow",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[3,"year_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rendered_api_web_snippet => /api/web_snippets/:id/rendered(.:format)
  // function(id, options)
  rendered_api_web_snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web_snippets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"rendered",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reorder_pages_form_template => /setup/form_templates/:id/reorder_pages(.:format)
  // function(id, options)
  reorder_pages_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reorder_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// report_reporting_admissions_funnel => /reporting/admissions_funnel/report(.:format)
  // function(options)
  report_reporting_admissions_funnel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"admissions_funnel",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// report_reporting_enrollment_snapshot => /reporting/enrollment_snapshot/report(.:format)
  // function(options)
  report_reporting_enrollment_snapshot_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"enrollment_snapshot",false],[2,[7,"/",false],[2,[6,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reporting_admissions_funnel => /reporting/admissions_funnel(.:format)
  // function(options)
  reporting_admissions_funnel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"admissions_funnel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reporting_combined_enrollment_total_report => /reporting/combined_enrollment_total_report(.:format)
  // function(options)
  reporting_combined_enrollment_total_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"combined_enrollment_total_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reporting_combined_funnel_report => /reporting/combined_funnel_report(.:format)
  // function(options)
  reporting_combined_funnel_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"combined_funnel_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reporting_enrollment_snapshot => /reporting/enrollment_snapshot(.:format)
  // function(options)
  reporting_enrollment_snapshot_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"enrollment_snapshot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reporting_key_feature_report => /reporting/key_feature_report(.:format)
  // function(options)
  reporting_key_feature_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"key_feature_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// resend_api_email_histories => /api/email_histories/resend(.:format)
  // function(options)
  resend_api_email_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_histories",false],[2,[7,"/",false],[2,[6,"resend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// resend_checklist_answer_checklist_request => /checklist_answers/:checklist_answer_id/checklist_request/resend_request(.:format)
  // function(checklist_answer_id, options)
  resend_checklist_answer_checklist_request_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"checklist_request",false],[2,[7,"/",false],[2,[6,"resend_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// resend_checklist_answer_recommendation_request => /checklist_answers/:checklist_answer_id/recommendation_request/resend_request(.:format)
  // function(checklist_answer_id, options)
  resend_checklist_answer_recommendation_request_path: Utils.route([["checklist_answer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"checklist_answers",false],[2,[7,"/",false],[2,[3,"checklist_answer_id",false],[2,[7,"/",false],[2,[6,"recommendation_request",false],[2,[7,"/",false],[2,[6,"resend_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reset_api_billing_account => /api/billing_accounts/:id/reset(.:format)
  // function(id, options)
  reset_api_billing_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// revenue_recognition_report_admin_billing => /admin/billing/revenue_recognition_report(.:format)
  // function(options)
  revenue_recognition_report_admin_billing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"billing",false],[2,[7,"/",false],[2,[6,"revenue_recognition_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// review => /setup/review(.:format)
  // function(options)
  review_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reviews_admin => /admissions/admin_reviews(.:format)
  // function(options)
  reviews_admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admissions",false],[2,[7,"/",false],[2,[6,"admin_reviews",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// rotate_api_bulk_file_page => /api/bulk_file_pages/:id/rotate(.:format)
  // function(id, options)
  rotate_api_bulk_file_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"bulk_file_pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"rotate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rswag_api => /api/external/docs
  // function(options)
  rswag_api_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[6,"docs",false]]]]]]),
// rswag_ui => /api/external/docs
  // function(options)
  rswag_ui_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"external",false],[2,[7,"/",false],[6,"docs",false]]]]]]),
// run_now_scheduled_export => /setup/scheduled_exports/:id/run_now(.:format)
  // function(id, options)
  run_now_scheduled_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"scheduled_exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"run_now",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// run_scheduled_export => /setup/scheduled_exports/:id/run(.:format)
  // function(id, options)
  run_scheduled_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"scheduled_exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sanity_checks_solrs => /internal/solrs/sanity_checks(.:format)
  // function(options)
  sanity_checks_solrs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"solrs",false],[2,[7,"/",false],[2,[6,"sanity_checks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// save_household => /contacts/save_household/:contact_id(.:format)
  // function(contact_id, options)
  save_household_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"save_household",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// scheduled_emails => /setup/scheduled_emails(.:format)
  // function(options)
  scheduled_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"scheduled_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// scheduled_export => /setup/scheduled_exports/:id(.:format)
  // function(id, options)
  scheduled_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"scheduled_exports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// scheduled_exports => /setup/scheduled_exports(.:format)
  // function(options)
  scheduled_exports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"scheduled_exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// school_year => /setup/school_years/:id(.:format)
  // function(id, options)
  school_year_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// school_years => /setup/school_years(.:format)
  // function(options)
  school_years_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"school_years",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// scrubbing_setup => /setup/data_scrubbing(.:format)
  // function(options)
  scrubbing_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"data_scrubbing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// search => /search(/:id(.:format))
  // function(options)
  search_path: Utils.route([["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[1,[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]],false]]]),
// search_autocomplete => /search/ac(/:q)(.:format)
  // function(options)
  search_autocomplete_path: Utils.route([["q",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"ac",false],[2,[1,[2,[7,"/",false],[3,"q",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// search_export => /search/export/:export_format/:id(.:format)
  // function(export_format, id, options)
  search_export_path: Utils.route([["export_format",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[3,"export_format",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// search_formatted => /search.:format
  // function(format, options)
  search_formatted_path: Utils.route([["format",true]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]),
// search_redirect_api_reporting_combined_funnel_report => /api/reporting/combined_funnel_report/search_redirect(.:format)
  // function(options)
  search_redirect_api_reporting_combined_funnel_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"combined_funnel_report",false],[2,[7,"/",false],[2,[6,"search_redirect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// searches => /searches(.:format)
  // function(options)
  searches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"searches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// send_authentication => /contacts/send_authentication/:contact_id/:recipient_id/:page(.:format)
  // function(contact_id, recipient_id, page, options)
  send_authentication_path: Utils.route([["contact_id",true],["recipient_id",true],["page",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"send_authentication",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[3,"recipient_id",false],[2,[7,"/",false],[2,[3,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// send_authentication_contacts => /contacts/send_authentication/:contact_id/:recipient_id/:page(.:format)
  // function(contact_id, recipient_id, page, options)
  send_authentication_contacts_path: Utils.route([["contact_id",true],["recipient_id",true],["page",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"send_authentication",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[3,"recipient_id",false],[2,[7,"/",false],[2,[3,"page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// send_email => /contacts/send_email/:email_type(.:format)
  // function(email_type, options)
  send_email_path: Utils.route([["email_type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"send_email",false],[2,[7,"/",false],[2,[3,"email_type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_email_modal => /contacts/send_email_modal(.:format)
  // function(options)
  send_email_modal_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"send_email_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// send_emails_now => /internal/send_emails_now(.:format)
  // function(options)
  send_emails_now_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"send_emails_now",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// send_statement_api_billing_account => /api/billing_accounts/:id/send_statement(.:format)
  // function(id, options)
  send_statement_api_billing_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_statement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// set_primary => /contacts/:id/set_primary(.:format)
  // function(id, options)
  set_primary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"set_primary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings => /internal/settings(.:format)
  // function(options)
  settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// setup_basic => /setup/basic(.:format)
  // function(options)
  setup_basic_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"basic",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// setup_home => /setup(.:format)
  // function(options)
  setup_home_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sever_relationship => /contacts/sever_relationship/:contact_id/:member_id(.:format)
  // function(contact_id, member_id, options)
  sever_relationship_path: Utils.route([["contact_id",true],["member_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"sever_relationship",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[3,"member_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sftp_config => /setup/sftp_configs/:id(.:format)
  // function(id, options)
  sftp_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"sftp_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sftp_configs => /setup/sftp_configs(.:format)
  // function(options)
  sftp_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"sftp_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sherlock => /internal/sherlock(.:format)
  // function(options)
  sherlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"sherlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sherlock_update_application => /internal/sherlock/update_application/:id(.:format)
  // function(id, options)
  sherlock_update_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"sherlock",false],[2,[7,"/",false],[2,[6,"update_application",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// show_pages_form_template => /setup/form_templates/:id/show_pages(.:format)
  // function(id, options)
  show_pages_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// show_pdf => /contracts/:id/pdf(.:format)
  // function(id, options)
  show_pdf_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pdf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// show_signature_invitation => /contracts/show_signature_invitation/:invitation_id(.:format)
  // function(invitation_id, options)
  show_signature_invitation_path: Utils.route([["invitation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"show_signature_invitation",false],[2,[7,"/",false],[2,[3,"invitation_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// simplified_export_api_stripe_balance_transactions => /api/stripe_balance_transactions/simplified_export(.:format)
  // function(options)
  simplified_export_api_stripe_balance_transactions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_balance_transactions",false],[2,[7,"/",false],[2,[6,"simplified_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// simplified_export_api_stripe_payouts => /api/stripe_payouts/simplified_export(.:format)
  // function(options)
  simplified_export_api_stripe_payouts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_payouts",false],[2,[7,"/",false],[2,[6,"simplified_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// site => /internal/sites/:id(.:format)
  // function(id, options)
  site_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"sites",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sites => /internal/sites(.:format)
  // function(options)
  sites_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"sites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// snippet => /setup/snippets/:id(.:format)
  // function(id, options)
  snippet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"snippets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// snippet_generate => /setup/snippets/:snippet_id/generate(.:format)
  // function(snippet_id, options)
  snippet_generate_path: Utils.route([["snippet_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"snippets",false],[2,[7,"/",false],[2,[3,"snippet_id",false],[2,[7,"/",false],[2,[6,"generate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// snippet_print => /setup/snippets/:snippet_id/print(.:format)
  // function(snippet_id, options)
  snippet_print_path: Utils.route([["snippet_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"snippets",false],[2,[7,"/",false],[2,[3,"snippet_id",false],[2,[7,"/",false],[2,[6,"print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// snippets => /setup/snippets(.:format)
  // function(options)
  snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// solrs => /internal/solrs(.:format)
  // function(options)
  solrs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"solrs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// start_nmi_redirect => /payments/start_nmi(.:format)
  // function(options)
  start_nmi_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"start_nmi",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stat => /internal/stats/:id(.:format)
  // function(id, options)
  stat_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"stats",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// statement_billing_account => /billing_accounts/:id/statement(.:format)
  // function(id, options)
  statement_billing_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"billing_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"statement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stats => /internal/stats(.:format)
  // function(options)
  stats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stripe_oauth_redirect => /oauth/stripe(.:format)
  // function(options)
  stripe_oauth_redirect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stripe_setup => /setup/stripe(.:format)
  // function(options)
  stripe_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// students => /students(.:format)
  // function(options)
  students_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"students",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// styling_setup => /setup/styling(.:format)
  // function(options)
  styling_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"styling",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_application => /applications/submit(.:format)
  // function(options)
  submit_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// summary_api_stripe_payouts => /api/stripe_payouts/summary(.:format)
  // function(options)
  summary_api_stripe_payouts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_payouts",false],[2,[7,"/",false],[2,[6,"summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// system_snippets => /setup/system_snippets(.:format)
  // function(options)
  system_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"system_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tag_api_email_snippets => /api/email_snippets/tag(.:format)
  // function(options)
  tag_api_email_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_snippets",false],[2,[7,"/",false],[2,[6,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tag_api_letter_snippets => /api/letter_snippets/tag(.:format)
  // function(options)
  tag_api_letter_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"letter_snippets",false],[2,[7,"/",false],[2,[6,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tag_api_mailing_label_snippets => /api/mailing_label_snippets/tag(.:format)
  // function(options)
  tag_api_mailing_label_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mailing_label_snippets",false],[2,[7,"/",false],[2,[6,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tag_api_mms_snippets => /api/mms_snippets/tag(.:format)
  // function(options)
  tag_api_mms_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms_snippets",false],[2,[7,"/",false],[2,[6,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tag_api_saved_searches => /api/saved_searches/tag(.:format)
  // function(options)
  tag_api_saved_searches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"saved_searches",false],[2,[7,"/",false],[2,[6,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tag_api_upload_fields => /api/upload_fields/tag(.:format)
  // function(options)
  tag_api_upload_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"upload_fields",false],[2,[7,"/",false],[2,[6,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tag_api_web_snippets => /api/web_snippets/tag(.:format)
  // function(options)
  tag_api_web_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web_snippets",false],[2,[7,"/",false],[2,[6,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tag_api_widget_snippets => /api/widget_snippets/tag(.:format)
  // function(options)
  tag_api_widget_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"widget_snippets",false],[2,[7,"/",false],[2,[6,"tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tags => /setup/tags(.:format)
  // function(options)
  tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// targets_reporting_enrollment_snapshot => /reporting/enrollment_snapshot/targets(.:format)
  // function(options)
  targets_reporting_enrollment_snapshot_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"enrollment_snapshot",false],[2,[7,"/",false],[2,[6,"targets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// task => /tasks/:id(.:format)
  // function(id, options)
  task_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tasks => /tasks(.:format)
  // function(options)
  tasks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// tax_statement_billing_account => /billing_accounts/:id/tax_statement(.:format)
  // function(id, options)
  tax_statement_billing_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"billing_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"tax_statement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// term_info_modal => /search/term_info_modal(.:format)
  // function(options)
  term_info_modal_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"term_info_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// test_api_veracross_config => /api/veracross_config/test(.:format)
  // function(options)
  test_api_veracross_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"veracross_config",false],[2,[7,"/",false],[2,[6,"test",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// test_facts_config => /setup/test_facts_config(.:format)
  // function(options)
  test_facts_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"test_facts_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// test_orders => /orders/test(.:format)
  // function(options)
  test_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"test",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// toggle_view => /contacts/:id/page/toggle(.:format)
  // function(id, options)
  toggle_view_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"page",false],[2,[7,"/",false],[2,[6,"toggle",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// twilio_webhooks_api_twilio => /api/twilio/webhooks(.:format)
  // function(options)
  twilio_webhooks_api_twilio_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"twilio",false],[2,[7,"/",false],[2,[6,"webhooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unhide_export => /setup/exports/:id/unhide(.:format)
  // function(id, options)
  unhide_export_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"exports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unhide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// unlink_account => /contacts/unlink_account/:main_contact_id/:contact_id(.:format)
  // function(main_contact_id, contact_id, options)
  unlink_account_path: Utils.route([["main_contact_id",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"unlink_account",false],[2,[7,"/",false],[2,[3,"main_contact_id",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// untag_api_email_snippets => /api/email_snippets/untag(.:format)
  // function(options)
  untag_api_email_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_snippets",false],[2,[7,"/",false],[2,[6,"untag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// untag_api_letter_snippets => /api/letter_snippets/untag(.:format)
  // function(options)
  untag_api_letter_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"letter_snippets",false],[2,[7,"/",false],[2,[6,"untag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// untag_api_mailing_label_snippets => /api/mailing_label_snippets/untag(.:format)
  // function(options)
  untag_api_mailing_label_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mailing_label_snippets",false],[2,[7,"/",false],[2,[6,"untag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// untag_api_mms_snippets => /api/mms_snippets/untag(.:format)
  // function(options)
  untag_api_mms_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms_snippets",false],[2,[7,"/",false],[2,[6,"untag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// untag_api_saved_searches => /api/saved_searches/untag(.:format)
  // function(options)
  untag_api_saved_searches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"saved_searches",false],[2,[7,"/",false],[2,[6,"untag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// untag_api_upload_fields => /api/upload_fields/untag(.:format)
  // function(options)
  untag_api_upload_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"upload_fields",false],[2,[7,"/",false],[2,[6,"untag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// untag_api_web_snippets => /api/web_snippets/untag(.:format)
  // function(options)
  untag_api_web_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web_snippets",false],[2,[7,"/",false],[2,[6,"untag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// untag_api_widget_snippets => /api/widget_snippets/untag(.:format)
  // function(options)
  untag_api_widget_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"widget_snippets",false],[2,[7,"/",false],[2,[6,"untag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_comment_form_template => /setup/form_templates/:id/update_comment(.:format)
  // function(id, options)
  update_comment_form_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_contract => /contracts/update/:contact_id/:id(.:format)
  // function(contact_id, id, options)
  update_contract_path: Utils.route([["contact_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_default_reporting_school_year_school_years => /setup/school_years/update_default_reporting_school_year(.:format)
  // function(options)
  update_default_reporting_school_year_school_years_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"school_years",false],[2,[7,"/",false],[2,[6,"update_default_reporting_school_year",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_facts_config => /setup/facts_config(.:format)
  // function(options)
  update_facts_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"facts_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_form_preview => /setup/form_preview/:template_id/edit(.:format)
  // function(template_id, options)
  update_form_preview_path: Utils.route([["template_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"form_preview",false],[2,[7,"/",false],[2,[3,"template_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_from_blackbaud_blackbaud_configs => /setup/blackbaud_configs/update_from_blackbaud(.:format)
  // function(options)
  update_from_blackbaud_blackbaud_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"blackbaud_configs",false],[2,[7,"/",false],[2,[6,"update_from_blackbaud",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_house_members => /contacts/:contact_id/update_household_membership(.:format)
  // function(contact_id, options)
  update_house_members_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[2,[7,"/",false],[2,[6,"update_household_membership",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_intercom_config => /setup/intercom_config(.:format)
  // function(options)
  update_intercom_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"intercom_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_line_items_line_items_refreshes => /internal/line_items_refreshes/update_line_items(.:format)
  // function(options)
  update_line_items_line_items_refreshes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"line_items_refreshes",false],[2,[7,"/",false],[2,[6,"update_line_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_lotteries => /setup/manage_lotteries(.:format)
  // function(options)
  update_lotteries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"manage_lotteries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_name_ad_hoc_templates => /setup/ad_hoc_templates/update_name(.:format)
  // function(options)
  update_name_ad_hoc_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"ad_hoc_templates",false],[2,[7,"/",false],[2,[6,"update_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_non_fee_billing_category => /setup/non_fee_billing_categories(.:format)
  // function(options)
  update_non_fee_billing_category_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"non_fee_billing_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_ordering_admissions_checklist_items => /setup/admissions_checklist_items/update_ordering(.:format)
  // function(options)
  update_ordering_admissions_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"admissions_checklist_items",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_api_billing_category_groups => /api/billing_category_groups/update_ordering(.:format)
  // function(options)
  update_ordering_api_billing_category_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"billing_category_groups",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_api_fee_rules => /api/fee_rules/update_ordering(.:format)
  // function(options)
  update_ordering_api_fee_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_api_payment_plans => /api/payment_plans/update_ordering(.:format)
  // function(options)
  update_ordering_api_payment_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_plans",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_application_fee_rules => /setup/application_fee_rules/update_ordering(.:format)
  // function(options)
  update_ordering_application_fee_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"application_fee_rules",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_contact_fields => /setup/contact_fields/update_ordering(.:format)
  // function(options)
  update_ordering_contact_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"contact_fields",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_enrollment_checklist_items => /setup/enrollment_checklist_items/update_ordering(.:format)
  // function(options)
  update_ordering_enrollment_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"enrollment_checklist_items",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_fee_rules => /setup/fee_rules/update_ordering(.:format)
  // function(options)
  update_ordering_fee_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fee_rules",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_fields => /setup/fields/update_ordering(.:format)
  // function(options)
  update_ordering_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"fields",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_grades => /setup/grades/update_ordering(.:format)
  // function(options)
  update_ordering_grades_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"grades",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ordering_inquiry_checklist_items => /setup/inquiry_checklist_items/update_ordering(.:format)
  // function(options)
  update_ordering_inquiry_checklist_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"inquiry_checklist_items",false],[2,[7,"/",false],[2,[6,"update_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_power_school_config => /setup/power_school_config(.:format)
  // function(options)
  update_power_school_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"power_school_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_setup_application_fee_rules => /setup/application_fee_rules/update_setup(.:format)
  // function(options)
  update_setup_application_fee_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"application_fee_rules",false],[2,[7,"/",false],[2,[6,"update_setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_ssatb_config => /setup/ssatb_config(.:format)
  // function(options)
  update_ssatb_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"ssatb_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_targets_reporting_enrollment_snapshot => /reporting/enrollment_snapshot/update_targets(.:format)
  // function(options)
  update_targets_reporting_enrollment_snapshot_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reporting",false],[2,[7,"/",false],[2,[6,"enrollment_snapshot",false],[2,[7,"/",false],[2,[6,"update_targets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// uploaded_file => /uploaded_files/:id(.:format)
  // function(id, options)
  uploaded_file_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"uploaded_files",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user => /setup/users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_communication_preferences => /users/communication_preferences(.:format)
  // function(options)
  user_communication_preferences_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"communication_preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_impersonate => /setup/users/:user_id/impersonate(.:format)
  // function(user_id, options)
  user_impersonate_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"impersonate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_info => /internal/user_info(.:format)
  // function(options)
  user_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"user_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_jobs => /setup/user_jobs(.:format)
  // function(options)
  user_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_migrate_to_admin => /setup/users/:user_id/migrate_to_admin(.:format)
  // function(user_id, options)
  user_migrate_to_admin_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"migrate_to_admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_reset_password => /setup/users/:user_id/reset_password(.:format)
  // function(user_id, options)
  user_reset_password_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_tasks => /users/:user_id/tasks(.:format)
  // function(user_id, options)
  user_tasks_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_unlock => /users/unlock(.:format)
  // function(options)
  user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users => /setup/users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_password_new_trackback => /users/password/new/trackback(.:format)
  // function(options)
  users_password_new_trackback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[2,[7,"/",false],[2,[6,"trackback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// validate_api_email_snippets => /api/email_snippets/validate(.:format)
  // function(options)
  validate_api_email_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"email_snippets",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// validate_api_letter_snippets => /api/letter_snippets/validate(.:format)
  // function(options)
  validate_api_letter_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"letter_snippets",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// validate_api_mailing_label_snippets => /api/mailing_label_snippets/validate(.:format)
  // function(options)
  validate_api_mailing_label_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mailing_label_snippets",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// validate_api_mms_snippets => /api/mms_snippets/validate(.:format)
  // function(options)
  validate_api_mms_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mms_snippets",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// validate_api_upload_fields => /api/upload_fields/validate(.:format)
  // function(options)
  validate_api_upload_fields_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"upload_fields",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// validate_api_web_snippets => /api/web_snippets/validate(.:format)
  // function(options)
  validate_api_web_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"web_snippets",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// validate_api_widget_snippets => /api/widget_snippets/validate(.:format)
  // function(options)
  validate_api_widget_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"widget_snippets",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// value_lists_api_veracross_config => /api/veracross_config/value_lists(.:format)
  // function(options)
  value_lists_api_veracross_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"veracross_config",false],[2,[7,"/",false],[2,[6,"value_lists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// veracross_config => /setup/veracross_config(.:format)
  // function(options)
  veracross_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"veracross_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// verify_api_payment_verification => /api/payment_verifications/:id/verify(.:format)
  // function(id, options)
  verify_api_payment_verification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"payment_verifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// verify_api_user_jobs_submit_contracts_jobs => /api/user_jobs/submit_contracts_jobs/verify(.:format)
  // function(options)
  verify_api_user_jobs_submit_contracts_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_jobs",false],[2,[7,"/",false],[2,[6,"submit_contracts_jobs",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// verify_authentication => /portal/verify_authentication(.:format)
  // function(options)
  verify_authentication_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"verify_authentication",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// verify_microdeposits_api_stripe_custom => /api/stripe_custom/verify_microdeposits(.:format)
  // function(options)
  verify_microdeposits_api_stripe_custom_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"stripe_custom",false],[2,[7,"/",false],[2,[6,"verify_microdeposits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// view_contract => /contracts/show/:contact_id(.:format)
  // function(contact_id, options)
  view_contract_path: Utils.route([["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"contracts",false],[2,[7,"/",false],[2,[6,"show",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// view_signers => /contacts/view_signers(.:format)
  // function(options)
  view_signers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"view_signers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// waitlist => /admin/waitlists(.:format)
  // function(options)
  waitlist_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"waitlists",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// waitlist_verify_current_ordering => /admin/waitlists/verify_current_ordering(.:format)
  // function(options)
  waitlist_verify_current_ordering_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"waitlists",false],[2,[7,"/",false],[2,[6,"verify_current_ordering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// webcal_admin_calendar => /admin/calendars/:id/export(.:format)
  // function(id, options)
  webcal_admin_calendar_path: Utils.route([["id",true],["format",false]], {"protocol":"webcal"}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"calendars",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// welcome_index => /welcome/index(.:format)
  // function(options)
  welcome_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"welcome",false],[2,[7,"/",false],[2,[6,"index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// widget_snippets => /setup/widget_snippets(.:format)
  // function(options)
  widget_snippets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"widget_snippets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, "Routes", result);
  }

  return result;

}).call(this);
