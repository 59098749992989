import adHocFormItem from '../../../../templates/checklist/_ad_hoc_form.jst.ejs';
import applicationItem from '../../../../templates/checklist/_application.jst.ejs';
import calendarItem from '../../../../templates/checklist/_calendar.jst.ejs';
import checklistFormItem from '../../../../templates/checklist/_checklist_form_item.jst.ejs';
import checklistRequestItem from '../../../../templates/checklist/_checklist_request.jst.ejs';
import contractItem from '../../../../templates/checklist/_contract.jst.ejs';
import depositItem from '../../../../templates/checklist/_deposit.jst.ejs';
import formFeeItem from '../../../../templates/checklist/_form_fee.jst.ejs';
import fileUploadItem from '../../../../templates/checklist/_file_upload.jst.ejs';
import inquiryItem from '../../../../templates/checklist/_inquiry.jst.ejs';
import multiFileUploadItem from '../../../../templates/checklist/_multi_file_upload.jst.ejs';
import portraitItem from '../../../../templates/checklist/_portrait.jst.ejs';
import recommendationRequestItem from '../../../../templates/checklist/_recommendation_request.jst.ejs';

const templateMap = {
  'checklist/ad_hoc_form_item': adHocFormItem,
  'checklist/application_item': applicationItem,
  'checklist/application_fee_item': formFeeItem,
  'checklist/calendar_item': calendarItem,
  'checklist/checklist_form_item': checklistFormItem,
  'checklist/checklist_request_item': checklistRequestItem,
  'checklist/contract_item': contractItem,
  'checklist/custom_fee_item': formFeeItem,
  'checklist/deposit_item': depositItem,
  'checklist/file_upload_item': fileUploadItem,
  'checklist/inquiry_item': inquiryItem,
  'checklist/multi_file_upload_item': multiFileUploadItem,
  'checklist/portrait_item': portraitItem,
  'checklist/recommendation_request_item': recommendationRequestItem
};

export default templateMap;
