import ChecklistFormItem from './_checklist_form_item';

const AdHocFormItem = ChecklistFormItem.extend({
  confirmIncomplete() {
    return this.model.has('form_id')
      ? this.confirm('Confirm Changes', 'The form will be un-submitted. Do you want to continue?')
      : null;
  }
});

export default AdHocFormItem;
