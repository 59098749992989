import Backbone from 'backbone';

import template from '../../../../templates/checklist/checklists.jst.ejs';
import ChecklistView from './checklist';

const Checklists = Backbone.View.extend({
  el: '.contact .checklist .lists',

  initialize: function() {
    var self = this;
    // Listen to event triggered on DOM element for refresh
    this.$el.on("refresh", function() { self.refresh(); });
  },

  // Fetch new checklist and refresh views
  refresh: function () {
    var self = this;
    this.collection.fetch({
      success: function() {
        self.render();
      }
    });
  },

  render : function() {
    var self = this;
    var workflows = [];

    this.collection.each(function(checklist) {
      workflows.push(checklist.get('name'));
    });

    this.$el.html(template({
      checklists: this.collection,
      current_workflow: workflows.indexOf(self.options.current_workflow) != -1 ? self.options.current_workflow : workflows[workflows.length - 1]
    }));

    this.collection.each(function(checklist) {
      new ChecklistView({
        model: checklist, el: '.contact .checklist .lists .tab-' + checklist.get('name'),
        workflows: workflows}
      ).render();
    });

    return this;
  }
});

export default Checklists;
