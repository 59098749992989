import ChecklistFormItem from './_checklist_form_item';
import ChecklistItem from './checklist_item';
import Routes from '../../../routes';

const ApplicationItem = ChecklistFormItem.extend({
  events: Object.assign({
    'click .edit-date-link': 'changeApplicationDate',
    'click .delete-file-link': 'deleteFile'
  }, ChecklistItem.prototype.events),

  changeApplicationDate() {
    const self = this;
    this.applicationDateModal().then(() => {
      self.model.fetch();
    });
    return false;
  },

  confirmComplete() {
    return this.applicationDateModal();
  },

  applicationDateModal() {
    var ok = $.Deferred();

    Dialog.open(Routes.contact_paper_application_path(this.model.get('contact_id'))).then((result) => {
      if (result === 'ok') {
        ok.resolve();
      }
    });

    return ok;
  }
});

export default ApplicationItem;
