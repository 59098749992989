import Backbone from 'backbone';
import accounting from 'accounting';

import templateMap from './templateMap';
import checklistItemTemplate from '../../../../templates/checklist/checklist_item.jst.ejs';

import { formatDate } from '../../../react/utils/i18n';

const ChecklistItem = Backbone.View.extend({
  tagName: 'li',

  events: {
    "click .box i": "toggleItem",
  },

  initialize: function() {
    this.listenTo(this.model, 'change', this.render);
    $(this.el).attr('id', this.model.get('id'));
  },

  render() {
    const $el = $(this.el);
    const templateName = `checklist/${this.model.get('type')}_item`;
    const hasTemplate = !!templateMap[templateName];

    const template = $(`<div>${hasTemplate
      ? templateMap[templateName]({
        item: this.model,
        view: this,
        templateMap,
        accounting
      })
      : ''
    }</div>`);

    const layout = $(checklistItemTemplate({
      item: this.model,
      view: this,
      action_el: template.find('.action'),
      detail_el: template.find('.detail')
    }));

    $el.html(layout);

    this.bindEditableDate();
    return this;
  },

  bindEditableDate: function () {
    var self = this;

    $(this.el).find('a.completion-date').first().datepicker({
      format: I18n.date.formats.default_datepicker,
      autoclose: true,
      todayBtn: true,
    })
      .on('changeDate', function(e) {
        self.model.set('completion_date', e.date.toISOString());
        self.model.save();
      });
  },

  toggleItem: function () {
    var model = this.model;
    if (!this.isDisabled()) {
      if (model.isComplete()) {
        $.when(this.confirmIncomplete()).then(function () {
          model.set('completion_date', null);
          model.save(null, {
            success: function() {
              // Update the status in the contact record header
              $('.contact-header .status').text(model.get('status'));
            }
          });
          model.trigger('incomplete');
        });
      } else {
        $.when(this.confirmComplete()).then(function () {
          // 'now_completion_date' is a special param that checklist_answers#update understands
          model.set('now_completion_date', true);
          model.save(null, {
            success: function() {
              // Update the status in the contact record header
              $('.contact-header .status').text(model.get('status'));
              // Unset now_completion_date so it doesn't persist to future saves
              model.unset('now_completion_date');
            }
          });
          model.trigger('complete');
        });
      }
    }
  },

  /**
   * Presents a confirmation modal with the given prompt.
   *
   * @param prompt the modal prompt
   * @returns a deferred that is marked resolved when the confirmation is accepted.
   */
  confirm: function (heading, prompt) {
    var confirm = $.Deferred();
    Modal.confirmModal({
      heading: heading,
      body: prompt,
      callback: function () {
        confirm.resolve();
      }
    });
    return confirm;
  },

  isDisabled: function () { return false; },
  confirmComplete: function () {},
  confirmIncomplete: function () {}
});

export default ChecklistItem;
