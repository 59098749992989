module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'detail\'>\n  ';
 if ((item.has('contract_submit_date') || item.has('paper_contract_receive_date')) && item.get("viewable_permission")) { ;
__p += '\n    Submitted on ' +
((__t = ( item.get('paper_contract_receive_date') || item.get('contract_submit_date') )) == null ? '' : __t) +
'\n        ';
 if (item.has('paper_contract_submitter')) { ;
__p += '\n            by ' +
((__t = ( item.get('paper_contract_submitter'))) == null ? '' : __t) +
'.\n            ';
 if (item.has('contract_id')) { ;
__p += '\n                <a href="' +
((__t = ( Routes.show_pdf_path(item.get('contract_id')) )) == null ? '' : __t) +
'" target="_blank"> See details <i class="fa fa-eye"></i></a>\n            ';
 } ;
__p += '\n\n        ';
 } ;
__p += '\n  ';
 } else if (item.has('contract_status')) { ;
__p += '  ' +
((__t = ( item.get('contract_status') )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n</div>\n\n<div class="action">\n  ';
 if ((item.has('contract_id') && item.has('contract_submit_date') && !item.has('paper_contract_receive_date')) && item.get("viewable_permission")) { ;
__p += '\n    <a href="' +
((__t = ( Routes.show_pdf_path(item.get('contract_id')) )) == null ? '' : __t) +
'" target="_blank">View Form <i class="fa fa-eye"></i></a>\n    ';
 } else if (item.has('contract_status') && ( item.get('contract_status') == 'Pending Signatures' || item.get('contract_status') == 'Pending Payment' ) ) { ;
__p += '\n    <a class="view-signers open-modal"\n     href="' +
((__t = ( Routes.view_signers_path({contract_id: item.get('contract_id')}) )) == null ? '' : __t) +
'" target="_blank">View Signers <i class="fa fa-eye"></i>\n    </a>\n    ';
 }  else { ;
__p += '\n        ' +
((__t = ( templateMap['checklist/checklist_form_item']({item: item}) )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}