module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detail">\n    ';
 if (item.has('request_status') && item.get('request_status') != 'Cancelled') { ;
__p += '\n        ' +
((__t = ( item.get('request_status') )) == null ? '' : __t) +
' on ' +
((__t = ( item.get('request_status_date') )) == null ? '' : __t) +
'\n    ';
 } else if (item.has('link_description')) { ;
__p += '\n        Linked on ' +
((__t = ( item.get('link_date') )) == null ? '' : __t) +
' to ' +
((__t = ( item.get('link_description') )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n</div>\n\n<div class="action">\n    ';
 if (item.has('request_status') && item.get("viewable_permission") && item.get('request_status') == 'Submitted' && item.has('request_form_id')) { ;
__p += '\n        <a href="' +
((__t = ( Routes.ad_hoc_form_path(item.get('request_form_id')) )) == null ? '' : __t) +
'" target="_blank">View Form <i class="fa fa-eye"></i></a>\n\n    &middot;\n\n    ';
 } else if (item.get("files").length > 0 && item.get("viewable_permission")) { ;
__p += '\n        <a href="' +
((__t = ( Routes.checklist_answer_manage_uploaded_files_path(item.get('id')) )) == null ? '' : __t) +
'" class="manage-files-modal">Manage Files <i class="fa fa-upload"></i></a>\n    ';
 } else { ;
__p += '\n\n      ';
 if (item.get("viewable_permission")) { ;
__p += '\n        ';
 if (item.has('request_status')) { ;
__p += '\n            ';
 var submitted = item.get('request_status') == 'Submitted';
__p += '\n            ';
 var text = submitted ? 'Details ' : 'Manage ';
__p += '\n            ';
 var icon = submitted ? 'fa fa-eye ' : 'fa fa-cogs ';
__p += '\n            <a href="' +
((__t = ( Routes.checklist_answer_checklist_request_path(item.get('id')) )) == null ? '' : __t) +
'" class="open-modal">' +
((__t = ( text )) == null ? '' : __t) +
' <i class="' +
((__t = ( icon)) == null ? '' : __t) +
'"></i>  | </a>\n        ';
 } else if (!item.has('link_description')) { ;
__p += '\n            <a href="' +
((__t = ( Routes.new_checklist_answer_checklist_request_path(item.get('id')) )) == null ? '' : __t) +
'" class="open-modal">Manage <i class="fa fa-cogs"></i>  | </a>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n\n        ' +
((__t = ( templateMap['checklist/checklist_form_item']({item: item}) )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n\n</div>\n';

}
return __p
}