/*  jCrumb jquery plugin 
 version 1.0, Sep 24 2010
 Licensed under the BSD New License
 Developed by Jason Brown for any bugs or questions you can email me at loktar69@hotmail
 info on the plugin is located on Somethinghitme.com and http://code.google.com/p/jcrumb/

 List of options

 maxCrumbs : 4,         // Number : Sets how many crumbs are displayed on the page
 maxMemory : 15,       // Number : How many breadcrumbs will be stored for the user to follow
 defaultClass : 'jCrumb',   // String: Controls how the breadcrumbs look
 jqUI : false,        // Bool : Attached jqui styles
 seperator : "&gt;"       // False or HTML : Sets a seperator inbetween  the breadbcrumbs

 Default Usage :
 $('#element').jCrumb();

 -or-

 $('#element').jCrumb(options);
 */

(function ($) {
  $.fn.jCrumb = function (options) {
    var settings = {
        maxCrumbs: 4,
        maxMemory: 15,
        defaultClass: 'jCrumb',
        jqUI: false,
        seperator: "&gt;"
      },

      getCrumbs = function () {
        var cookieName = "breadcrumbs=";
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].replace(/^\s+/, "");

          if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
          }
        }
        return;
      },

      filterUserTypeCrumbs = function (crumbs, user_type) {
        crumbs = crumbs.split('*');
        for (i = 0; i < crumbs.length; i++) {
          if (crumbs[i].split('^')[2] != user_type) {
            crumbs.splice(i, 1);
            i--;
          }
        }
        return crumbs;
      },

      setCrumb = function (crumb) {
        var crumbs = getCrumbs();

        if (crumbs) {
          var maxMemory = settings.maxMemory;
          crumbCheck = crumbs.split('*');

          //[yuriy] lets check for dupes, replace link, move the item forward
          var cur_crumb_parts = crumb.split('^');
          var cur_title = cur_crumb_parts[0];
          var cur_link = cur_crumb_parts[1];

          var exp = new RegExp("(^|\\*)" + cur_title + "\\^", "gi");
          if (crumbs.match(exp)) //we have this title in our list, have a dupe
          {
            for (i = 0; i < crumbCheck.length; i++) {
              if (crumbCheck[i].indexOf(cur_title + '^') === 0) {
                crumbCheck.splice(i, 1);
              }
            }
            crumbCheck.push(crumb);
          }
          else // default functionality, no dupe found
          {
            //alert('kein match')
            if (crumbCheck[crumbCheck.length - 1] != crumb) {
              crumbCheck.push(crumb);
            }
            if (crumbCheck.length > maxMemory) {
              crumbCheck.splice(0, 1);
            }
          }
          crumbs = crumbCheck.join('*');
        }
        else {
          crumbs = crumb;
        }
        document.cookie = 'breadcrumbs=' + crumbs + '; path=/';
      },

      removeCrumb = function (index) {
        var crumbs = getCrumbs();
        if (crumbs) {
          crumbCheck = crumbs.split('*');
          crumbCheck.splice(index, (crumbCheck.length - index));
          crumbs = crumbCheck.join('*');
          document.cookie = 'breadcrumbs=' + crumbs + '; path=/';
        }

        return;
      };

    return this.each(function () {
      if (options) {
        settings = $.extend(settings, options);
      }

      // lets set type of portal, we create crumb for
      portal = (typeof portal === 'undefined') ? 'admin' : 'parent';
      show_default = (typeof show_default === 'undefined') ? false : show_default;

      //[yuriy] i want to NOT add the crumb, and show the last one as a link(Back to ...), by setting this var in layout view file
      if (typeof show_single_last_as_link !== 'undefined' && show_single_last_as_link === true) {
        //alert('just show last')
        crumbs = getCrumbs();
        if (crumbs) {
          if (show_default && (typeof user_type !== 'undefined')) {
            switch (user_type) {
              case 'admin':
                last_crumb = ['Dashboard', '/admin'];
                break;
              case 'user':
                last_crumb = ['Home', '/'];
                break;
            }
          }
          else if (user_type !== '') {
            crumbs = filterUserTypeCrumbs(crumbs, (user_type == 'admin' ? 'admin' : 'parent'));
            last_crumb = crumbs.pop().split('^');
          }
          else if (typeof user_type === 'undefined') {
            crumbs = crumbs.split('*');
            crumb = crumbs.pop();
            last_crumb = crumb === null ? ['Home', '/'] : crumb.split('^');
            //last_crumb = crumbs.pop().split('^');
          }
        }

        //alert(last_crumb);
        //lets set it
        if (typeof last_crumb === 'undefined') {
          $('#jcrumb_back_arrow').hide();
          $('#jcrumb_back_link').hide();
        }
        else {
          if ($('#jcrumb_back_arrow')) {
            $('#jcrumb_back_arrow').attr('href', last_crumb[1]);
          }
          if ($('#jcrumb_back_link')) {
            $('#jcrumb_back_link').attr('href', last_crumb[1]);
            $('#jcrumb_back_link').text('Back to ' + last_crumb[0]);
          }
        }
        return;
      }

      //[yuriy] you can avoid pushing new crumb, by setting this var in layout view file
      if (typeof set_crumb === 'undefined' || set_crumb !== false) {
        setCrumb(document.title + '^' + window.location.href + '^' + portal);
      }

      crumbs = getCrumbs();
      var crumbVal = "";
      var crumbList = $(document.createElement('ul')).addClass(settings.defaultClass).appendTo($(this));
      var crumbListItem = "";

      if (settings.jqUI) {
        crumbList.addClass('fg-buttonset fg-buttonset-single ui-helper-clearfix');
      }

      if (crumbs) {
        crumbs = filterUserTypeCrumbs(crumbs, 'admin');
        startIndex = 0;

        if (crumbs.length > settings.maxCrumbs) {
          startIndex = crumbs.length - settings.maxCrumbs;
        }

        removeCrumbFunc = function (obj) {
          return function () {
            removeCrumb($(obj).data("index"));
          };
        };

        for (var i = startIndex; i < crumbs.length - 1; i++) {
          crumbVal = crumbs[i].split('^');
          crumbListItem = $(document.createElement('li'));

          var crumbLink = $(document.createElement('a'));
          crumbLink.attr({'href': crumbVal[1]}).text(crumbVal[0]).appendTo(crumbListItem.appendTo(crumbList));
          crumbLink.click(removeCrumbFunc(this));
          crumbLink.data("index", i);

          if (settings.jqUI) {
            crumbListItem.addClass('fg-button ui-state-default ui-priority-primary');
            if (i === 0) {
              crumbListItem.addClass('ui-corner-left');
              crumbLink.addClass('ui-icon ui-icon-home');
            }
            if (startIndex !== 0 && i === startIndex) {
              crumbListItem.addClass('ui-corner-left');
              crumbLink.addClass('ui-icon ui-icon-carat-1-w');
            }
          } else if (settings.seperator) {
            $(document.createElement('span')).html(settings.seperator).appendTo(crumbListItem);
          }
        }

        crumbListItem = $(document.createElement('li'));
        crumbVal = crumbs[crumbs.length - 1] ? crumbs[crumbs.length - 1].split('^') : null;
        if (crumbVal) {
          $(document.createElement('span')).text(crumbVal[0]).appendTo($(crumbListItem).appendTo($(crumbList)));
        }

        if (settings.jqUI) {
          crumbListItem.addClass('fg-button ui-state-default ui-priority-primary ui-corner-right ui-state-active');
        }

        $(".fg-button:not(.ui-state-disabled)").hover(
          function () {
            $(this).addClass("ui-state-hover");
          },
          function () {
            $(this).removeClass("ui-state-hover");
          }
        );
      }
    });
  };
})(jQuery);
