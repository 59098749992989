import ChecklistItem from './checklist_item';

const FactsItem = ChecklistItem.extend({
  events: Object.assign({}, ChecklistItem.prototype.events),

  isDisabled() {
    return false;
  }
});

export default FactsItem;
