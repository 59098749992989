module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="action">\n    <span>\n      ';
 if (item.get("viewable_permission")) { ;
__p += '\n        ';
 if (item.has('appointment_ids')) { ;
__p += '\n          ';
 for (let i = 0; i < item.get('appointment_ids').length; i++) { ;
__p += '\n            <div>\n              ' +
((__t = ( item.get('appointment_texts')[i] )) == null ? '' : __t) +
'\n            </div>\n            ';
 const appointment_id = item.get('appointment_ids')[i] ;
__p += '\n            <div class="appointment_actions">\n            <a class="reschedule-appointment"\n              href="' +
((__t = ( Routes.portal_calendar_path(item.get('calendar_id'), {appointment_id: appointment_id, contact_id: item.get('contact_id'), mode: 'reschedule'}) )) == null ? '' : __t) +
'"\n              style="margin-right: 1rem">\n              Reschedule <i class="fa fa-calendar-o"></i>\n            </a>\n\n            <a class="view-appointment open-modal"\n              href="' +
((__t = ( Routes.confirm_cancel_admin_appointment_path(appointment_id) )) == null ? '' : __t) +
'"\n              style="margin-right: 1rem">\n                Cancel <i class="fa fa-calendar-times-o"></i>\n            </a>\n\n            <a class="view-appointment open-modal"\n              href="' +
((__t = ( Routes.admin_appointment_path(appointment_id, {hide_contact_link: 1}) )) == null ? '' : __t) +
'">\n              View Appointment <i class="fa fa-eye"></i>\n            </a>\n            </div>\n          ';
 } ;
__p += '\n        ';
 } else if (item.has('calendar_id')) { ;
__p += '\n          <a href="' +
((__t = ( Routes.admin_calendar_path(item.get('calendar_id'), {contact_id: item.get('contact_id')}) )) == null ? '' : __t) +
'">\n            Schedule Appointment <i class="fa fa-calendar"></i>\n          </a>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n  </span>\n</div>\n';

}
return __p
}