module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<input type="checkbox" ' +
((__t = ( item.isComplete() ? 'checked=checked' : '' )) == null ? '' : __t) +
'>\n\n<div class="row">\n  <div class="box checklist-item-box col-2 ' +
((__t = ( view.isDisabled() || !item.get('viewable_permission') ? 'disabled' : '' )) == null ? '' : __t) +
'">\n    ';
 if (item.isComplete()) { ;
__p += '\n    <i class="fa fa-check-square"></i>\n    ';
 } else { ;
__p += '\n    <i class="fa fa-square-o"></i>\n    ';
 } ;
__p += '\n  </div>\n\n  <div class="item col-22">\n    <div class="title">' +
((__t = ( item.get('checklist_item_name') )) == null ? '' : __t) +
'</div>\n    <div class="detail">' +
((__t = ( detail_el.html() )) == null ? '' : __t) +
'</div>\n    ';
 if (item.has('completion_date') || action_el.children().length > 0) { ;
__p += '\n      <div class="second-row row">\n        <div class=\'col-7\'>\n          ';
 if (item.has('completion_date') && !view.isDisabled()) { ;
__p += '\n            <a class="completion-date editable ' +
((__t = ( !item.get('viewable_permission') ? 'disabled' : '' )) == null ? '' : __t) +
'">' +
((__t = ( item.formatDateTime() )) == null ? '' : __t) +
'</a>\n          ';
 } ;
__p += '\n          &nbsp;\n        </div>\n        <div class=\'action col-17\'>' +
((__t = ( action_el.html() )) == null ? '' : __t) +
'</div>\n      </div>\n    ';
 } else{ ;
__p += '\n       <div class=\'action col-17\'>' +
((__t = ( action_el.html() )) == null ? '' : __t) +
'</div>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';

}
return __p
}