import Routes from '../../../routes';
import ChecklistFormItem from './_checklist_form_item';

const ContractItem = ChecklistFormItem.extend({

  isDisabled: function () {
    return this.model.has('contract_submit_date') && !this.model.has('paper_contract');
  },

  confirmIncomplete: function() {
    return this.confirm('Confirm Changes', 'The contract will be deleted, and all associated tuition and fee line items will be cleared from the ' +
                        '<a href="' + Routes.line_items_path() + '">Financial Line Items</a> log. Then, if the parent' +
                        ' submits another contract, new tuition and fee line items will be created.');
  },

  confirmComplete: function () {
    return this.paperContractModal();
  },

  paperContractModal: function () {
    var self = this;
    Dialog.open(Routes.new_contact_paper_contract_path(this.model.get('contact_id'))).then(function (result) {
      self.model.fetch();
    });

    // We'll never resolve this. The paper contract
    // modal will check-off the contract item for us.
    return $.Deferred();
  }
});

export default ContractItem;
