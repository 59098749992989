import moment from 'moment';

(function ($) {
  var methods = {
    init : function(options) {
      var self = this;

      var defaults = {
        hideEventTitle: false
      };

      var settings = $.extend(true, {}, defaults, options);

      var calendarOptions = {
        header: {
          left: `prev,next today firstAvailable ${settings.showFirstTimeSlot ? 'firstTimeSlot' : ''}`,
          center: 'title',
          right: 'month,agendaWeek'
        },
        customButtons: {
          firstAvailable: {
            text: 'first available',
            click: function() {
              if (settings.firstAvailableDate) {
                self.fullCalendar('gotoDate', settings.firstAvailableDate);
              } else {
                alert('There are no available appointment slots.');
              }
            }
          },
          firstTimeSlot: {
            text: 'first time slot',
            click: function() {
              if (settings.firstTimeSlotDate) {
                self.fullCalendar('gotoDate', settings.firstTimeSlotDate);
              } else {
                alert('There are no appointment slots.');
              }
            }
          }
        },
        allDaySlot: false,
        displayEventEnd: settings.displayEventEnd || false,
        timezone: settings.timezone,
        timeFormat: settings.timeFormat,
        events(start, end, _, callback) {
          $.ajax({
            method: 'GET',
            url: settings.fetchURL,
            dataType: 'json',
            async: false,
            data: {
              start: +start / 1000,
              end: +end / 1000
            },
            success(data) {
              const events = [];

              $.each(data.calendar.available_times, (i, time) => {
                const event = Object.assign({}, time);

                event.allDay = false;
                event.title = settings.hideEventTitle ? '' : `(${time.title})`;
                event.scheduleId = time.schedule_id;
                events.push(event);
              });

              callback(events);
            }
          });
        },
        eventClick: function (calEvent, jsEvent, view) {
          self.trigger('eventClick', [calEvent, jsEvent, view]);
        },
        eventRender(eventObj, $el) {
          $el.attr('data-date', eventObj.start.toString());
          $el.attr('data-schedule-id', eventObj.scheduleId);
        },
        viewRender(view, $el) {
          // Disable the first available and first time slot buttons if we are already on that month.
          // NOTE: intervalStart is inclusive, but intervalEnd is exclusive (https://fullcalendar.io/docs/v3/view-object), so cannot use isSameOrAfter() here.
          if (view.intervalStart.isSameOrBefore(moment(settings.firstAvailableDate))
            && view.intervalEnd.isAfter(moment(settings.firstAvailableDate))) {
            $('.fc-firstAvailable-button').prop('disabled', true).addClass('fc-state-disabled');
          } else {
            $('.fc-firstAvailable-button').prop('disabled', false).removeClass('fc-state-disabled');
          }

          if (settings.showFirstTimeSlot && view.intervalStart.isSameOrBefore(moment(settings.firstTimeSlotDate))
            && view.intervalEnd.isAfter(moment(settings.firstTimeSlotDate))) {
            $('.fc-firstTimeSlot-button').prop('disabled', true).addClass('fc-state-disabled');
          } else if (settings.showFirstTimeSlot) {
            $('.fc-firstTimeSlot-button').prop('disabled', false).removeClass('fc-state-disabled');
          }
        },
        dayClick: function(date, jsEvent, view) {
          self.trigger('dayClick', [date, jsEvent, view]);
        }
      };

      if (settings.setDefaultDateToFirstAvailable && settings.firstAvailableDate) {
        calendarOptions.defaultDate = settings.firstAvailableDate;
      }

      this.fullCalendar(calendarOptions);

      $('body').on('calendarUpdated', function() {
        self.fullCalendar('refetchEvents');
      });

      return this;
    },
    toggleSchedule: function (scheduleId, visible) {
      this.find(`.fc-event[data-schedule-id=${scheduleId}]`).toggleClass('hidden', visible);

      return this;
    }
  };


  $.fn.calendar = function (methodOrOptions) {
    if (methods[methodOrOptions]) {
      return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
    } else if (typeof methodOrOptions === 'object' || !methodOrOptions) {
      // Default to "init"
      return methods.init.apply(this, arguments);
    } else {
      $.error('Method ' + methodOrOptions + ' does not exist on jQuery.calendar');
    }
  };
}(jQuery));
