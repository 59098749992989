import Backbone from 'backbone';

import ChecklistsView from '../views/checklist/checklists';

const ContactRecord = Backbone.Router.extend({
  routes: {
    '': 'index'
  },
  initialize(data) {
    this.checklists = data.checklists;
    this.currentWorkflow = data.currentWorkflow;
    this.notes = data.notes;
    this.id = data.id;
    this.contact = data.contact;
  },
  index() {
    const checklistView = new ChecklistsView({
      collection: this.checklists,
      current_workflow: this.currentWorkflow
    });
    checklistView.render();
  }
});

export default ContactRecord;
