import Spinner from 'Legacy/components/spinner/spinner';

(function ($) {
  // Initialize an appointment form
  jQuery.fn.appointmentForm = function (appointmentFormData) {
    var self = this;
    var spinner = $('<img class="appointment-spinner" />');

    $(this).append(spinner);
    Spinner.showSpinner(spinner);

    var iframeLink = appointmentFormData.render_initial_form
      ? appointmentFormData.form_link
      : appointmentFormData.validate_link;

    var $iframe = $('<iframe src="' + iframeLink + '" scrolling="no" class="appointment-form d-none" />');
    var appointmentFormDataField = $('<input type="hidden" name="appointment_form_data" />');
    var outerForm = $(this).closest('form');

    // Resize the iframe when contents change (fields hidden/shown)
    var _resize = function() {
      var padding = 5;
      var newInnerHeight = $iframe.get(0).contentWindow.document.documentElement.scrollHeight + padding;
      var oldHeight = $iframe.height();

      // Check to avoid just adding padding pixels before actually modifying the iframe height
      // (Fixes Firefox issue where scrollHeight grows to iframe height)
      if (newInnerHeight - oldHeight !== padding) {
        $iframe.css('height', newInnerHeight + 'px');
        $iframe.get(0).height = newInnerHeight + 'px';
      }

      if (!appointmentFormData.admin_portal) {
        $(window).trigger('resize');
      }
    };

    $iframe.on('load', function() {
      var innerDoc = this.contentWindow.document.documentElement;
      var innerForm = $(innerDoc).find('.sa-form form');

      // Forgive me father, for I have sinned:
      $(innerDoc).find('.sa-form').addClass('sa-form-embedded');
      $(innerDoc).find('.sa-form form').addClass('form-horizontal');
      $(innerDoc).find('.sa-form-field-group-prompt').addClass('col-form-label col-sm-8');
      $(innerDoc).find('.sa-form-field-group-prompt').removeClass('sa-form-field-group-prompt');
      $(innerDoc).find('.sa-form-field-group-body').addClass('controls col-sm-12');
      $(innerDoc).find('input:not(:checkbox):not(:radio)').addClass('form-control form-control-sm');
      $(innerDoc).find('select').addClass('form-control form-control-sm');


      // Insert form answers as hidden elements and submit if necessary
      if (!appointmentFormData.render_initial_form) {
        // Only need to do this part once
        appointmentFormData.render_initial_form = true;

        var innerForm = $(this.contentWindow.document.documentElement).find('.sa-form form');
        for (var key in appointmentFormData.form_answers) {
          if ($.isArray(appointmentFormData.form_answers[key])) {
            for (var i = 0; i < appointmentFormData.form_answers[key].length; i++) {
              var e = $('<input type="hidden" />');
              e.attr('name', 'ad_hoc_form[' + key + '][]');
              e.attr('value', appointmentFormData.form_answers[key][i]);
              innerForm.append(e);
            }
          } else {
            var e = $('<input type="hidden" />');
            e.attr('name', 'ad_hoc_form[' + key + ']');
            e.attr('value', appointmentFormData.form_answers[key]);
            innerForm.append(e);
          }
        }
        innerForm.submit();
      }

      // This event is defined in custom_form.js and bubbled out of the iframe
      // by the appointment_forms layout
      $('body').on('sa-form:change', function() {_resize();});
      // show() doesn't remove "d-none" class on parent portal
      $(this).removeClass('d-none');
      $(self).find('.appointment-spinner').hide();
      _resize();
    });

    outerForm.on('beforeSend', self, function(e) {
      var elem = e.data;
      var data = $(elem).appointmentFormAnswers();
      $(elem).find('input[name=appointment_form_data]').val(data);
      $('body').off('sa-form:change');
      $(elem).find('iframe').off('load');
    });

    self.closest('.modal').on('hide.bs.modal', self, function(e) {
      var elem = e.data;
      $('body').off('sa-form:change');
      $(elem).remove();
    });

    $(this).append($iframe);
    $(this).append(appointmentFormDataField);
  };

  // Return JSON representation of appointment form answers
  jQuery.fn.appointmentFormAnswers = function () {
    var innerForm = $($(this).find('iframe').get(0).contentWindow.document.documentElement).find('.sa-form form');
    return $(innerForm).serialize();
  };
}(jQuery));
