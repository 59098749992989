import Backbone from 'backbone';

import ChecklistItem from './checklist_item';

// Similar to deposit_item
const FormFeeItem = ChecklistItem.extend({

  events: Object.assign({
    'click .change-fee-link': 'updateFeeAmount'
  }, ChecklistItem.prototype.events),

  initialize() {
    ChecklistItem.prototype.initialize.apply(this);

    this.backboneEventHandler = Object.assign({}, Backbone.Events);

    this.backboneEventHandler.on(
      'MODAL_SUBMIT',
      (result) => { this.updateModel(result); },
      this
    );
  },

  updateModel(attributes) {
    const { model } = this;
    model.set(attributes);
  },

  // overrides the default toggleItem so the modal completes the checklist item
  toggleItem(e) {
    const { model } = this;

    if (this.isDisabled()) {
      return;
    }
    if (model.isComplete()) {
      $.when(this.confirmIncomplete())
        .then(() => {
          model.set('completion_date', null);
          model.save(null, {
            success() {
              // Update the status in the contact record header
              $('.contact-header .status').text(model.get('status'));
            }
          });
          model.trigger('incomplete');
        });
    } else if (this.model.get('show_popup')) {
      this.showModal();
    } else {
      // If no billing, just behave like a basic toggle
      FormFeeItem.__super__.toggleItem.apply(this);
    }
  },

  showModal() {
    ReactComponent.dispatch({
      type: 'schooladmin/modal/SHOW',
      modalType: 'ManualPaymentModal',
      modalProps: {
        checklistAnswerId: this.model.get('id'),
        type: this.model.get('back_bone_type'),
        contactId: this.model.get('contact_id'),
        paymentMethod: this.model.get('fee_method'),
        schoolYearId: this.model.get('school_year_id'),
        currentPaymentAmount: this.model.get('fee_amount'),
        expectedAmount: this.model.get('expected_fee_amount'),
        defaultCategorizedAmounts: this.model.get('categorized_amounts'),
        backboneEventHandler: this.backboneEventHandler
      }
    });
  },

  updateFeeAmount() {
    this.showModal();
  }
});

export default FormFeeItem;
