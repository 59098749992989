function spinner(item) {
  $(item).html('<img src="/assets/shared_components/spinner.gif" />');
}

var Spinner = {
  IMAGE_URL: '/assets/shared_components/spinner.gif',

  imageSpinner: function(img) {
    $(img).attr("src", "/assets/shared_components/spinner.gif");
  },

  showSpinner: function(imgSelector) {
    Spinner.imageSpinner(imgSelector);
    $(imgSelector).show();
  },

  hideSpinner: function(imgSelector) {
    $(imgSelector).hide();
  },


  dialogSpinner: function(selector_id, text_to_display) {
    Spinner.dialogSpinnerWithDialog(selector_id, text_to_display, null);
  },

  dialogBootstrapSpinner: function(selector_id, text_to_display) {
    Spinner.dialogSpinnerWithDialog(selector_id, text_to_display, '.modal:visible .modal-footer');
  },

  dialogSpinnerWithDialog: function(selector_id, text_to_display, dialog_id) {
    if (dialog_id) {
      //$(dialog_id).dialog("option", "buttons", null);
      $(dialog_id).html('');
    }

    var contentElement = $(selector_id);
    var spinnerElement = $(selector_id + ' + .spinner');
    if ((spinnerElement === null) || (spinnerElement.length === 0)) {
      spinnerElement = contentElement;
    } else {
      contentElement.hide();
    }

    spinnerElement.html('<img src="/assets/shared_components/ajax-progress-bar.gif" />');
    spinnerElement.addClass('import-spinner');
    var spinnerText = $(document.createElement('div'));
    spinnerText.html("<span id='spinnerText'>" + text_to_display + "</span>");
    spinnerElement.append(spinnerText);
    spinnerElement.show();
  }
};

export default Spinner;
