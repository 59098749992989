(function ($) {
  $.fn.parentCalendar = function(options) {

    const defaults = {
      timeFormat: 'h:mm A',
      hideEventTitle: true
    };

    const settings = $.extend(true, {}, defaults, options);

    const calendar = this.find('.calendar').calendar({
      displayEventEnd: true,
      timeFormat: 'h:mm A',
      hideEventTitle: true,
      calendar_id: settings.calendar_id,
      contact_id: settings.contact_id,
      fetchURL: settings.fetchURL,
      firstAvailableDate: settings.firstAvailableDate,
      setDefaultDateToFirstAvailable: true,
      timezone: settings.timezone
    });

    calendar.on('eventClick', function(event, calEvent) {
      if (!Dialog.isOpen) {
        if (settings.mode === 'reschedule') {
          // TODO, should use Dialog.open(Routes.confirm_reschedule_portal_appointment_path(calEvent.id, {contact_id: settings.contact_id, appointment_id: settings.appointment_id, available_time_id: calEvent.id}));
          // but seeing failing specs in calendar_spec.rb
          const searchParams = new URLSearchParams({
            contact_id: settings.contact_id,
            appointment_id: settings.appointment_id,
            available_time_id: calEvent.id
          }).toString();
          Dialog.open(`/portal/appointments/${calEvent.id}/confirm_reschedule?${searchParams}`)
        } else {
          // TODO, should use Dialog.open(Routes.new_portal_available_time_appointment_path(calEvent.id, {contact_id: settings.contact_id}));
          // here, but seeing failing specs in calendar_spec.rb
          Dialog.open(`/portal/available_times/${calEvent.id}/appointments/new?contact_id=${settings.contact_id}`);
        }
      }
    });

  };
}(jQuery));
