import ChecklistItem from './checklist_item';

const PortraitItem = ChecklistItem.extend({

  events: Object.assign({
    'click .delete-file-link' : 'deleteFile'
  }, ChecklistItem.prototype.events),

  render: function () {
    var self = this;
    PortraitItem.__super__.render.apply(this);
    Checklists.bindFileUploadAdmin(this.$('.fileinput-button input[type=file]'), this.model.get('max_file_size')*1024*1024).then(
        function () {
          self.model.fetch();
          Checklists.refreshContactPortrait();
          Checklists.openPortraitModal(true);
        },
        function () {
          self.model.fetch();
          Utility.displayAjaxError();
          Checklists.refreshContactPortrait();
        }
    );
    return this;
  },

  deleteFile: function () {
    return confirm('Are you sure you want to delete this file?');
  }
});

export default PortraitItem;
