import { Modal } from 'bootstrap';

import Utility from 'Legacy/helpers/shared/utility';
import 'Vendor/javascripts/stateful_button/stateful_button';

/**
 * Helper to make it easier to deal with bootstrap modals.
 *
 * To have a link trigger a modal whose contents are loaded via ajax, simply
 * create a link for it with the 'open-dialog' class:
 *
 * <a href='/some-controller/show' class='open-dialog'>Click me</a>
 *
 * The link should return a full bootstrap modal including header, body and
 * footer.
 *
 * If a button or link in the modal contains the class 'dialog-submit',
 * then the form in the modal will be submitted via ajax when clicked, and
 * the result rendered in the same modal.  For this to work properly, the
 * submit button must be placed outside the form.
 *
 * This helper can be used in conjunction with these controller methods, which
 * provide JSON encoded modal actions:
 * ApplicationController#redirect_from_modal()
 * ApplicationController#redirect_back_from_modal()
 * ApplicationController#close_modal()
 * ApplicationController#close_and_redirect_from_modal()
 */
var Dialog = {
  isOpen: false,

  install_links: function (selector) {
    $(selector).on('click', 'a.open-dialog, a.open-modal', function () {
      Dialog.open($(this).attr('href'));
      return false;
    });
  },

  getModal: function(selector) {
    return Modal.getInstance($(selector)[0]);
  },

  open: function (url, opts) {
    this.isOpen = true;
    var deferred_result = $.Deferred();

    // Hide any notifications
    Utility.hideNotifications();

    // Load the modal
    var self = this;
    $.get(url, function (response, status, xhr) {
      var success = self._handle_json_response(null, response, xhr) ||
          self._handle_open_modal_response(response, deferred_result, opts);
    });

    return deferred_result;
  },

  submit: function (dialog, form, submit) {
    var self = this;
    var $dialog = $(dialog);
    var $form = $(form);
    var $submit = $(submit);

    $submit.buttonState('loading');

    // Trigger a pre-submit event that can be used to prepare the form.  Send
    // the button name as an extra parameter so the event handler can know
    // which button was clicked.
    $form.trigger('beforeSend', $submit.attr('name'));

    $form.ajaxSubmit({
      success: function (response, status, xhr) {
        // If the response type is unknown then reload the page. This
        // can happen when the session times out thus reloading
        // will redirect to the login page.
        var result = self._handle_json_response($dialog, response, xhr) ||
            self._handle_update_modal_response($dialog, response) ||
            window.location.reload();
      },

      error: function () {
        $submit.buttonState('reset');
        Utility.displayAjaxError();
      }
    });
  },

  _handle_json_response: function (dialog, response, xhr) {
    var content_type = xhr.getResponseHeader('content-type') || '';
    if (content_type.indexOf('json') === -1) {
      return false;
    }
    var json_response = eval(response);
    var deferred_result = $(dialog).data('deferred_result');
    var modal = Modal.getInstance($(dialog).get(0));

    if (json_response.modal_action === 'redirect') {
      // Redirect to a new page
      window.location = json_response.redirect_url;
    } else if (json_response.modal_action === 'reload'){
      window.location.reload();
    } else if (json_response.modal_action === 'close') {
      // Close the modal
      if (typeof(dialog) !== 'undefined' && dialog !== null) {
        modal.hide();
      }
      // Display flash messages
      if ('flash' in json_response) {
        setTimeout(function() { Utility.displayFlashMessages(json_response.flash); }, 500);
      }
      if (deferred_result) {
        deferred_result.resolve(json_response.result);
      }
    }

    return true;
  },

  _handle_open_modal_response: function (response, deferred_result, opts) {
    var $dialog = $(response);
    opts = opts || {};

    if (deferred_result) {
      $dialog.data('deferred_result', deferred_result);
    }

    // The bootstrap fade effect does not work in all versions of IE
    var isMSIE = /*@cc_on!@*/0;
    if (isMSIE) {
      $dialog.removeClass('fade');
    }

    // Delete the dialog from the DOM when hidden
    $dialog.on('hidden.bs.modal', function () {
      if(opts.onHidden){
        opts.onHidden($dialog);
      }
      $dialog.remove();
      Dialog.isOpen = false;
    });

    $('body').append($dialog);
    const modal = new Modal($dialog);
    modal.show();

    this._instrument_dialog($dialog);
    return true;
  },

  _handle_update_modal_response: function (dialog, response) {
    var $response = $(response);
    if ($response.find('.modal-body').length === 0) {
      return false;
    }

    // Copy the header, body and footer into the current modal
    $.each(['.modal-header', '.modal-body', '.modal-footer'], function (i, selector) {
      dialog.find(selector).contents().remove();
      dialog.find(selector).html($response.find(selector).html());
    });

    dialog.find('.modal-body').scrollTop(0);

    this._instrument_dialog(dialog);
    return true;
  },

  _instrument_dialog: function (dialog) {
    var self = this;
    var form = dialog.find('form').filter(':first');
    let modal = Modal.getInstance($(dialog).get(0));

    // Use bootstrap error highlighting for any fields with errors
    dialog.find('.field_with_errors').closest('.field-with-errors-check').addClass('has-error');

    // Bind any submit buttons to the first form in the dialog
    dialog.find('.dialog-submit, .modal-submit').click(function (e) {
      e.preventDefault();
      self.submit(dialog, form, $(this));
    });

    // Prevent enter from submitting form
    dialog.find('form').on('keypress', 'input, select', function(event) {
      return event.keyCode != 13;
    });

    // Bind any modal links such that they hide the current modal and open a new one
    dialog.find('.dialog-link, .modal-link').click(function () {
      modal.hide();
      Dialog.open($(this).attr('href'));
      return false;
    });

    // Initialize date pickers if the datepicker jquery UI stuff is available
    // TODO - see if there's a cleaner way to check if datepicker js available
    if (typeof($('.date-picker').datepicker) !== 'undefined') {
      $('.date-picker').datepicker().on('show', function() {
        // This is a work around for an issue where the date picker appears
        // behind the modal after it has been open and closed a few times.
        var zx = $(this).closest('.modal').css('z-index');
        if (zx) {
          $(this).css('z-index', zx);
          $('.datepicker-dropdown').css('z-index', zx);
        }
      });
    }
  }
};

global.Dialog = global.Dialog || Dialog;

export default Dialog;
