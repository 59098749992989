module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'detail\'>\n  ';
 if (item.get("viewable_permission")) { ;
__p += '\n    ';
 if (item.get('show_popup')) { ;
__p += '<a href=\'#\' class=\'change-fee-link\'>';
 } ;
__p += '\n\n    ';
 if (item.has('fee_method')) {
    ;
__p += '\n      ';
 if (item.get('fee_amount') > 0) { ;
__p += '\n        ' +
((__t = ( accounting.formatMoney(item.get('fee_amount'), item.get('currency_opts')) )) == null ? '' : __t) +
'\n      ';
 } ;
__p += ' paid by ' +
((__t = ( item.get('fee_method').toLowerCase() )) == null ? '' : __t) +
'\n    ';
 } else { ;
__p += '\n      ';
 if (item.get('show_popup')) { ;
__p += 'Edit';
 } ;
__p += '\n    ';
 } ;
__p += '\n\n    ';
 if (item.get('show_popup')) { ;
__p += '<i class=\'fa fa-pencil\'></i></a>';
 } ;
__p += '\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}