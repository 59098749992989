(function ($) {

  $.fn.parentDashboard = function () {
    var self = this;

    this.find('.oe').createPortalStudent();

    // Override the submit handler for the add student widget to make it all ajax-y n stuff
    $(document).on('submit', '.oe form', function (e, add_another) {
      var $form = $(this);
      $form.ajaxSubmit({
        success: function () {
          $.get(Routes.parent_dashboard_path({add_another: add_another}), function (data) {
            var $data = $(data);

            self.find('.student-summaries').replaceWith($data.find('.student-summaries'));
            self.find('.oe').createPortalStudent();
            self.find('.oe-after')[0].scrollIntoView(false);
            self.find('[name="student[first_name]"]').focus();

            Utility.successMessage('Done! Student added.');
          });
        },
        error: function () {
          Utility.errorMessage('An error has occurred, please try again.');
          $form.find('button').buttonState('reset');
        }
      });

      return false;
    });

    return this;
  };

  $.fn.disableDragAndDrop = function () {
    $('main').on('drop', function() {
      return false;
    });
  };

}(jQuery));
