import { utcToZonedTime, format } from 'date-fns-tz';
import { titleize } from "./strings";
import moment from 'moment';

const COUNTRIES = require('../../../config/countries.json');
const STATES = require('../../../config/states.json');

let currencyFormatter = null;
let numberFormatter = null;


/**
 * DEPRECATED - Formats a time in the customer's locale. - DEPRECATED
 *
 * @deprecated    This does not handle timezone conversion.  Use `formatDateTime(dateTime, 'fns_date')` instead
 *
 * @param date    A Date object or string containing a date or datetime.  Is expected to already be in the customer's time zone
 *                  i.e. "2021-01-01"
 * @param default_format  moment format to use
 * @returns {*}   The date component of the provided string, i.e. "2021-08-24T00:00:00Z" -> "08/24/2021"
 */
function formatDate(date, default_format = 'default_moment') {

    return moment(date).format(global.I18n.date.formats[default_format]);
}

/**
 * Formats a DATE and a TIME using the settings specified in locale as well as customer time zone
 *
 * @param dateTime   A Date object or a string.  Should have time zone info for proper conversion,
 *                   i.e. "2021-08-24T19:49:02Z" or "2021-08-25T00:49:02-05:00"
 * @param format     Name of the format to use (see `config/locales/en.yml` etc)
 * @param timezone   optional time zone to force display in.  Defaults to customer-local.
 * @returns {string} full timestamp, i.e. "2021-08-24T19:00:00Z" -> "8/24/2021 2:00 PM CDT"
 */
function formatDateTime(dateTime, default_format = 'fns_date_time', timezone = global.I18n.time_zone) {
  const parsedAs = format(utcToZonedTime(dateTime, timezone), global.I18n.time.formats[default_format], {});
  return parsedAs;
}

/**
 * Shows only the time component of a date/time, in the current locale as well as the customer's time zone
 *
 * @param date       A Date object or a string.  Should have time zone info for proper conversion,
 *                   i.e. "2021-08-24T19:49:02Z" or "2021-08-24T14:49:02-05:00"
 * @returns {string} The time, converted to customer's time zone and locale.  i.e. "2021-08-24T19:00:00Z" -> "2:00 PM CDT"
 */
function formatTime(date) {
  return formatDateTime(date, 'default_time');
}


function formatNumber(number) {
  const {
    I18n = {}
  } = global;

  if (typeof (number) === 'string') {
    number = parseCurrency(number);
  }

  // Lazy-initialize
  if (numberFormatter === null) {
    const locale = I18n.locale || 'en-US';
    numberFormatter = new Intl.NumberFormat(locale);
  }

  return numberFormatter.format(number);
}

function currencyDisplayOptions(I18n) {
  const options = {};

  if (I18n.show_i18n_currency_code) {
    options.currencyDisplay = 'code';
  }

  return options;
}

function formatCurrency(number) {
  const {
    I18n = {}
  } = global;
  const locale = I18n.locale || 'en-US';
  const currency = I18n.currency_code || 'USD';

  if (typeof (number) === 'string') {
    number = parseCurrency(number);
  }

  // Lazy-initialize
  if (currencyFormatter === null) {
    currencyFormatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      ...(currencyDisplayOptions(I18n)),
      currency
    });
  }

  return currencyFormatter.format(number);
}

function getCurrencySymbol() {
  const {
    I18n = {}
  } = global;

  return new Intl.NumberFormat(I18n.locale || 'en-US', {
    style: 'currency',
    ...(currencyDisplayOptions(I18n)),
    currency: I18n.currency_code || 'USD'
  }).formatToParts(0).find(part => part.type === 'currency').value;
}

function parseCurrency(string) {
  if (typeof (string) !== 'string') {
    return string; // It's already a number?
  }

  return parseFloat(string.replace(/[^0-9\.-]+/g, ""));
}

let sortedCountries = null;

/**
 * Returns a list of countries sorted based on current locale preferences.
 *
 * @returns {*|*[]} a list of countries sorted based on current locale preferences
 */
function getCountries() {
  const {
    I18n: {
      priority_countries = []
    } = {}
  } = global;

  return sortedCountries || [
    ...priority_countries.map(code => COUNTRIES.find(c => c.code === code)),
    ...COUNTRIES.sort((a, b) => a.label.localeCompare(b.label))
  ];
}

/**
 * Get the states for a particular country
 *
 * @param country     Either the 2-digit code for the country, or the whole country object as returned by getCountries
 * @returns {*|Array} Any states known for this country, or an empty array
 */
function getStates(country) {
  const countryCode = country.code || country;

  return STATES[countryCode] || [];
}

/**
 * Helper to find the fill info about a country based on one or more criteria.
 *
 * @param criteria Object specifying criteria to search on, ie {code: 'US'} or {label: 'United States', alpha_3: 'USA'}
 * @returns {T}
 */
function findCountry(criteria) {
  return getCountries().find(c =>
    Object.keys(criteria).every(check =>
      c[check] === criteria[check]
    )
  );
}


/**
 * Gets the display name for a state or state-like entity (i.e. 'State' in the US and 'Province' in Canada)
 *
 * @param country        Either country config or the 2-letter code for a country
 * @returns {titleized}  What to call a state.  Defaults to 'State'
 */
function getStateDisplayName(country) {
  const countrySpec = (typeof (country) === 'string') ? findCountry({ code: country }) : country;

  return titleize((!!countrySpec && countrySpec.stateDisplayName) || 'state');
}

/**
 * Gets the display name for zip code or postal code.
 * (i.e. "Zip Code" in US, "Postal Code" in Canada, "Zip / Postal Code" anywhere else)
 *
 * @param country       Either country config or the 2-letter code for a country
 * @returns {titleized} What to call a zip code.  Defaults to "Zip / Postal Code"
 */
function getZipCodeDisplayName(country) {
  const countrySpec = (typeof (country) === 'string') ? findCountry({ code: country }) : country;

  return titleize((!!countrySpec && countrySpec.zipCodeDisplayName) || 'Zip / Postal Code');
}

/**
 * Gets if zip/postal code is required
 *
 * @param country       Either country config or the 2-letter code for a country
 * @returns {boolean}   Whether or not to require a zip/postal code
 */
function getZipCodeRequired(country) {
  const countrySpec = (typeof (country) === 'string') ? findCountry({ code: country }) : country;

  if (!countrySpec) {
    return false;
  }

  return countrySpec.zipCodeRequired || false;
}

export {
  findCountry,
  formatCurrency,
  getCurrencySymbol,
  formatDate,
  formatTime,
  formatDateTime,
  getCountries,
  getStates,
  getStateDisplayName,
  getZipCodeDisplayName,
  getZipCodeRequired,
  parseCurrency,
  formatNumber
};
