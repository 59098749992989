import moment from 'moment';

var I18n = {
  // This object also gets populated with the date format strings, i.e. I18n.date.formats.default_moment.
  // See shared/_globals.html.haml.

  /**
   * DEPRECATED Displays a date in the customer's locale without converting time zones DEPRECATED
   *
   * @deprecated This does not handle time zone conversion.  Use react/utils/i18n.js - formatDateTime instead
   *
   * @param date {Date|string}  A Date object or a string containing the date to show
   *                            i.e. "2021-08-24T00:00:00Z"
   * @returns {string|null}     The date component, in locale-specific display format, but without time zone conversion
   *                            i.e. "2021-08-24T00:00:00Z" -> "08/24/2021"
   */
  ldate: function(date) {
    return date ? moment(date).format(I18n.date.formats.default_moment) : null;
  }
};

global.I18n = global.I18n || I18n;
