module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row header"></div>\n\n<div class="row items">\n  ';
 if (required_items > 0 || optional_items > 0) { ;
__p += '\n  <div class="col-24 checklist-items">\n    ';
 if (required_items > 0) { ;
__p += '\n    <div class="required-items">\n      <ul></ul>\n    </div>\n    ';
 } ;
__p += '\n    ';
 if (optional_items > 0) { ;
__p += '\n    <div class="optional-items">\n      <h3>Internal & Optional Items:</h3>\n      <ul></ul>\n    </div>\n    ';
 } ;
__p += '\n  </div>\n  ';
 } else { ;
__p += '\n  <div class="col-24 checklist-items">\n    <ul>\n      <li>\n        <div class="card bg-light">\n        </div>\n      </li>\n    </ul>\n  </div>\n  ';
 } ;
__p += '\n</div>';

}
return __p
}