module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="action">\n  ';
 if (item.has('portrait_uploaded') && item.get("viewable_permission")) { ;
__p += '\n    <a href="' +
((__t = ( Routes.edit_contact_portrait_path(item.get('contact_id')) )) == null ? '' : __t) +
'" class="open-dialog">View & Edit Photo <i class="fa fa-eye"></i></a>\n  ';
 } else { ;
__p += '\n    ';
 if (item.get('can_upload') && item.get("viewable_permission")) { ;
__p += '\n      <div class="fileinput-button">\n          <a href="#">Upload (' +
((__t = ( item.get('max_file_size') )) == null ? '' : __t) +
'mb limit) <i class="fa fa-upload"></i></a>\n          <input type="file" name="portrait[image]" data-url="' +
((__t = ( Routes.contact_portrait_path(item.get('contact_id')) )) == null ? '' : __t) +
'">\n      </div>\n      <div class="progress d-none">\n          <div class="progress-bar bg-success"></div>\n      </div>\n    ';
 } ;
__p += '\n  ';
 } ;
__p += '\n</div>\n';

}
return __p
}