module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'detail\'>\n    ';
 if (item.isComplete() && item.has('inquiry_submit_date') && item.get("viewable_permission")) { ;
__p += '\n        <a class="edit-date-link" href="#">Submitted on ' +
((__t = ( item.get('inquiry_submit_date') )) == null ? '' : __t) +
'</a>\n    ';
 } ;
__p += '\n</div>\n\n<div class=\'action\'>\n    ';
 if (item.has('inquiry_id') && item.get("viewable_permission")) { ;
__p += '\n        <a href="' +
((__t = ( Routes.admin_inquiry_path(item.get('inquiry_id')) )) == null ? '' : __t) +
'" target="_blank">View Form <i\n                    class=\'fa fa-eye\'></i></a>\n    ';
 } ;
__p += '\n    ';
 if (!item.has('inquiry_id') || item.get("files").length > 0) { ;
__p += '\n        ' +
((__t = ( templateMap['checklist/checklist_form_item']({item: item}) )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}