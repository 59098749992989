// Assets shared between the parent portal and form bundles

import Routes from '../../routes';

import 'moment';
import 'jquery-ujs';
import 'jquery-form/jquery.form';
import 'underscore';

// Load third-party non-bower assets
import 'Vendor/javascripts/jquery-jcrumb-1.0/jquery.jcrumb';

// Load library JavaScript
import '../../../../../lib/assets/javascripts/set_slide_toggle';

// Load shared modules
import 'Legacy/shared/modules/user_menu_toggle';

global.Routes = Routes;
global.jQuery = $;
global.$ = $;
