$(function() {
  var hidden = true;

  $('.user-actions-toggle').click(function() {
    if (hidden) {
      $('.portal-nav').slideDown();
    } else {
      $('.portal-nav').slideUp(function() {
        $('.portal-nav').removeAttr('style');
      });
    }

    hidden = !hidden;
    $(this).find('.fa').toggleClass('fa-chevron-down fa-chevron-up');
  });


});


