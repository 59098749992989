module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-13">\n  <ul class="stages">\n    ';
 $.each(workflows, function(index, workflow) { ;
__p += '\n      <li class="' +
((__t = ( name === workflow ? 'active' : '' )) == null ? '' : __t) +
'">\n        <a class="' +
((__t = ( workflow )) == null ? '' : __t) +
'" href="javascript:void(0);">' +
((__t = ( workflow.slice(0, 1).toUpperCase() + workflow.slice(1, workflow.length)  )) == null ? '' : __t) +
'</a>\n      </li>\n    ';
 }); ;
__p += '\n  </ul>\n</div>\n\n\n<div class="col-2">' +
((__t = ( percentage )) == null ? '' : __t) +
'%</div>\n<div class="col-9">\n  <div class=\'progress progress-success\'>\n    <div class=\'progress-bar bg-success\' style=\'width: ' +
((__t = ( percentage )) == null ? '' : __t) +
'%;\'></div>\n  </div>\n</div>';

}
return __p
}