(($) => {
  $.fn.createPortalStudent = function createPortalStudent() {
    const $self = this;
    const $form = this.find('form');
    const $submitButtons = this.find('.oe-add-another-button, .oe-done-adding-button, .oe-resolve-multiple-button');

    function validateForm() {
      const valid = $form.validate({
        errorClass: 'is-invalid',
        errorPlacement: () => false
      }).checkForm();

      $submitButtons.prop('disabled', !valid);
    }

    // Initialize controls

    $submitButtons.prop('disabled', true);

    // Bind event handlers

    this.on('change keyup', 'form', validateForm);

    this.on('click', '.oe-add-another-student', function addAnotherStudent() {
      $(this).hide();
      $form[0].reset();
      $self.find('button').buttonState('reset');
      $self.find('[name="primary_household"], [name="primary_contact"]').trigger('change');
      $self.find('.oe-add-student-control').setSlideToggle(true, () => {
        $self.find('form').trigger('change');
        $self.find('.oe-after')[0].scrollIntoView(false);
        $self.find('[name="student[first_name]"]').focus();
      });
    });

    this.on('click', '.oe-cancel-button', () => {
      $self.find('.oe-add-student-control').setSlideToggle(false, () => {
        $self.find('.oe-add-another-student').show();
      });
      return false;
    });

    this.on('change', '[name="primary_household"]', (e) => {
      const isNew = $self.find('[name="primary_household"]:checked').val() === 'new';
      const newHousehold = $self.find('.oe-new-household');

      // Avoid the animation when manually triggered as
      // we want the changes to take effect immediately.
      if (!e.originalEvent) {
        newHousehold.toggle(isNew);
      } else {
        newHousehold.setSlideToggle(isNew, validateForm);
      }
    });

    this.on('change', '[name="primary_contact"]', (e) => {
      const val = $self.find('[name="primary_contact"]:checked').val();
      const isNew = val === 'new';
      const newGuardian = $self.find('.oe-new-guardian');

      // Avoid the animation when manually triggered as
      // we want the changes to take effect immediately.
      if (!e.originalEvent) {
        newGuardian.toggle(isNew);
      } else {
        newGuardian.setSlideToggle(isNew, validateForm);
      }
    });

    $submitButtons.on('click', function onClick() {
      $self.find('button').prop('disabled', true);
      $(this).buttonState('loading');

      console.log($(this).hasClass('oe-add-another-button'));

      $form.trigger('submit', $(this).hasClass('oe-add-another-button'));

      return false;
    });

    return this;
  };
})(jQuery);
