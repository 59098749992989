module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'detail\'>\n    ';
 if (item.has('form_id')) { ;
__p += '\n        Submitted by ' +
((__t = (item.get('form_user_name'))) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n</div>\n\n<div class=\'action\'>\n  ';
 if (item.has('form_id') && item.get("viewable_permission")) { ;
__p += '\n    <a href="' +
((__t = ( Routes.ad_hoc_form_path(item.get('form_id')) )) == null ? '' : __t) +
'" target="_blank">View Form <i class=\'fa fa-eye\'></i></a>\n  ';
 } else if (item.get("files").length > 0 && item.get("viewable_permission")) { ;
__p += '\n     <a href="' +
((__t = ( Routes.checklist_answer_manage_uploaded_files_path(item.get('id')) )) == null ? '' : __t) +
'" class="manage-files-modal">Manage Files <i class="fa fa-upload"></i></a>\n  ';
 } else { ;
__p += '\n     ';
 if (item.get('form_access') != 'parent' && item.get("viewable_permission")) { ;
__p += '\n        <a href="' +
((__t = ( Routes.form_create_path(item.get('ad_hoc_type'), item.get('form_name'), item.get('contact_id')))) == null ? '' : __t) +
'" target="_blank">Complete <i class=\'fa fa-file-text\'></i>  <span class="spacer">|</span>  </a>\n     ';
 } ;
__p += '\n    ' +
((__t = ( templateMap['checklist/checklist_form_item']({item: item}) )) == null ? '' : __t) +
'\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}