(function ($) {
  // A version of slide toggle that lets you specify whether to show/hide.
  $.fn.setSlideToggle = function (display, callback) {

    // Remove classes that could override our inline styling due to potential "!important" conflicts
    this.removeClass('d-none hidden');

    return display ?
        this.slideDown(200, callback) :
        this.slideUp(200, callback);
  };
}(jQuery));