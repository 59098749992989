import ChecklistItem from './checklist_item';

const FileUploadItem = ChecklistItem.extend({

  events: Object.assign({
    'click .delete-file-link' : 'deleteFile'
  }, ChecklistItem.prototype.events),

  render: function () {
    var self = this;
    FileUploadItem.__super__.render.apply(this);
    Checklists.bindFileUploadAdmin(this.$('.fileinput-button input[type=file]'), this.model.get('max_file_size')*1024*1024).then(
        function () {
          self.model.fetch();
        },
        function () {
          self.model.fetch();
          Utility.displayAjaxError();
        }
    );

    this.$('.manage-files-modal').click(function () {
      Dialog.open($(this).attr('href'), {
        onHidden () {
          self.model.fetch();
        }
      });

      return false;
    });
    return this;
  },

  deleteFile: function () {
    return confirm('Are you sure you want to delete this file?');
  }
});

export default FileUploadItem;
